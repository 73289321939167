import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import MyYoastColors from "../config/colors.json";
import PropTypes from "prop-types";

/**
 * Returns an upward pointing chevron, or a downward pointing one. Depending on whether isOpen is true or false, respectively.
 *
 * @param   {Object}      props The props for the upDownArrow.
 * @returns {ReactElement}        An up or down arrow.
 */
export default function UpDownArrow( props )  {
	const style = {
		width: "32px",
		height: "32px",
		color: MyYoastColors.$text_gray,
	};

	return props.isOpen ? <ChevronUpIcon style={ style } /> : <ChevronDownIcon style={ style } />;
}

UpDownArrow.propTypes = {
	isOpen: PropTypes.bool.isRequired,
};
