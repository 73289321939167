// External dependencies.
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { injectIntl, intlShape } from "react-intl";

// Internal dependencies.
import defaults from "../config/defaults.json";

const activeStyle = "active-class-name";

const Menu = styled.nav`
	clear: both;

	& ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	& li {
		display: inline;
	}

	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		margin: 0;
		text-align: center;

		& ul {
			display: flex;
		}
	}
`;

const MenuItem = styled( NavLink )`
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 300;
	padding: 16px;
	margin-left: 24px;
	color: var(--text-color-white);
	text-decoration: none;
	border-radius: 6px 0 0 6px;
	transition: color 0.1s ease-out, background-color 0.1s ease-out, padding 50ms ease-out, transform 100ms ease-out;

	&:hover,
	&:focus {
		padding-left: 1.25rem;
		color: var(--text-color-white);
	}

	svg:not(.external-link) {
		height: 20px;
		width: 20px;
		margin-right: 16px;
		fill: var(--color-white);
	}

	svg.external-link {
		width: 12px;
		height: 12px;
		transform: translateX(4px) translateY(-3px);
	}

	&.${ activeStyle } {
		color: var(--text-color-purple);
		background-color: var(--bg-color-offwhite);
		font-weight: var(--font-weight-600);
		color: var(--text-color-purple);
		position: relative;
		overflow-y: hidden;

		&:hover,
		&:focus {
			padding-left: 1rem;
		}

		svg {
			fill: var(--text-color-purple);
		}
	}

	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 1.15rem 1rem !important;
		color: var(--text-color-white);
		font-size: var(--text-font-size);
		overflow: hidden;

		&:hover,
		&:focus {
			padding-left: 1rem;
		}

		svg:not(.external-link) {
			display: none;
		}

		&.${ activeStyle } {
			color: var(--text-color-white);
			background-color: rgba(0, 0, 0, .2);

			&:hover,
			&:focus {
				padding-left: 1rem;
			}
		}
	}
`;

/**
 * The main menu.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered component.
 */
const MainMenu = props => {
	return (
		<Menu>
			{ // eslint-disable-next-line jsx-a11y/no-redundant-roles
			}<ul id="main-nav" role="list">
				{ props.menuRoutes.map( page => {
					const isActive = page.isActive || (
						( match ) => {
							return match;
						}
					);

					const menuItemIcon = page.iconSource;

					const title = props.intl.formatMessage( page.title );

					return <li id={ `main-nav__${ page.titleKey }` } key={ page.titleKey }>
						<MenuItem
							activeClassName={ activeStyle }
							to={ page.isExternal ? page.pathname : page.path }
							target={ page.isExternal ? "_blank" : "_self" }
							isActive={ isActive }
						>
							{ menuItemIcon }
							{ title }
							{ page.isExternal && page.externalLinkIconSource }
						</MenuItem>
					</li>;
				} )
				}
			</ul>
		</Menu>
	);
};

export default injectIntl( MainMenu );

MainMenu.propTypes = {
	menuRoutes: PropTypes.array.isRequired,
	intl: intlShape.isRequired,
};
