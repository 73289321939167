import React, { useEffect } from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { ErrorPropTypeShape } from "../../errors/ErrorDisplay";
import { ButtonLink } from "../Button";
import Alert from "../Alert";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import PropTypes from "prop-types";
import LoginMessage from "./LoginMessage";
import getEnv from "shared-frontend/functions/getEnv";
import Link from "../Link";

const StyledButtonLink = styled( ButtonLink )`
	margin: 1em 0;
	width: 100%;
`;

const RightAlignedLink = styled( Link )`
	float: right;
`;

const messages = defineMessages( {
	buttonToLoginPage: {
		id: "activate.loginButton",
		defaultMessage: "Go to the login page",
	},
	userAlreadyActive: {
		id: "activate.alreadyActive",
		defaultMessage: "Your account was already activated",
	},
	genericActivationFailed: {
		id: "activate.genericActivationFailed",
		defaultMessage: "Activating your account failed: {errorMessage}",
	},
	linkToYoast: {
		id: "activate.link.to.yoast",
		defaultMessage: "Go to yoast.com",
	},
} );

// eslint-disable-next-line require-jsdoc
function getActivationError( isLoading, activationError, resetOauthError ) {
	if ( isLoading || ! activationError ) {
		return null;
	}

	if ( activationError.code === "already_active" ) {
		return (
			<>
				<Alert type="info" dismissable={ false }>
					<FormattedMessage { ...messages.userAlreadyActive } />
				</Alert>
				<StyledButtonLink to={ "/login" } onClick={ resetOauthError }>
					<FormattedMessage { ...messages.buttonToLoginPage } />
				</StyledButtonLink>
			</>
		);
	}

	return (
		<>
			<Alert type="error" dismissable={ false }>
				<FormattedMessage
					{ ...messages.genericActivationFailed }
					values={ { errorMessage: activationError.message } }
				/>
			</Alert>
		</>
	);
}

// eslint-disable-next-line require-jsdoc
function getLinkToYoastcom() {
	return (
		<RightAlignedLink
			to={ getEnv( "SHOP_URL", "https://yoast.test" ) }
			hasExternalLinkIcon={ false }
			ariaLabel={ messages.linkToYoast.defaultMessage }
		>
			<FormattedMessage { ...messages.linkToYoast } /> &rarr;
		</RightAlignedLink>
	);
}

// eslint-disable-next-line require-jsdoc
export const Activate = ( props ) => {
	const parsedQuery = queryString.parse( props.location.search, { arrayFormat: "bracket" } );
	const key         = parsedQuery.key || "";

	useEffect( () => {
		if ( key ) {
			props.activateUser( key );
		}
	}, [ key ] );

	if ( props.loggedIn ) {
		return ( <Redirect to={ "/enter-details" } /> );
	}

	if ( ! key ) {
		return ( <Redirect to={ "/login" } /> );
	}

	const error = getActivationError( props.loading, props.activationError, props.resetOauthError );

	return <>
		<LoginMessage asideType="activate" />
		{ error }
		{ ( props.activationError && ! props.loading ) && getLinkToYoastcom() }
	</>;
};

Activate.propTypes = {
	loggedIn: PropTypes.bool,
	activateUser: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
	activationError: ErrorPropTypeShape,
	loading: PropTypes.bool,
	resetOauthError: PropTypes.func.isRequired,
};

Activate.defaultProps = {
	loggedIn: false,
	loading: false,
	activationError: null,
};

export default injectIntl( Activate );
