import styled from "styled-components";
import defaults from "../../config/defaults.json";

const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: end;
  	gap: var(--gap);
  	margin-top: var(--gap);

	@media screen and (max-width: ${ defaults.css.breakpoint.mobile }px) {
		flex-direction: column;
		justify-content: center;
	  	gap: calc(var(--gap) / 2);
	}
`;

export default ButtonsContainer;
