// External dependencies.
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors } from "@yoast/style-guide";
import { FormattedMessage, FormattedDate, defineMessages, injectIntl, intlShape } from "react-intl";
import Cookies from "js-cookie";

// Internal dependencies.
import { CloseButtonTopRight, makeButtonFullWidth, YellowCaretLink } from "./Button";
import renewalNotification from "../images/renewalNotification.svg";
import { ColumnFixedWidth, ColumnPrimary, ListTable, CompactRow, makeFullWidth, responsiveHeaders } from "./Tables";
import caretRight from "../icons/caret-right.svg";
import defaults from "../config/defaults.json";
import { generateRenewalUrl } from "../functions/generateRenewalUrl";
import { createCartUrlForSubscription } from "shared-frontend/functions/cart";
import groupBy from "../functions/groupBy";

const messages = defineMessages( {
	header: {
		id: "renewal.notification.header",
		defaultMessage: "One or more subscriptions are about to expire!",
	},
	description: {
		id: "renewal.notification.description",
		defaultMessage: "When they do, you will no longer receive (security) updates or support!",
	},
	close: {
		id: "renewal.notification.close",
		defaultMessage: "close",
	},
	linkPlaceholder: {
		id: "renewal.notification.link.placeholder",
		defaultMessage: "{renewalLink}",
	},
	linkMessage: {
		id: "renewal.notification.link.message",
		defaultMessage: "Renew now",
	},
	alternateLinkMessage: {
		id: "renewal.notification.link.alternateMessage",
		defaultMessage: "Buy subscription",
	},
	productHeader: {
		id: "headers.product",
		defaultMessage: "Subscription number",
	},
	expiryHeader: {
		id: "headers.expiry",
		defaultMessage: "Expires on",
	},
} );

const MessageContainer = styled.div`
	background-color: ${ colors.$color_white };
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
	position: relative;
	display: block;

	> button {
		position: absolute;
		right: 16px;
		top: 16px;
	}
`;

const Header = styled.h2`
	margin: 0 32px 4px 0;
	font-size: 25px;
`;

const ImageTextContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 16px;

`;

const InlineBlock = styled.div`
	display: inline-block;
	vertical-align: middle;
`;

const RenewalImage = styled.img`
	flex: 0 1 auto;
	max-height: 100px;
	margin-right: 24px;

	@media screen and (max-width: ${ defaults.css.breakpoint.mobile }px) {
		display: none;
	}
`;

const RenewalItem = styled.p`
	display: flex;
	align-items: center;
	margin: 0 0 8px 0;
`;

const RenewalItemImage = styled.img`
	width: 32px;
	height: 32px;
	margin-right: 12px;
`;

// The labels for the column need sightly more room in the notification.
const ColumnPrimaryResponsive = styled( makeFullWidth( responsiveHeaders( ColumnPrimary ) ) )`
	@media screen and (max-width: ${ defaults.css.breakpoint.mobile }px) {
		&::before {
			min-width: 88px;
		}
	}
`;

const ColumnFixedWidthResponsive = makeFullWidth( responsiveHeaders( ColumnFixedWidth ) );

const RenewButton = makeButtonFullWidth( YellowCaretLink );

/**
 * Renewal notification message
 *
 * To help the customer understand what happened to licenses.
 *
 * @param {object} props Properties of the component.
 * @returns {ReactElement} Subscription component.
 * @constructor
 */
class RenewalNotification extends React.Component {
	/**
	 * Initializes the renewal notification.
	 */
	constructor()  {
		super();
		this.state = {
			hideNotification: false,
		};
		this.onCrossClick = this.onCrossClick.bind( this );
	}

	/**
	 * Hides notification after component mount if the notification was clicked away before.
	 *
	 * @returns {void}
	 */
	componentDidMount() {
		if ( Cookies.get( "hideRenewalNotification" ) ) {
			this.setState( { hideNotification: true } );
		}
		if ( ! this.props.allSubscriptions ) {
			this.props.loadData();
		}
	}

	/**
	 * Called on cross click.
	 *
	 * @returns {void}
	 */
	onCrossClick() {
		Cookies.set( "hideRenewalNotification", "true", { expires: 7 } );
		this.setState( { hideNotification: true } );
	}

	subscriptionRenewalRow( renewals ) {
		const firstRenewal = renewals[ 0 ];
		const oneMonthAgo = new Date();
		oneMonthAgo.setMonth( oneMonthAgo.getMonth() - 1 );
		const expiredMoreThanOneMonthAgo = ( firstRenewal.nextPayment && firstRenewal.nextPayment < oneMonthAgo ) ||
			( firstRenewal.endDate && firstRenewal.endDate < oneMonthAgo );
		const subscriptionNumber = firstRenewal.subscriptionNumber;

		return (
			<CompactRow key={ subscriptionNumber } style={ { flexWrap: "wrap" } }>
				<ColumnPrimaryResponsive
					headerLabel={ this.props.intl.formatMessage( messages.productHeader ) }
					ellipsis={ true }
				>
					<strong>#</strong> { subscriptionNumber }
				</ColumnPrimaryResponsive>
				<ColumnPrimaryResponsive
					headerLabel={ this.props.intl.formatMessage( messages.expiryHeader ) }
				>
					<FormattedDate
						value={ firstRenewal.nextPayment }
						year="numeric"
						month="long"
						day="2-digit"
					/>
				</ColumnPrimaryResponsive>
				<ColumnFixedWidthResponsive>
					<RenewButton
						to={ expiredMoreThanOneMonthAgo ? createCartUrlForSubscription( firstRenewal ) : generateRenewalUrl( firstRenewal ) }
						linkTarget="_blank"
						iconSource={ caretRight }
						className={ expiredMoreThanOneMonthAgo ? "buy_subscription_button_cta" : "renew_now_button_cta" }
					>
						<FormattedMessage { ...( expiredMoreThanOneMonthAgo ? messages.alternateLinkMessage : messages.linkMessage ) } />
					</RenewButton>
				</ColumnFixedWidthResponsive>
				<ColumnPrimaryResponsive style={ { flexBasis: "100%", paddingLeft: 0, fontSize: "0.8rem" } }>
					{ renewals.map( item => (
						<RenewalItem key={ item.id }>
							<RenewalItemImage src={ item.product.icon } />
							<span style={ { opacity: 0.65 } }>{ item.limit } x { item.name }</span>
						</RenewalItem>
					) ) }
				</ColumnPrimaryResponsive>
			</CompactRow>
		);
	}

	/**
	 * Creates a list component with the upcoming renewals.
	 *
	 * @param {Array} upcomingRenewals The upcoming renewals.
	 *
	 * @returns {ReactElement} The upcoming renewals component.
	 */
	listUpcomingRenewals( upcomingRenewals ) {
		const groupedRenewals = Object.values( groupBy( upcomingRenewals, "subscriptionNumber" ) );
		const upcomingRenewalsList = groupedRenewals.map( renewalGroup => {
			return this.subscriptionRenewalRow( renewalGroup );
		} );

		return (
			<ListTable invertZebra={ true }>
				{ upcomingRenewalsList }
			</ListTable>
		);
	}

	/**
	 * Renders the message.
	 *
	 * @returns { ReactElement|null} Returns a message container including the renewalNotification or null.
	 */
	render() {
		if ( this.props.upcomingRenewals.length < 1 ) {
			return null;
		}

		// The first item in the array is the first upcoming renewal (sorted in the container).
		const earliestRenewal = this.props.upcomingRenewals[ 0 ];

		if ( this.state.hideNotification || ! earliestRenewal ) {
			return null;
		}

		return (
			<MessageContainer>
				<CloseButtonTopRight
					onClick={ this.onCrossClick }
					aria-label={ this.props.intl.formatMessage( messages.close ) }
				/>
				<ImageTextContainer>
					<RenewalImage src={ renewalNotification } />
					<InlineBlock>
						<Header>
							<FormattedMessage { ...messages.header } />
						</Header>
						<FormattedMessage
							{ ...messages.description }
						/>
					</InlineBlock>
				</ImageTextContainer>
				{ this.listUpcomingRenewals( this.props.upcomingRenewals ) }
			</MessageContainer>
		);
	}
}

RenewalNotification.propTypes = {
	intl: intlShape.isRequired,
	upcomingRenewals: PropTypes.array,
	loadData: PropTypes.func.isRequired,
	allSubscriptions: PropTypes.object,
};

RenewalNotification.defaultProps = {
	upcomingRenewals: [],
	allSubscriptions: {},
};

export default injectIntl( RenewalNotification );
