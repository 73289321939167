import { connect } from "react-redux";

import AccountPage from "../components/AccountPage";
import { isOnlyProvisionerSubscriptions } from "../redux/selectors/ui/subscriptions";

// eslint-disable-next-line require-jsdoc
const mapStateToProps = state => {
	return {
		isOnlyProvisionerSubscriptions: isOnlyProvisionerSubscriptions( state ),
	};
};

const AccountPageContainer = connect(
	mapStateToProps,
)( AccountPage );

export default AccountPageContainer;
