import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import React, { Fragment } from "react";
import PropTypes from "prop-types";

import MyYoastModal from "../MyYoastModal";
import { ModalHeading } from "../Headings";
import Link from "../Link";
import { Button, ButtonLink, makeButtonFullWidth } from "../Button";
import { generateRenewalUrl } from "../../functions/generateRenewalUrl";
import { createCartUrlForSubscription } from "shared-frontend/functions/cart";

const messages = defineMessages( {
	paymentSuspended: {
		id: "subscriptionDetail.modal.paymentSuspended",
		defaultMessage: "Unfortunately we were not able to bill your payment method for the next billing cycle. " +
			"This means we have suspend the subscription until the problem is resolved. Sorry for the inconvenience!",
	},
	paymentFailed: {
		id: "subscriptionDetail.header",
		defaultMessage: "Payment Failed",
	},
	manualFix: {
		id: "subscriptionDetail.modal.manualFix",
		defaultMessage: "Whatever the reason may be for the failed payment, you will have to renew your " +
			"subscription manually to fix this. After doing so, the new payment info you provide will be used to " +
			"reactivate your subscription.",
	},
	expiredManualFix: {
		id: "subscriptionDetail.modal.manualFix",
		defaultMessage: "Unfortunately we were not able to bill your payment method for the next billing cycle." +
			" As the failed payment occurred more than a month ago, you will have to buy a new subscription.",
	},
	cancel: {
		id: "subscriptionDetail.modal.cancelButton",
		defaultMessage: "Cancel",
	},
	renew: {
		id: "subscriptionDetail.modal.renewLink",
		defaultMessage: "Renew",
	},
	buy: {
		id: "subscriptionDetail.modal.buyLink",
		defaultMessage: "Buy subscription",
	},
} );

const modalAriaLabel = defineMessages( {
	id: "modal.arialabel.paymentFailed",
	defaultMessage: "Payment failed",
} );

const ButtonArea = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const Modal = styled( MyYoastModal )`
	 padding: 24px 16px 24px 16px !important;
	 &.my-yoast-modal__content {
	    max-width: 640px;
	 }
`;

const ButtonClose = makeButtonFullWidth( Button );
const ButtonRenew = makeButtonFullWidth( ButtonLink );

const ButtonMarginRight = styled( ButtonClose )`
	margin: 0 ${ props => props.marginRight }px 0 0;
`;

/**
 * Returns the SubscriptionDetailModal.
 *
 * @param {Object} props The props required.
 * @returns { ReactElement } The SubscriptionDetailModal.
 */
const SubscriptionDetailModal = ( props ) => {
	const OneMonthAgo = new Date();
	OneMonthAgo.setMonth( OneMonthAgo.getMonth() - 1 );
	const expiredMoreThanOneMonthAgo = ( props.subscription.nextPayment && props.subscription.nextPayment < OneMonthAgo ) ||
		( props.subscription.hasEndDate && props.subscription.endDate && props.subscription.endDate < OneMonthAgo );
	const formattedMessage = <FormattedMessage
		id="subscriptionDetail.modal.help"
		defaultMessage="Let us know if you need any help, { supportLink }!"
		values={ {
			supportLink: <Link to="mailto:support@yoast.com">{
				props.intl.formatMessage( {
					id: "subscriptionDetail.support-link",
					defaultMessage: "our support team is available 24/7",
				} )
			}</Link>,
		} }
	/>;

	return (
		<Modal
			isOpen={ props.modalOpen }
			onClose={ props.onClose }
			modalAriaLabel={ modalAriaLabel }
		>
			<Fragment>
				<ModalHeading>
					{ props.intl.formatMessage( messages.paymentFailed ) }
				</ModalHeading>
				{ expiredMoreThanOneMonthAgo ? ""
					: <p>
						{ props.intl.formatMessage( messages.paymentSuspended ) }
					</p>
				}
				<p>
					{ expiredMoreThanOneMonthAgo
						? props.intl.formatMessage( messages.expiredManualFix )
						: props.intl.formatMessage( messages.manualFix )
					}
				</p>
				{ expiredMoreThanOneMonthAgo ? ""
					: <p>
						{ formattedMessage }
					</p>
				}
				<ButtonArea>
					<ButtonMarginRight
						marginRight={ 15 }
						enabledStyle={ false }
						onClick={ props.onClose }
					>
						{ props.intl.formatMessage( messages.cancel ) }
					</ButtonMarginRight>
					<ButtonRenew
						to={ expiredMoreThanOneMonthAgo
							? createCartUrlForSubscription( props.subscription )
							: generateRenewalUrl( props.subscription ) }
					>
						{ expiredMoreThanOneMonthAgo ? props.intl.formatMessage( messages.buy ) : props.intl.formatMessage( messages.renew ) }
					</ButtonRenew>
				</ButtonArea>
			</Fragment>
		</Modal>
	);
};


SubscriptionDetailModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	subscription: PropTypes.object.isRequired,
	modalOpen: PropTypes.bool,
	intl: intlShape.isRequired,
};

SubscriptionDetailModal.defaultProps = {
	modalOpen: false,
};

export default injectIntl( SubscriptionDetailModal );
