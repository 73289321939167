import styled from "styled-components";
import React, { useState } from "react";

import { getUserId, getAccessToken } from "shared-frontend/functions/auth";

const Info = styled.div`
	margin: var(--gap);
	background: white;
  	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
	padding: 0;
  	overflow: hidden;
`;

const InfoContent = styled.div`
	padding: 1em;
`;

const InfoTitle = styled.div`
	padding: 0.5em 1em;
	background-color: var(--color-purple-900);
	color: white;
	font-weight: 500;
`;

const LabelTokenWrapper = styled.div`
  	margin: 0 0 var(--gap) 0;
  
  	&:last-of-type {
	  	margin-bottom: 0;
    }
`;

const Label = styled.p`
  	display: flex;
  	align-items: center;
  	justify-content: space-between;
  	margin: 0 0 calc(var(--gap) / 4) 0;
  	padding: 0 0 calc(var(--gap) / 4) 0;
  	border-bottom: 1px solid var(--border-color);
`;

const Token = styled.p`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
  	margin: 0;
`;

const CopyButton = styled.button`
	margin-left: calc(var(--gap) / 2);
  	border: none;
  	padding: calc(var(--gap) / 4) calc(var(--gap) / 2);
  	background-color: var(--color-gray-200);
  	border-radius: calc(var(--border-radius) / 2);
  	color: var(--text-color-blue);
  	font-size: var(--text-font-size-small);
  	cursor: pointer;
  
  	:hover {
	  	background-color: var(--color-gray-300);
    }
`;

/**
 * Copies to clipboard
 * @param {String} text Text to be copied.
 * @returns {boolean|void} Nothing.
 */
const copyToClipboard = ( text ) => {
	if ( window.clipboardData && window.clipboardData.setData ) {
		return window.clipboardData.setData( "Text", text );
	}

	if ( document.queryCommandSupported && document.queryCommandSupported( "copy" ) ) {
		const textarea = document.createElement( "textarea" );
		textarea.textContent = text;
		textarea.style.position = "fixed";
		document.body.appendChild( textarea );
		textarea.select();
		try {
			return document.execCommand( "copy" );
		} catch ( error ) {
			console.warn( "Copy to clipboard failed.", error );
		} finally {
			document.body.removeChild( textarea );
		}
	}
};

/**
 * Some info to help debugging
 *
 * @returns {JSXElement} The element.
 */
export default function DebugInfo() {
	if ( process.env.NODE_ENV !== "development" ) {
		return null;
	}

	const [ userIdCopied, setUserIdCopied ] = useState( false );
	const [ accessTokenCopied, setAccessTokenCopied ] = useState( false );

	/**
	 * Handles copying to clipboard.
	 *
	 * @param {Event} event Event type (click in this case).
	 * @param {String} valueToCopy Text to be copied.
	 * @returns {boolean|void} Nothing.
	 */
	const handleCopyToClipboard = ( event, valueToCopy ) => {
		if ( event.target.id === "copyUserId" ) {
			setUserIdCopied( true );
			setAccessTokenCopied( false );
		}

		if ( event.target.id === "copyAccessToken" ) {
			setUserIdCopied( false );
			setAccessTokenCopied( true );
		}

		return copyToClipboard( valueToCopy );
	};

	return <Info>
		<InfoTitle>DEBUG</InfoTitle>
		<InfoContent>
			<LabelTokenWrapper>
				<Label>
					<strong>User ID</strong>
					<CopyButton
						id="copyUserId"
						onClick={ event => handleCopyToClipboard( event, getUserId() ) }
					>
						{ userIdCopied ? "Copied" : "Copy" }
					</CopyButton>
				</Label>
				<Token>{ getUserId() }</Token>
			</LabelTokenWrapper>
			<LabelTokenWrapper>
				<Label>
					<strong>Access Token</strong>
					<CopyButton
						id="copyAccessToken"
						onClick={ event => handleCopyToClipboard( event, getAccessToken() ) }
					>
						{ accessTokenCopied ? "Copied" : "Copy" }
					</CopyButton>
				</Label>
				<Token>{ getAccessToken() }</Token>
			</LabelTokenWrapper>
		</InfoContent>
	</Info>;
}
