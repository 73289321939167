import React from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
// Components.
import LoginMessage from "./LoginMessage";
import { ButtonLink } from "../Button";

const messages = defineMessages( {
	successMessage: {
		id: "reset.message",
		defaultMessage: "Password changed successfully!",
	},
	continueButton: {
		id: "continue.button",
		defaultMessage: "Continue to MyYoast",
	},
} );

const Wrapper = styled.div`
	max-width: 480px;
`;

const StyledButtonLink = styled( ButtonLink )`
	position: relative;
	margin: 1em 0;
	width: 100%;
`;

/**
 * Page to show the password change was succeeded.
 */
class ResetPasswordSuccessPage extends React.Component {
	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return (
			<Wrapper>
				<LoginMessage header={ messages.successMessage } />
				{ this.renderButton() }
			</Wrapper>
		);
	}

	/**
	 * Creates a button with the message as given in the props.
	 * Adds the onClick callback (also given in the props).
	 *
	 * @returns {React.Component} the button, with the onClick callback added.
	 */
	renderButton() {
		return (
			<StyledButtonLink to={ "/login" }>
				<FormattedMessage { ...messages.continueButton } />
			</StyledButtonLink>
		);
	}
}
export default injectIntl( ResetPasswordSuccessPage );

ResetPasswordSuccessPage.propTypes = {
	intl: intlShape.isRequired,
};
