import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import Link from "../Link";
import Card from "../card/index";

const ListItem = styled.div`
	display: flex;
	margin-bottom: 16px
`;

const Icon = styled.img`
	width: 48px;
	height: 48px;
	margin-right: 16px;
	flex-shrink: 0;
`;

/**
 * A function that returns the UpsellItem, which is a collection of icon, link, and description.
 *
 * @param {Object} props The props for the UpsellItem component.
 *
 * @returns {ReactElement} The UpsellItem.
 */
const UpsellItem = ( props ) => {
	return (
		<ListItem>
			<Icon src={ props.icon } alt="" />
			<div>
				<Link to={ props.link.url } linkTarget="_blank"  hasExternalLinkIcon={ true }>
					{ props.link.name }
				</Link>
				<p>
					<FormattedMessage
						id={ props.description.id }
						defaultMessage={ props.description.defaultMessage }
					/>
				</p>
			</div>
		</ListItem>
	);
};

UpsellItem.propTypes = {
	icon: PropTypes.string.isRequired,
	link: PropTypes.shape(
		{
			name: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
		},
	).isRequired,
	description: PropTypes.shape(
		{
			id: PropTypes.string.isRequired,
			defaultMessage: PropTypes.string.isRequired,
		},
	).isRequired,
};

/**
 * A function that returns the UpsellCard component.
 *
 * @param {Object} props The props required for the UpsellCard component.
 *
 * @returns {ReactElement} The UpsellCard component.
 */
const UpsellCard = ( props ) => {
	return (
		<Card>
			<Card.Header>
				<h2>
					<FormattedMessage
						id={ `${ props.id }.${ props.header.id }` }
						defaultMessage={ props.header.defaultMessage }
					/>
				</h2>
			</Card.Header>
			<Card.Content className="no-margin">
				{
					props.listPropsArray.map( ( listProps, index ) => {
						return (
							<UpsellItem { ...listProps } key={ `${ props.id }-key-${ index }` } />
						);
					} )
				}
			</Card.Content>
		</Card>
	);
};

UpsellCard.propTypes = {
	id: PropTypes.string,
	header: PropTypes.object.isRequired,
	listPropsArray: PropTypes.array.isRequired,
};

UpsellCard.defaultProps = {
	id: null,
};

export default injectIntl( UpsellCard );
