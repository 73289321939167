/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import { colors } from "@yoast/style-guide";
import isEmpty from "lodash/isEmpty";
import { Redirect } from "react-router";
/* Internal dependencies */
import { emailConstraints } from "./CommonConstraints";
import logo from "../../images/my-yoast-academy-logo.svg";
import { Button, ButtonLink } from "../Button";
import ValidationInputField from "../ValidationInputField";
import { StyledLabel } from "../Labels";
import { ButtonArea } from "../account/profile/FormElements";
import ErrorDisplay, { ErrorPropTypeShape } from "../../errors/ErrorDisplay";

const messages = defineMessages( {
	passwordResetTitle: {
		id: "reset.password",
		defaultMessage: "Password reset",
	},
	labelEmail: {
		id: "email.address",
		defaultMessage: "Email address",
	},
	emailAddressMessage: {
		id: "email.address.message",
		defaultMessage: "Please enter your email address. You will receive a link to create a new password via email.",
	},
	sendButton: {
		id: "reset.button",
		defaultMessage: "Send password reset email",
	},
	loadingButton: {
		id: "reset.button.loading",
		defaultMessage: "Sending password reset email...",
	},
	backButton: {
		id: "back.button",
		defaultMessage: "Back to login form",
	},
} );

// Styled components.
const Header = styled.div`
	text-align: center;
	margin-bottom: 40px;
`;

const Logos = styled.img`
	max-width: 360px;
`;

const Wrapper = styled.div`
	max-width: 480px;
`;

const Title = styled.h1`
	margin-bottom: 0.5em;
`;

const FormGroup = styled.form`
	position: relative;
	width: 100%;
	min-height: 300px;
	margin-top: 40px;
`;

const LabelBlock = styled.div`
	width: 100%;
`;

const Label = styled( StyledLabel )`
	margin-top: 5px;
`;

const EmailButtonArea = styled( ButtonArea )`
	margin-top: 3em;
`;

const SaveButton = styled( Button )`
	margin: 1em 0;
	width: 100%;
	background-color: ${ props => props.enabledStyle ? colors.$color_green_medium_light : colors.$color_grey_disabled };
`;

const BackButton = styled( ButtonLink )`
	margin: 1em 0;
	width: 100%;
	color: ${ colors.$color_black };
	background-color: ${ colors.$color_white };
	border: 1px solid ${ colors.$color_black };
	text-shadow: none;

	&:hover,
	&:focus {
		background-color: ${ colors.$color_green_medium_light };
		color: ${ colors.$color_white };
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
		border: 0;
	}
`;

/**
 * Page to send a email to reset the password of an account.
 */
class ResetPasswordEmailPage extends React.Component {
	/**
	 * Constructor.
	 *
	 * @param {object} props The props.
	 */
	constructor( props ) {
		super( props );

		// Default state.
		this.state = {
			email: this.props.email,
			errors: [],
			validating: false,
		};

		this.handleSubmit = this.handleSubmit.bind( this );

		this.onUpdateEmail = this.onUpdateEmail.bind( this );

		// Validation constraints.
		this.constraints = {
			email: emailConstraints( this.props.intl ),
		};
	}

	/**
	 * Updates the specified field in the state,
	 * to be used as callback functions in text input fields.
	 *
	 * @param {string} value the input field value.
	 * @param {array} errors The email related errors.
	 * @param {boolean} validating Whether or not the email is in the process of being validated.
	 *
	 * @returns {void}
	 */
	onUpdateEmail( value, errors = [], validating = false ) {
		this.setState( {
			email: value,
			errors: errors,
			validating: validating,
		} );
	}

	/**
	 * Provides the email address to send a pass word reset link to.
	 *
	 * @param { object } event The button click event.
	 *
	 * @returns {void}
	 */
	handleSubmit( event ) {
		event.preventDefault();
		if ( this.canSubmit() === false ) {
			return;
		}
		this.props.attemptResetPasswordEmail( { email: this.state.email } );
	}

	/**
	 * Checks whether or not the form may be submitted.
	 *
	 * @returns {boolean} Whether or not the form may be submitted
	 */
	canSubmit() {
		return ! isEmpty( this.state.email ) && this.state.errors.length === 0 && ! this.props.loading && ! this.state.validating;
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		if ( this.props.passwordRequestSent ) {
			return ( <Redirect to={ "/forgot-password/check-your-email" } /> );
		}

		let buttonText = messages.sendButton;

		if ( this.props.loading ) {
			buttonText = messages.loadingButton;
		}
		return (
			<Wrapper>
				<Header>
					<Logos src={ logo } alt="MyYoast - Yoast Academy" />
				</Header>
				<Title>
					<FormattedMessage { ...messages.passwordResetTitle } />
				</Title>
				<FormattedMessage { ...messages.emailAddressMessage } />
				<FormGroup onSubmit={ this.handleSubmit }>
					<ErrorDisplay error={ this.props.error } />
					<LabelBlock>
						<Label htmlFor="email">
							<FormattedMessage { ...messages.labelEmail } />
						</Label>
						<ValidationInputField
							id="email"
							name="email"
							type="email"
							errors={ [] }
							onChange={ this.onUpdateEmail }
							constraint={ emailConstraints( this.props.intl ) }
							value={ this.state.email }
						/>
					</LabelBlock>

					<EmailButtonArea>
						<SaveButton
							type="submit"
							enabledStyle={ this.canSubmit() }
							aria-disabled={ ! this.canSubmit() }
						>
							<FormattedMessage { ...buttonText } />
						</SaveButton>
						<BackButton enabledStyle={ true } to="../login">
							<FormattedMessage { ...messages.backButton } />
						</BackButton>
					</EmailButtonArea>
				</FormGroup>
			</Wrapper>
		);
	}
}

ResetPasswordEmailPage.propTypes = {
	intl: intlShape.isRequired,
	email: PropTypes.string,
	passwordRequestSent: PropTypes.bool,
	loading: PropTypes.bool,
	error: ErrorPropTypeShape,
	attemptResetPasswordEmail: PropTypes.func.isRequired,
};

ResetPasswordEmailPage.defaultProps = {
	email: "",
	passwordRequestSent: false,
	loading: false,
	error: null,
};

export default injectIntl( ResetPasswordEmailPage );
