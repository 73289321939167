import React from "react";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
// Different Brands.
import AcademyLoginAside from "./AcademyLoginAside";
import DefaultLoginAside from "./DefaultLoginAside";
import DefaultSignupAside from "./DefaultSignupAside";
import PostCheckoutLoginAside from "./PostCheckoutLoginAside";
import ActivateAside from "../ActivateAside";

const MainPaper = styled.div`
	padding: 0px;
	margin: 0 auto;
	max-width: 480px;
	height: 480px;
	text-align: center;
`;

/**
 * A card representing a message in a login screen,
 * e.g. "Welcome back!", "Password changed".
 *
 * @param  {object} props The props.
 *
 * @returns {ReactElement} The component that contains the message.
 */
function LoginMessage( props ) {
	const { asideType } = props;

	/**
	 * Switch between different brands.
	 *
	 * @param  {string} asideTypeString The aside type used to make a selection.
	 *
	 * @returns {ReactElement} The component with the corresponding brand.
	 */
	function selectAside( asideTypeString ) {
		switch ( asideTypeString ) {
			case "academy" :
				return <AcademyLoginAside />;
			case "post-checkout":
				return <PostCheckoutLoginAside />;
			case  "signup" :
				return <DefaultSignupAside />;
			case  "login" :
				return <DefaultLoginAside />;
			case  "activate" :
				return <ActivateAside />;
			default:
				return <DefaultSignupAside />;
		}
	}

	return (
		<MainPaper>
			{ selectAside( asideType ) }
		</MainPaper>
	);
}

LoginMessage.propTypes = {
	asideType: PropTypes.string,
};

LoginMessage.defaultProps = {
	asideType: "signup",
};

export default injectIntl( LoginMessage );
