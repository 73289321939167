import { connect } from "react-redux";

import DownloadsPage from "../components/DownloadsPage";
import { getAllProducts } from "shared-frontend/redux/actions/products";
import { onSearchQueryChange } from "../redux/actions/search";
import { getProductGroups } from "shared-frontend/redux/actions/productGroups";
import { getAllSubscriptions } from "shared-frontend/redux/actions/subscriptions";
import { getOrders } from "shared-frontend/redux/actions/orders";
import { createComposerToken, fetchComposerTokens } from "shared-frontend/redux/actions/composerTokens";
import { composerHelpModalClosed, composerHelpModalOpen } from "../redux/actions/composerTokens";
import { getQueryEbooks, getQueryPluginsForDownload, getSearchQuery } from "shared-frontend/redux/selectors/entities/search";
import { getFirstEnabledComposerToken } from "shared-frontend/redux/selectors/entities/composerTokens";
import {
	hasMixedSubscriptions,
	isOnlyProvisionerSubscriptions,
	provisioners,
} from "../redux/selectors/ui/subscriptions";

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state ) => {
	return {
		query: getSearchQuery( state ),
		eBooks: getQueryEbooks( state ),
		plugins: getQueryPluginsForDownload( state ),
		composerToken: getFirstEnabledComposerToken( state ),
		composerHelpModalIsOpen: state.ui.composerTokens.composerHelpModalIsOpen,
		composerHelpProductName: state.ui.composerTokens.composerHelpProductName,
		composerHelpProductGlNumber: state.ui.composerTokens.composerHelpProductGlNumber,
		isOnlyProvisionerSubscriptions: isOnlyProvisionerSubscriptions( state ),
		hasMixedSubscriptions: hasMixedSubscriptions( state ),
		provisioners: provisioners( state ),
		subscriptionsLoaded: state.ui.subscriptions.firstTimeLoaded,
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	dispatch( getAllProducts() );
	dispatch( getProductGroups() );
	dispatch( getAllSubscriptions() );
	dispatch( getOrders() );
	dispatch( fetchComposerTokens() );
	return {
		onSearchChange: ( query ) => {
			dispatch( onSearchQueryChange( query ) );
		},
		onComposerHelpModalClose: () => {
			dispatch( composerHelpModalClosed() );
		},
		onComposerHelpModalOpen: ( productName, glNumber, composerToken ) => {
			dispatch( composerHelpModalOpen( productName, glNumber, composerToken ) );
		},
		composerHelpCreateComposerToken: () => {
			dispatch( createComposerToken( { name: "Default Token" } ) );
		},
	};
};

const DownloadsPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( DownloadsPage );

export default DownloadsPageContainer;
