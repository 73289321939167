import React, { Fragment, useState, useContext } from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import { colors } from "@yoast/style-guide";
import { browserName } from "react-device-detect";

import Link from "../Link";
import { InstallContext } from "./InstallContext";
import CheckoutLadyImage from "../../icons/checkout-lady.svg";
import CheckoutSuccessImage from "../../icons/checkout-success.svg";
import LogoImage from "../../icons/Yoast-logo.svg";
import { Button } from "../Button";
import defaults from "../../config/defaults.json";
import Alert from "../Alert";
import useRequest from "../../reactHooks/useRequest";

const messages = defineMessages( {
	checkout: {
		id: "downloading.checkout",
		defaultMessage: "Checkout",
	},
	stepOne: {
		id: "downloading.stepOne",
		defaultMessage: "Enter your details",
	},
	stepTwo: {
		id: "downloading.stepTwo",
		defaultMessage: "Pay",
	},
	stepThree: {
		id: "downloading.stepThree",
		defaultMessage: "Receive your products",
	},
	stepFour: {
		id: "downloading.stepFour",
		defaultMessage: "Install",
	},
	title: {
		id: "downloading.title",
		defaultMessage: "Downloading...",
	},
	subheaderTitle: {
		id: "downloading.subheaderTitle",
		defaultMessage: "Need help?",
	},
	orderSuccess: {
		id: "downloading.orderSuccess",
		defaultMessage: "Your order has been processed successfully!",
	},
	nextSteps1: {
		id: "downloading.nextstep.firstLine",
		defaultMessage: "Yoast SEO Premium is being downloaded. After the file successfully downloads to your computer, " +
			"you can proceed to your website where you can install and activate your purchase.",
	},
	goToSiteButton: {
		id: "downloading.goToSiteButton",
		defaultMessage: "I'm ready. Please take me to my website",
	},
	needHelp: {
		id: "downloading.needHelp",
		defaultMessage: "Check out the video below where we'll walk you through the final steps of installing " +
		"and activating Yoast SEO Premium for your website.",
	},
	videoTitle: {
		id: "downloading.videoTitle",
		defaultMessage: "Installing your plugin",
	},
	siteSelectedForInstallation: {
		id: "downloading.siteSelectedForInstallation",
		defaultMessage: "You've selected a site to install your products on, use the install button to continue.",
	},
	myYoastLink: {
		id: "downloading.myYoastLink",
		defaultMessage: "your MyYoast account.",
	},
	installOtherPluginsInfoAlert: {
		id: "downloading.installOtherPluginsInfoAlert",
		defaultMessage: "This will only install {bold}. If you also want to install other Yoast plugins, find and download them from {link}",
	},
	seoPremium: {
		id: "downloading.seoPremium",
		defaultMessage: "Yoast SEO Premium",
	},

} );

const Header = styled.div`
	width: 100%;
	background-color: ${ colors.$color_purple_dark };
	padding: 32px 16px;
`;

const WideContent = styled.div`
	max-width: ${ defaults.css.breakpoint.medium }px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;

const HeaderTitle = styled.h3`
	color: white;
	font-size: 2.5em;
	line-height: 1.2;
	font-weight: 200;
	margin: 0;
`;

const HeaderImage = styled.div`
	margin: -24px 0 -38px;
	width: 100%;
	max-width: 30%;
	vertical-align: bottom;

	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		display: none;
	}
`;

const Steps = styled.ol`
	width: 100%;
	display: flex;
	align-items: baseline;
	flex-direction: row;
	list-style: none;
	margin: 32px 0;
	padding: 0;
	justify-content: space-between;
	position: relative;
	&:before {
		content:' ';
		z-index: 0;
		border-top: 1px solid rgba(0,0,0,0.2);
		width: 100%;
		position: absolute;
		top: 13px;
	}
	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		display: none;
	}
`;

const Step = styled.li`
	font-size: 1em;
	z-index: 1;
	padding: 0 8px;
	background-color: white;
	color: #707070;
	margin: 0;
	counter-increment: indicator-step;
	&:before {
		content: counter(indicator-step);
		display: inline-block;
		overflow: hidden;
		width: 20px;
		height: 20px;
		line-height: 20px;
		margin-right: 8px;
		margin-bottom: -5px;
		border-radius: 50%;
		background-color: #707070;
		text-align: center;
		font-size: 11px;
		color: #fff;
	}
`;

const StepActive = styled( Step )`
	color: #303030;
	font-weight: 600;
	&:before {
		background-color: ${ colors.$color_pink_dark };
	}
`;

const SuccessImage = styled.img`
	margin: 48px auto 0;
	width: 200px;
`;

const Title = styled.h2`
	padding: 0;
	margin: 16px 0;
	color: ${ colors.$color_pink_dark };
	font-weight: 400;
	font-size: 2.5em;
`;


const SubheaderTitle = styled.h4`
	padding: 0;
	margin: 16px 0;
	color: ${ colors.$color_pink_dark };
	font-weight: 200;
	font-size: 1.8em;
`;

const CenterContent = styled.div`
	text-align: center;
	max-width: 600px;
	margin: 0 auto;
	padding: 0 16px;
`;

const GoToSiteButton = styled( Button )`
	margin: 1em 0;
	background-color: ${ colors.$color_pink_dark };
`;

const SEOPremiumInfoAlert = styled.p`
	padding: 0;
	margin: 0;
	text-align: left;
`;

const OtherPluginsInfo = styled.div`
	margin-top:16px;
`;

const myYoastLink = <Link to={ "/" } id="front-page">
	<FormattedMessage { ...messages.myYoastLink } />
</Link>;

const boldSEOPremium = <strong>
	<FormattedMessage { ...messages.seoPremium } />
</strong>;

/**
 * Renders the downloading component. Which shows instructions about installing Yoast plugins.
 *
 * @param {object} props The properties.
 *
 * @returns {ReactComponent} The rendered component.
 */
function Downloading( props ) {
	const { downloadZip, linkedSite, invoiceNumberParam } = useContext( InstallContext );

	const url = invoiceNumberParam ? `Orders/productGroups/${invoiceNumberParam}` : "";
	const { data: productGroups } = useRequest( url, "GET", {} );

	const plugins = productGroups.filter( productGroup => productGroup.parent && productGroup.parent.slug === "all-plugins" );

	/**
	 * Redirects the user to the upload plugin zip page on the selected site.
	 *
	 * @returns {void}
	 */
	function goToSite() {
		const customerSite = linkedSite.replace( /\/$/, "" );

		window.location = customerSite + "/wp-admin/plugin-install.php?tab=upload";
	}

	/**
	 * Returns the youtube embed url for the video intended for the user's browser.
	 *
	 * @returns {string} The youtube embed url.
	 */
	function getVideoForBrowser() {
		const wistiaIds = {
			Chrome: "wogh6vsr21",
			Safari: "wogh6vsr21",
			Firefox: "wogh6vsr21",
			Edge: "wogh6vsr21",
			"default": "wogh6vsr21",
		};

		let wistiaId = wistiaIds[ browserName ];

		if ( ! wistiaId ) {
			wistiaId = wistiaIds.default;
		}

		return `https://fast.wistia.net/embed/iframe/${ wistiaId }?dnt=1&autoPlay=false`;
	}

	const [ wistiaUrl ] = useState( () => getVideoForBrowser() );

	return (
		<Fragment>
			<Header>
				<WideContent>
					<div>
						<img src={ LogoImage } alt="Yoast SEO for everyone" />
						<HeaderTitle><FormattedMessage { ...messages.checkout } /></HeaderTitle>
					</div>
					<HeaderImage><img src={ CheckoutLadyImage } alt="" /></HeaderImage>
				</WideContent>
			</Header>

			<WideContent>
				<Steps>
					<Step><FormattedMessage { ...messages.stepOne } /></Step>
					<Step><FormattedMessage { ...messages.stepTwo } /></Step>
					<Step><FormattedMessage { ...messages.stepThree } /></Step>
					<StepActive><FormattedMessage { ...messages.stepFour } /></StepActive>
				</Steps>
			</WideContent>

			<CenterContent>
				<SuccessImage src={ CheckoutSuccessImage } alt={ props.intl.formatMessage( messages.orderSuccess ) } />

				<Title><FormattedMessage { ...messages.title } /></Title>
				<p><FormattedMessage { ...messages.nextSteps1 } /></p>

				<GoToSiteButton
					id="install-addon-redirect-action"
					className="install-addon-redirect-button"
					onClick={ goToSite }
				>
					<FormattedMessage{ ...messages.goToSiteButton } />
				</GoToSiteButton>

				{ ( ! invoiceNumberParam || plugins.length > 1 ) && (
					<OtherPluginsInfo>
						<Alert type="info" dismissable={ false } cookieName="installOtherPluginsInfoAlert">
							<SEOPremiumInfoAlert>
								<FormattedMessage
									{ ...messages.installOtherPluginsInfoAlert }
									values={ { link: myYoastLink, bold: boldSEOPremium } }
								/>
							</SEOPremiumInfoAlert>
						</Alert>
					</OtherPluginsInfo> )
				}

				<SubheaderTitle><FormattedMessage { ...messages.subheaderTitle } /></SubheaderTitle>
				<p><FormattedMessage { ...messages.needHelp } /></p>

				<iframe
					title={ messages.videoTitle.defaultMessage }
					width="500"
					height="250"
					src={ wistiaUrl }
					allowFullScreen={ true }
					frameBorder="0"
				/>

				<iframe
					title="download"
					width="0"
					height="0"
					src={ downloadZip }
					style={ { display: "none" } }
				/>

			</CenterContent>
		</Fragment>
	);
}

Downloading.propTypes = {
	intl: intlShape.isRequired,
};

export default injectIntl( Downloading );
