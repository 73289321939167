import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import icon from "../../images/greeting.png";
import logo from "../../images/my-yoast-academy-logo.svg";
import StyledHeading from "./StyledHeading";
import RenderParagraph from "./RenderParagraph";
import styled from "styled-components";

const messages = defineMessages( {
	header: {
		id: "login.defaultAside.header",
		defaultMessage: "Welcome back!",
	},
	message: {
		id: "login.defaultAside.message",
		defaultMessage: "Log in with your email address and password. If you don't remember your password, just reset it!",
	},
} );

const Icon = styled.img`
	margin-top: 20px;
	width: 200px;
`;

const Logos = styled.img`
	margin-top: 40px;
	width: 360px;
`;

/* eslint-disable max-len */
/**
 * Display's the left side with the Login branding.
 *
 * @returns {ReactElement} The component that contains the message.
 */
function DefaultLoginAside() {
	return (
		<div>
			<Logos src={ logo } decoding="async" fetchpriority="high" alt="MyYoast - Yoast Academy" width="360" height="37" />
			<Icon src={ icon } decoding="async" fetchPriority="high" alt="" width="200" height="200" />
			<StyledHeading header={ messages.header } />
			<RenderParagraph message={ messages.message } />
		</div>
	);
}
/* eslint-enable max-len */

export default injectIntl( DefaultLoginAside );
