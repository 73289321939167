/* External dependencies */
import React from "react";
/* Internal dependencies */
import ErrorDisplay from "../errors/ErrorDisplay";


/**
 * Displays the warnings for the provided field.
 *
 * @param {Array}  warnings The warnings that could be displayed.
 * @param {string} field    The field to display warnings for.
 *
 * @returns {ReactElement} List of JSXElements if warnings are found. Otherwise null.
 */
export function displayValidateJSWarnings( warnings, field ) {
	// Find warnings for the specified field.
	const fieldWarnings = warnings.filter( warning => {
		return warning.attribute === field;
	} );

	if ( ! fieldWarnings.length || fieldWarnings.length <= 0 ) {
		return null;
	}
	const warningToShow = fieldWarnings[ 0 ];
	return <ErrorDisplay key={ warningToShow.attribute } error={ { message: warningToShow.message, type: "warning" } } />;
}
