/* External dependencies */
import React from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape, FormattedMessage, defineMessages } from "react-intl";
import styled from "styled-components";
import { colors } from "@yoast/style-guide";

/* Internal dependencies */
import { InputField } from "../../InputField";
import { StyledLabel } from "../../Labels";

const FormGroup = styled.form`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	text-align: left;
`;

const LabelBlock = styled.div`
	width: 100%;
`;

const NameBlock = styled( LabelBlock )`
	div:last-of-type{
		margin-bottom: 0;
	}
`;

const messages = defineMessages( {
	labelFirstName: {
		id: "profile.label.firstName",
		defaultMessage: "First name",
	},
	labelLastName: {
		id: "profile.label.lastName",
		defaultMessage: "Last name",
	},
} );

/**
 * A component for entering the first- and last name and
 * uploading a profile image.
 */
class ProfileDetailsBlock extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
			userFirstName: this.props.userFirstName,
			userLastName: this.props.userLastName,
		};

		this.onUpdateFirstName = this.onUpdateField.bind( this, "userFirstName" );
		this.onUpdateLastName = this.onUpdateField.bind( this, "userLastName" );
		this.handleSubmit = this.handleSubmit.bind( this );
	}

	/**
	 * Updates the given field in the state with the target value in the
	 * given event.
	 *
	 * @param {string} field the field that needs to be updated.
	 * @param {object} event the event from which to get the new value.
	 * @returns {void}
	 */
	onUpdateField( field, event ) {
		this.setState( {
			[ field ]: event.target.value,
		} );
	}

	/**
	 * Calls the onSubmit function given in the props
	 * with the entered first name, last name and image file.
	 *
	 * @param {event} event The submit event.
	 * @returns {void}
	 */
	handleSubmit( event ) {
		event.preventDefault();
		this.props.onSubmit( this.state.userFirstName, this.state.userLastName );
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return <FormGroup onSubmit={ this.handleSubmit }>
			<NameBlock id="left">
				<StyledLabel htmlFor="first-name">
					<FormattedMessage { ...messages.labelFirstName } />
				</StyledLabel>
				<InputField
					id="first-name"
					name="first name"
					type="text"
					value={ this.state.userFirstName }
					onChange={ this.onUpdateFirstName }
					backgroundColor={ colors.$color_grey_light }
				/>
				<StyledLabel htmlFor="last-name">
					<FormattedMessage { ...messages.labelLastName } />
				</StyledLabel>
				<InputField
					id="last-name"
					name="last name"
					type="text"
					value={ this.state.userLastName }
					onChange={ this.onUpdateLastName }
					backgroundColor="var(--bg-color-offwhite)"
				/>
			</NameBlock>
			{ this.props.children }
		</FormGroup>;
	}
}

export default injectIntl( ProfileDetailsBlock );

ProfileDetailsBlock.propTypes = {
	intl: intlShape.isRequired,
	children: PropTypes.any,
	onSubmit: PropTypes.func.isRequired,
	userFirstName: PropTypes.string,
	userLastName: PropTypes.string,
	profileSaving: PropTypes.bool,
};

ProfileDetailsBlock.defaultProps = {
	userFirstName: "",
	userLastName: "",
	profileSaving: false,
};
