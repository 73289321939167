import PropTypes from "prop-types";
import React from "react";
import Transaction from "./Transaction";
import { ListTable } from "../../Tables";
import { Paper } from "../../PaperStyles";
import defaults from "../../../config/defaults.json";
import styled from "styled-components";
import TransactionObject from "../../../types/Transaction";


const TransactionsPaper = styled( Paper )`
	@media screen and ( max-width: ${ defaults.css.breakpoint.tablet }px ) {
		margin-top: 12px;
	}
`;

/**
 * Returns the rendered Orders component.
 *
 * @param {object} props Properties for this element.

 * @returns {ReactElement} PageOrderList element.
 */
const Transactions = props => {
	// In the event no orders are present in the props, treat the orders as empty.
	if ( props.transactions.some( transaction => ! transaction ) ) {
		return [];
	}

	const TransactionsTable = <ListTable>
		{ props.transactions.map( transaction => <Transaction transaction={ transaction } key={ transaction.id } /> ) }
	</ListTable>;

	if ( props.hasPaper ) {
		return <TransactionsPaper>{ TransactionsTable }</TransactionsPaper>;
	}

	return TransactionsTable;
};

export default Transactions;

Transactions.propTypes = {
	transactions: PropTypes.arrayOf( TransactionObject ),
	hasPaper: PropTypes.bool,
};

Transactions.defaultProps = {
	transactions: [],
	hasPaper: true,
};
