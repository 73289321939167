import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import icon from "../images/greeting.png";
import logo from "../images/my-yoast-academy-logo.svg";
import styled from "styled-components";
import StyledHeading from "./login/StyledHeading";
import RenderParagraph from "./login/RenderParagraph";

const messages = defineMessages( {
	header: {
		id: "activate.aside.header",
		defaultMessage: "Welcome!",
	},
	message: {
		id: "activate.aside.message",
		defaultMessage: "We are activating your MyYoast account. Please stay with us for a little while longer.",
	},
} );

const Icon = styled.img`
	margin-top: 20px;
	width: 200px;
`;

const Logos = styled.img`
	margin-top: 40px;
	max-width: 360px;
`;

/**
 * Display's the left side with the Signup branding.
 *
 * @returns {ReactElement} The component that contains the message.
 */
function ActivateAside() {
	return (
		<div>
			<Logos src={ logo } alt="MyYoast - Yoast Academy" />
			<Icon src={ icon } alt="" />
			<StyledHeading header={ messages.header } />
			<RenderParagraph message={ messages.message } />
		</div>
	);
}

export default injectIntl( ActivateAside );
