/* External dependencies */
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { defineMessages, FormattedDate, FormattedMessage, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import { colors } from "@yoast/style-guide";

/* Internal dependencies */
import MyYoastModal from "./MyYoastModal";
import ButtonsContainer from "./general/ButtonsContainer";
import {
	LargeButton,
	IconButton,
	LargeSecondaryButton,
	makeResponsiveIconButton,
	makeButtonFullWidth,
} from "./Button";
import ErrorDisplay, { ErrorPropTypeShape } from "../errors/ErrorDisplay";
import plus from "../icons/black-plus-thin.svg";
import minus from "../icons/black-minus-thin.svg";
import { InputField } from "./InputField";
import { ModalHeading } from "./Headings";
import { StyledLabel } from "./Labels";
import { toSafeInteger } from "lodash/lang";

const messages = defineMessages( {
	ariaLabel: {
		id: "subscriptionCancel.modal.arialabel",
		defaultMessage: "Cancel subscription",
	},
	header: {
		id: "subscriptionCancel.modal.header",
		defaultMessage: "Cancel subscription?",
	},
	body: {
		id: "subscriptionCancel.modal.body",
		defaultMessage: "Are you sure you want to cancel this subscription? " +
			"If you cancel, you will no longer receive support or security and functionality updates after {term}",
	},
	activeSites: {
		id: "subscriptionCancel.modal.activeSites",
		defaultMessage: "You have {amount} active {amount, plural, one {site} other {sites}} using this subscription.",
	},
	numberOfCurrentSubscriptions: {
		id: "subscriptionCancel.modal.numberSubscriptions",
		defaultMessage: "You have {amount} of this type of subscription.",
	},
	cancel: {
		id: "subscriptionCancel.modal.cancel",
		defaultMessage: "Cancel",
	},
	confirm: {
		id: "subscriptionCancel.modal.confirm",
		defaultMessage: "Confirm cancellation",
	},
	loading: {
		id: "subscriptionCancel.modal.loading",
		defaultMessage: "Cancelling subscription...",
	},
	amount: {
		id: "subscriptionCancel.modal.amount",
		defaultMessage: "Amount",
	},
	selectAmount: {
		id: "subscriptionCancel.modal.selectAmount",
		defaultMessage: "Select amount",
	},
	decreaseAmount: {
		id: "subscriptionCancel.modal.decreaseAmount",
		defaultMessage: "Decrease amount",
	},
	increaseAmount: {
		id: "subscriptionCancel.modal.increaseAmount",
		defaultMessage: "Increase amount",
	},
	activeEnrollments: {
		id: "subscriptionCancel.modal.activeEnrollments",
		defaultMessage: "You have {amount} active {amount, plural, one {enrollment} other {enrollments}} using this subscription.",
	},
} );

const ActionButtonsContainer = styled( ButtonsContainer )`
	margin-top: var(--gap);
`;

const Label = styled( StyledLabel )`
	margin-top: 5px;
`;

const NumberButton = styled( IconButton )`
	background-color: ${ colors.$color_grey_light };
	background-size: 15px;
	border-color: ${ colors.$color_red };
	box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.1);
	color: ${ colors.$color_white };
	filter: drop-shadow(0 2px 2px rgb(0, 0, 0, 0.2) );
	margin-left: 10px;
	max-height: 30px;
	max-width: 35px;
	padding-left: 30px !important;
	padding-right: 0;
`;

const NumberField = styled( InputField )`
	width: 35px;
	height: 30px;
	box-shadow: inset 0 0 3px 0px rgba(0,0,0,0.3);
	border: 1px solid ${ colors.$color_grey_light };
	font-size: 1em;
	font-weight: bold;
	margin-left: 8px;
	padding: 0 0 0 12px;
	-moz-appearance: textfield;
	appearance: textfield;

	::-webkit-inner-spin-button,
	::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

`;

const CancelSubscriptionContainer = styled.div`
	max-width: 600px;
	margin: auto;
	font-size: 1em;
`;

/**
 * Returns the rendered Subscription Edit Modal component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {JSX.Element} The rendered SubscriptionEditModal component.
 */
const SubscriptionEditModal = ( props ) => {
	const [ amountToCancel, setAmountToCancel ] = useState( 0 );

	useEffect( props.onClose, [ props.success ] );

	/**
	 * Cancels the subscription given in the props.
	 *
	 * @returns {void}
	 */
	const cancelSubscription = useCallback( () => {
		if ( ! amountToCancel > 0 ) {
			return;
		}

		props.cancelSubscription( props.subscription.id, amountToCancel );
	}, [ props.cancelSubscription, amountToCancel, props.subscription.id ] );

	/**
	 * Validates the input against the maximum number of subscriptions that is possible to cancel
	 * and sets it in the state.
	 *
	 * @param {Object} event The change event
	 *
	 * @returns {void}
	 */
	const changeItemsToCancel = useCallback( ( event ) => {
		event.preventDefault();
		const value = toSafeInteger( event.target.value );
		// Makes sure the value is between 0 and the maximum number of subscriptions
		setAmountToCancel( Math.min( Math.max( value, 0 ), props.numberOfCurrentSubscriptions ) );
	}, [ setAmountToCancel, props.numberOfCurrentSubscriptions ] );

	/**
	 * Increase the number of items to cancel unless it is already at the maximum possible.
	 *
	 * @returns {void}
	 */
	const incrementItemsToCancel = useCallback( () => {
		if ( amountToCancel < props.numberOfCurrentSubscriptions ) {
			setAmountToCancel( amountToCancel + 1 );
		}
	}, [ amountToCancel, setAmountToCancel, props.numberOfCurrentSubscriptions ] );

	/**
	 * Decrease the number of items to cancel unless it is already at one.
	 *
	 * @returns {void}
	 */
	const decrementItemsToCancel = useCallback( () => {
		if ( amountToCancel > 0 ) {
			setAmountToCancel( amountToCancel - 1 );
		}
	}, [ amountToCancel, setAmountToCancel ] );

	/**
	 * Renders the plus and minus buttons, and the input field, if the numberOfCurrentSubscriptions is defined.
	 *
	 * @returns {JSX.Element} The div element containing the buttons and the input field
	 */
	const displayNumberButtons = () => {
		if ( props.numberOfCurrentSubscriptions ) {
			const ResponsiveNumberButton = makeResponsiveIconButton( NumberButton );
			return <div>
				<Label htmlFor="input-number">
					<FormattedMessage
						id={ messages.selectAmount.id }
						defaultMessage={ messages.selectAmount.defaultMessage }
					/>
				</Label>
				<ResponsiveNumberButton
					id="decrease"
					onClick={ decrementItemsToCancel }
					iconSource={ minus }
					aria-label={ messages.decreaseAmount.defaultMessage }
				/>
				<NumberField
					id="input-number"
					value={ amountToCancel }
					onChange={ changeItemsToCancel }
					type="number"
					aria-label={ messages.amount.defaultMessage }
				/>
				<ResponsiveNumberButton
					id="increase"
					onClick={ incrementItemsToCancel }
					iconSource={ plus }
					aria-label={ messages.increaseAmount.defaultMessage }
				/>
			</div>;
		}
	};


	const confirmButtonText        = props.loading ? messages.loading : messages.confirm;
	const WideLargeButton          = makeButtonFullWidth( LargeButton );
	const WideSecondaryButton      = makeButtonFullWidth( LargeSecondaryButton );
	const isEnabled                = props.loading === false && amountToCancel > 0;
	const { nextPayment, endDate } = props.subscription;
	const paymentTermDate          = <FormattedDate
		value={ nextPayment ? nextPayment : endDate }
		year="numeric"
		month="long"
		day="2-digit"
	/>;

	return (
		<MyYoastModal
			isOpen={ props.isOpen }
			onClose={ props.onClose }
			modalAriaLabel={ messages.ariaLabel }
		>
			<CancelSubscriptionContainer>
				<ModalHeading>
					<FormattedMessage { ...messages.header } />
				</ModalHeading>
				<p>
					<FormattedMessage
						{ ...messages.body }
						values={ { term: <strong>{ paymentTermDate }</strong> } }
					/>
				</p>
				<p>
					<strong>
						<FormattedMessage
							{ ...messages.activeSites }
							values={ { amount: props.amountOfActiveSites } }
						/>
					</strong>
				</p>
				<p>
					<strong>
						<FormattedMessage
							{ ...messages.activeEnrollments }
							values={ { amount: props.numberOfActiveEnrollments } }
						/>
					</strong>
				</p>
				<p>
					<FormattedMessage
						{ ...messages.numberOfCurrentSubscriptions }
						values={ { amount: props.numberOfCurrentSubscriptions } }
					/>
				</p>
				{ displayNumberButtons() }
				<ErrorDisplay error={ props.error } />
				<ActionButtonsContainer>
					<WideSecondaryButton onClick={ props.onClose }>
						<FormattedMessage { ...messages.cancel } />
					</WideSecondaryButton>
					<WideLargeButton
						type="submit"
						onClick={ cancelSubscription }
						enabledStyle={ isEnabled }
						aria-disabled={ ! isEnabled }
					>
						<FormattedMessage { ...confirmButtonText } />
					</WideLargeButton>
				</ActionButtonsContainer>
			</CancelSubscriptionContainer>
		</MyYoastModal>
	);
};

SubscriptionEditModal.propTypes = {
	intl: intlShape.isRequired,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	cancelSubscription: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	success: PropTypes.bool.isRequired,
	error: ErrorPropTypeShape,
	amountOfActiveSites: PropTypes.number.isRequired,
	numberOfCurrentSubscriptions: PropTypes.number.isRequired,
	numberOfActiveEnrollments: PropTypes.number.isRequired,
	subscription: PropTypes.object.isRequired,
};

SubscriptionEditModal.defaultProps = {
	isOpen: false,
	error: null,
};

export default injectIntl( SubscriptionEditModal );
