import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import icon from "../../icons/checkout-success.svg";
import logo from "../../images/my-yoast-academy-logo.svg";
import StyledHeading from "./StyledHeading";
import RenderParagraph from "./RenderParagraph";
import styled from "styled-components";

const messages = defineMessages( {
	header: {
		id: "login.postCheckoutAside.header",
		defaultMessage: "Almost there!",
	},
	message: {
		id: "login.postCheckoutAside.message",
		defaultMessage: "Thank you for your purchase! " +
			"It appears you already have a MyYoast account. That’s great! " +
			"Please log in to start using your new products.",
	},
} );

const Icon = styled.img`
	margin-top: 20px;
	width: 200px;
`;

const Logos = styled.img`
	margin-top: 40px;
	width: 360px;
`;

/**
 * Display's the left side with the post-checkout branding.
 *
 * @returns {ReactElement} The component that contains the message
 */
function PostCheckoutLoginAside() {
	return (
		<div>
			<Logos src={ logo } alt="MyYoast - Yoast Academy" />
			<Icon src={ icon } alt="" />
			<StyledHeading header={ messages.header } />
			<RenderParagraph message={ messages.message } />
		</div>
	);
}

export default injectIntl( PostCheckoutLoginAside );
