import PropTypes from "prop-types";
import React, { Fragment } from "react";
import MediaQuery from "react-responsive";
import { defineMessages, FormattedDate, FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import { orderBy } from "lodash/collection";

import { ColumnFixedWidth, ColumnIcon, ColumnMinWidth, ColumnPrimary, Row } from "../../Tables";
import { LargeButton } from "../../Button";
import formatAmount from "../../../functions/currency";
import defaults from "../../../config/defaults.json";
import { capitalizeFirstLetter } from "shared-frontend/functions/stringHelpers";
import SiteIcon from "../../SiteIcon";
import { SubscriptionDetailsText } from "./SubscriptionDetailsText";
import { generateRenewalUrl } from "../../../functions/generateRenewalUrl";
import { getShopUrl } from "shared-frontend/functions/products";
import { createCartUrlForSubscription } from "shared-frontend/functions/cart";
import Link from "../../Link";
import UpDownArrow from "../../../icons/UpDownArrow";

const messages = defineMessages( {
	individualSubscriptions: {
		id: "subscriptions.overview.individualSubscriptions",
		defaultMessage: "Individual subscriptions",
	},
	subscriptions: {
		id: "subscriptions.overview.subscriptions",
		defaultMessage: "Subscriptions",
	},
	gotoProvisioner: {
		id: "subscriptions.overview.gotoProvisioner",
		defaultMessage: "{ name }",
	},
	provisioned: {
		id: "subscriptions.overview.provisioned",
		defaultMessage: "Partner subscriptions",
	},
	boughtVia: {
		id: "subscriptions.overview.provisionedBy",
		defaultMessage: "Bought via",
	},
	status: {
		id: "subscriptions.overview.status",
		defaultMessage: "Status",
	},
	level: {
		id: "subscriptions.overview.level",
		defaultMessage: "Level",
	},
	used: {
		id: "subscriptions.overview.used",
		defaultMessage: "Used on",
	},
	billingType: {
		id: "subscriptions.overview.billingType",
		defaultMessage: "Billing type",
	},
	nextPaymentOn: {
		id: "subscriptions.overview.nextPaymentOn",
		defaultMessage: "Next billing",
	},
	endsDate: {
		id: "ends.date",
		defaultMessage: "Ends on { endDate }",
	},
	billingAmount: {
		id: "subscriptions.overview.billingAmount",
		defaultMessage: "Amount",
	},
	manage: {
		id: "subscriptions.overview.manage",
		defaultMessage: "Manage",
	},
	needsAttention: {
		id: "subscriptions.overview.needsAttention",
		defaultMessage: "Needs attention",
	},
	details: {
		id: "subscriptions.overview.details",
		defaultMessage: "Details",
	},
	manualRenewMessage: {
		id: "subscriptions.overview.manualRenewMessage",
		defaultMessage: "Renew now",
	},
	RenewSubscriptionPlanMessage: {
		id: "subscriptions.overview.RenewSubscriptionPlanMessage",
		defaultMessage: "Renew now",
	},
	buyNewMessage: {
		id: "subscriptions.overview.buyNewMessage",
		defaultMessage: "Buy subscription",
	},
	seeDetails: {
		id: "subscriptions.overview.seeDetails",
		defaultMessage: "See details",
	},
	paymentFailed: {
		id: "subscriptions.overview.paymentFailed",
		defaultMessage: "Payment failed",
	},
	amountOfAttentionNeeded: {
		id: "subscriptions.overview.amountOfAttentionNeeded",
		defaultMessage: "{amount} {amount, plural, one {action} other {actions}} needed",
	},
	active: {
		id: "subscriptions.overview.active",
		defaultMessage: "Active",
	},
	inactive: {
		id: "subscriptions.overview.inactive",
		defaultMessage: "Inactive",
	},
	inactiveTable: {
		id: "subscriptions.table.inactive",
		defaultMessage: "Inactive subscriptions",
	},
	suspended: {
		id: "subscriptions.overview.suspended",
		defaultMessage: "Suspended",
	},
	pendingCancellation: {
		id: "subscriptions.overview.pendingCancellation",
		defaultMessage: "Active until end of term",
	},
	expiresSoon: {
		id: "subscriptions.overview.expiresSoon",
		defaultMessage: "Expires soon",
	},
	expired: {
		id: "subscriptions.overview.expired",
		defaultMessage: "Expired",
	},
	detailsButton: {
		id: "subscriptions.overview.detailsButton",
		defaultMessage: "More details",
	},
	exVAT: {
		id: "subscriptions.overview.exVAT",
		defaultMessage: "(ex VAT)",
	},
} );

const StyledSpace = styled.div`
	min-width: ${ props => props.tablet ? 48 : 150 }px;
`;

StyledSpace.propTypes = {
	tablet: PropTypes.bool,
};


const StyledColumnMinWidth = styled( ColumnMinWidth )`

	@media screen and ( max-width: ${ defaults.css.breakpoint.tablet }px ) {
		max-width: ${ props => props.maxWidth && props.maxWidth };
		min-width: ${ props => props.minWidth === "198px" && "158px" };
		padding-left: ${ props => props.paddingLeft ? props.paddingLeft : "0px" };
	}

	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
		max-width: none;
	}
`;

StyledColumnMinWidth.propTypes = {
	maxWidth: PropTypes.string,
	minWidth: PropTypes.string,
	paddingLeft: PropTypes.string,
};

const StyledStatus = styled.span`
	color: ${ props => {
		if ( props.needsAttention || props.isInactive ) {
			return "var(--text-color-error)";
		}

		return "var(--text-color-dark)";
	} };
	font-weight: ${ props => ( props.needsAttention || props.isInactive ) ? "bold" : "inherit" };
`;

StyledStatus.propTypes = {
	needsAttention: PropTypes.bool.isRequired,
	isInactive: PropTypes.bool.isRequired,
};

StyledStatus.defaultProps = {
	needsAttention: false,
	isInactive: false,
};

const Detail = styled.div`
	font-size: 14px;
	padding-left: ${ props => props.isInExpanded ? "32px" : "none" };

	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
		padding-left: 0;
	}
`;

const Icon = styled( ColumnIcon )`
	height: 32px;
	width: 32px;
`;

const CollapseButtonSpacer = styled.div`
	@media screen and ( min-width: ${ defaults.css.breakpoint.tablet + 1 }px ) {
		width: 152px;
		display: flex;
		justify-content: flex-end;
	}
`;

const ToggleButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background: transparent;
	cursor: pointer;
	width: 42px;
	height: 42px;
	border-radius: var(--border-radius);
	padding: 0;

	&.rotate-90deg {
		transform: rotate(-90deg);
	}
`;

const Separator = styled.div`
	border-bottom: 1px solid var(--border-color);
`;

/**
 * Creates a subscription component
 *
 * @param {object} props Properties of the component.
 * @returns {ReactElement} Subscription component.
 * @constructor
 */
class SubscriptionRow extends React.Component {
	/**
	 * Initializes the class with the specified props.
	 *
	 * @param {Object} props The props to be passed to the class that was extended from.
	 *
	 * @returns {void}
	 */
	constructor( props ) {
		super( props );

		this.state = {
			isOpen: false,
		};

		this.getManageButtons = this.getManageButtons.bind( this );
		this.toggleOpen = this.toggleOpen.bind( this );
	}

	/**
	 * Whether the subscription is active or not.
	 *
	 * @param   {string}  status         The status of the subscription.
	 * @param   {boolean} includePending Whether pending-cancel is to be considered active.
	 * @returns {boolean}                Whether the subscription is active.
	 */
	isActive( status, includePending = true ) {
		const activeStatus = [ "active" ];
		if ( includePending ) {
			activeStatus.push( "pending-cancel" );
		}
		return activeStatus.includes( status );
	}

	/**
	 * Toggles the open state of the collapsible row.
	 *
	 * @returns {void}
	 */
	toggleOpen() {
		const isOpen = this.state.isOpen;
		this.setState( { isOpen: ! isOpen } );
	}

	/**
	 * Set up manage button area depending on subscription status and screen width.
	 *
	 * @param {boolean}  showManageButton Whether to show the manage button.
	 * @param {function} onManage         The function that should be called on clicking the manage button.
	 *
	 * @returns {ReactElement}            The appropriate manage buttons or empty area.
	 */
	getManageButtons( showManageButton, onManage ) {
		const tabletView = defaults.css.breakpoint.tablet;

		if ( ! showManageButton ) {
			return <Fragment>
				<MediaQuery query={ `(min-width: ${ tabletView + 1 }px)` }>
					<StyledSpace tablet={ false } />
				</MediaQuery>
				<MediaQuery query={ `(max-width: ${ tabletView }px)` }>
					<StyledSpace tablet={ true } />
				</MediaQuery>
			</Fragment>;
		}

		return <Fragment>
			<MediaQuery query={ `(min-width: ${ tabletView + 1 }px)` }>
				<LargeButton className="manage_subscription_btn" onClick={ onManage }>
					{ this.props.intl.formatMessage( messages.manage ) }
				</LargeButton>
			</MediaQuery>

			<MediaQuery query={ `(max-width: ${ tabletView }px)` }>
				<ToggleButton
					className="rotate-90deg manage_subscription_btn"
					onClick={ onManage }
					aria-label={ this.props.intl.formatMessage( messages.manage ) }
				>
					<UpDownArrow isOpen={ false } />
				</ToggleButton>
			</MediaQuery>
		</Fragment>;
	}

	/**
	 * Adds chevron button on mobile view.
	 *
	 * @param {Object} subscription The subscription for which to get the status.
	 *
	 *  @returns {ReactElement} A Fragment with the next billing information or the endDate of the subscription.
	 */
	getActionButtonMobile( subscription ) {
		const mobileView = defaults.css.breakpoint.mobile;

		if ( subscription.status === "on-hold" ) {
			return <Fragment>
				<MediaQuery query={ `(max-width: ${ mobileView }px)` }>
					<ToggleButton
						className="rotate-90deg"
						onClick={ () => this.props.showDetailsModal( subscription ) }
						aria-label={ this.props.intl.formatMessage( messages.manage ) }
					>
						<UpDownArrow isOpen={ false } />
					</ToggleButton>
				</MediaQuery>
			</Fragment>;
		}
	}

	/**
	 * Organizes the billing and endDate information under Next Payment.
	 *
	 * @param {string} status The status of the subscription.
	 * @param {ReactElement} endDate The end date of the subscription.
	 * @param {string} nextPayment The next billing.
	 * @param {string} amount The amount of the next billing.
	 *
	 * @returns {ReactElement} A Fragment with the next billing information or the endDate of the subscription.
	 */
	getNextBilling( status, endDate, nextPayment, amount ) {
		if ( status === "pending-cancel" ) {
			return <Fragment>
				<FormattedMessage { ...messages.endsDate } values={ { endDate: endDate } } />
			</Fragment>;
		}

		if ( nextPayment && amount ) {
			return <Fragment>
				{ nextPayment }
			</Fragment>;
		}

		return null;
	}

	/**
	 * Retrieve/compute data that applies to the top (summary) row for this group of subscriptions.
	 *
	 * @param {Array}    subscriptionsArray An array of subscriptions that were grouped.
	 * @returns {Object}                    The data for the top row of the subscriptions.
	 */
	retrieveOverallSubscriptionData( subscriptionsArray ) {
		const donorSubscription = subscriptionsArray[ 0 ];

		const activeSubscriptions = subscriptionsArray.filter(
			subscription => [ "active", "pending-cancel" ].includes( subscription.status ),
		);
		const needsAttentionArray = this.props.needsAttention ? subscriptionsArray : [];
		const needsAttention = needsAttentionArray.length > 0;

		/**
		 * Function to count the total number of a given field.
		 * @param {Array} inputArray An array containing all the sub-subscriptions.
		 * @param {string} field Which selector should be used.
		 * @returns {number} The total number of a given field.
		 */
		const countTotals = ( inputArray, field ) => {
			return inputArray.reduce( ( total, current ) => {
				return total + current[ field ];
			}, 0 );
		};

		const isActive = activeSubscriptions.length > 0;
		const totalLimit = countTotals( activeSubscriptions, "limit" );
		const totalUsed = countTotals( activeSubscriptions, "used" );

		/**
		 * Maps a number of props to a status.
		 * @param {boolean} isSubscriptionActive Whether the subscription is active.
		 * @param {boolean} subscriptionNeedsAttention Whether the subscription needs attention.
		 * @param {number} nrOfActions The number of subscriptions that need attention.
		 * @returns {FormattedMessage} The status to show.
		 */
		const donorSubscriptionstatus = ( isSubscriptionActive, subscriptionNeedsAttention, nrOfActions ) => {
			if ( subscriptionNeedsAttention ) {
				return <FormattedMessage { ...messages.amountOfAttentionNeeded } values={ { amount: nrOfActions } } />;
			}
			if ( isSubscriptionActive ) {
				return <FormattedMessage { ...messages.active } />;
			}
			return <FormattedMessage { ...messages.inactive } />;
		};

		return {
			name: donorSubscription.name,
			icon: donorSubscription.icon,
			hasSites: donorSubscription.hasSites,
			status: donorSubscriptionstatus( isActive, needsAttention, needsAttentionArray.length ),
			used: totalUsed,
			limit: totalLimit,
			needsAttention: needsAttention,
		};
	}

	/**
	 * Gets a status based on the subscription.status and props.
	 * @param {Object} subscription The subscription for which to get the status.
	 * @returns {(FormattedMessage|String)} The status for the subscription.
	 */
	getStatus( subscription ) {
		if ( ! this.props.needsAttention ) {
			if ( subscription.status === "pending-cancel" ) {
				return <FormattedMessage { ...messages.pendingCancellation } />;
			}

			return capitalizeFirstLetter( subscription.status );
		}
		if ( subscription.status === "on-hold" ) {
			return <FormattedMessage { ...messages.suspended } />;
		}
		if ( subscription.status === "expired" ) {
			return <FormattedMessage { ...messages.expired } />;
		}
		return <FormattedMessage { ...messages.expiresSoon } />;
	}

	/**
	 * The first column has different content depending on screen size, and whether it is in in the collapsible or not.
	 *
	 * @param   {Object}         subscription           The subscription in this row.
	 * @param   {boolean}        isInExpanded           Whether the row is in the expandable part of a collapsible.
	 * @param   {string}         nextPaymentInformation Information about the first upcoming payment.
	 * @returns {ReactElement}                          The contents of the first column (for this row).
	 */
	getPrimaryColumnContent( subscription, isInExpanded = false, nextPaymentInformation = "" ) {
		const status = this.getStatusDescription( subscription, isInExpanded );

		return (
			<Fragment>
				{ isInExpanded && this.isActive( subscription.status, false ) &&
				<MediaQuery query={ `(max-width: ${ defaults.css.breakpoint.mobile }px)` }>
					<strong>
						<FormattedMessage
							id={ "mobile-expandable-next-billing" }
							defaultMessage={ "Next billing: " }
						/>
					</strong>
					{ nextPaymentInformation }
					{ subscription.billingType }
				</MediaQuery>
				}
				{ ! isInExpanded && subscription.name }
				<Detail isInExpanded={ isInExpanded }>
					<StyledStatus
						status={ subscription.status }
						needsAttention={ this.props.needsAttention }
						isInactive={ this.props.isInactive }
					>
						{ status }
					</StyledStatus>
					{ subscription.subscriptionNumber && ` - ${ subscription.subscriptionNumber }` }
				</Detail>
			</Fragment>
		);
	}

	/**
	 * Get provisioner dependent status.
	 *
	 * @param   {Object}         subscription           The subscription in this row.
	 * @param   {boolean}        isInExpanded           Whether the row is in the expandable part of a collapsible.
	 * @returns {string}                                The status.
	 */
	getStatusDescription( subscription, isInExpanded ) {
		return subscription.name.toLowerCase().includes( "shopify" ) && ! isInExpanded && this.props.subscriptionsArray.length !== 1
			? "Managed by Shopify"
			: this.getStatus( subscription );
	}

	/**
	 * Gets the details about what action is needed for the subscription.
	 * @param   {SubscriptionRow} subscription    The subscription for which this function gets the details.
	 * @returns {ReactElement} The details section.
	 */
	getDetails( subscription ) {
		const goToYoastSubscriptionsPage = getShopUrl() + "/subscriptions";
		const subscriptionIsMonthly = subscription.name.includes( "(monthly)" );
		const subscriptionIsAnnually = subscription.name.includes( "(annually)" );
		let linkText, redMessage;

		if ( subscription.status === "on-hold" ) {
			linkText = this.props.intl.formatMessage( messages.seeDetails );
			redMessage = this.props.intl.formatMessage( messages.paymentFailed );

			return (
				<SubscriptionDetailsText
					linkText={ linkText }
					redMessage={ redMessage }
					onClickHandler={ () => this.props.showDetailsModal( subscription ) }
				/>
			);
		}

		const date = subscription.hasEndDate ? subscription.endDate : subscription.nextPayment;
		const OneMonthAgo = new Date();
		OneMonthAgo.setMonth( OneMonthAgo.getMonth() - 1 );
		const expiredMoreThanOneMonthAgo = new Date( date ).getTime() < OneMonthAgo.getTime();
		const statuses = [ "expired", "cancelled", "refunded" ];
		const expired = statuses.includes( subscription.status ) ? "Expired" : "Expires";

		// Subscriptions that are not renewable anymore.
		const isExpiredAndIsNotRenewable = (
			( expiredMoreThanOneMonthAgo && subscription.status === "expired" ) ||
			subscription.status === "cancelled" ||
			subscription.status === "refunded"
		);

		redMessage = <FormattedMessage
			id="details.redMessageText"
			defaultMessage="{ expired } { date }"
			values={ {
				expired: expired,
				date: date.toLocaleDateString( "en-US", { year: "numeric", month: "short", day: "numeric" } ),
			} }
		/>;

		if ( isExpiredAndIsNotRenewable ) {
			return (
				<SubscriptionDetailsText
					linkText={ this.props.intl.formatMessage( messages.buyNewMessage ) }
					redMessage={ redMessage }
					linkTo={ subscriptionIsMonthly ? goToYoastSubscriptionsPage : createCartUrlForSubscription( subscription ) }
					className={ "buy_subscription_button_subs_page" }
				/>
			);
		}

		if ( subscriptionIsMonthly || subscriptionIsAnnually ) {
			if ( ! expiredMoreThanOneMonthAgo ) {
				return <SubscriptionDetailsText
					linkText={ this.props.intl.formatMessage( messages.RenewSubscriptionPlanMessage ) }
					redMessage={ redMessage }
					linkTo={ generateRenewalUrl( subscription ) }
					className={ "renew_now_button_subs_page" }
				/>;
			}
		}

		return (
			<SubscriptionDetailsText
				linkText={ this.props.intl.formatMessage( messages.manualRenewMessage ) }
				redMessage={ redMessage }
				linkTo={ generateRenewalUrl( subscription ) }
				className={ "renew_now_button_subs_page" }
			/>
		);
	}

	/**
	 * For the most part, the single and collapsible rows are not different. Here we set up the common parts.
	 * With some adjustments to the content based on context, that is.
	 *
	 * @param   {Object}       subscription           The subscription in this row.
	 * @param   {boolean}      isInExpanded           Whether the row is in the expanded part of the collapsible.
	 * @param   {ReactElement} nextPaymentInformation Information about the next payment.
	 * @param   {string}       billingType            Whether renewal is automatic or manual.
	 * @param   {ReactElement} details                The details concerning the subscription when action is needed.
	 * @returns {ReactElement}                        The common part of each row.
	 */
	commonRowTemplate(
		subscription,
		isInExpanded = false,
		nextPaymentInformation = null,
		billingType = "",
		details = null,
	) {
		/**
		 * Function to retrieve the right primary column header label based on two parameters
		 *
		 * @param {boolean} isGrouped Whether the subscription is grouped
		 * @param {boolean} needsAttention Whether the subscription needs attention
		 * @param {boolean} isProvisioned Whether the subscription is provisioned
		 * @param {boolean} isInactive Whether the subscription is inactive
		 *
		 * @returns {string} The message that is used as the headerLabel for the primary Column
		 */
		const primaryColumnHeaderLabel = (
			isGrouped,
			needsAttention,
			isProvisioned,
			isInactive,
		) => {
			if ( needsAttention ) {
				return messages.needsAttention;
			} else if ( isGrouped ) {
				return messages.subscriptions;
			} else if ( isProvisioned ) {
				return messages.provisioned;
			} else if ( isInactive ) {
				return messages.inactiveTable;
			}
			return messages.individualSubscriptions;
		};

		const subscriptionPayload = isInExpanded ? subscription : this.props.subscriptionsArray[ 0 ];

		return (
			<Fragment>
				<Icon>
					{
						! isInExpanded &&
						<SiteIcon src={ subscription.icon } alt="" />
					}
				</Icon>
				<ColumnPrimary
					key={ subscription.id + "-primary" }
					ellipsis={ false }
					headerLabel={ this.props.intl.formatMessage(
						primaryColumnHeaderLabel( this.props.isGrouped, this.props.needsAttention, this.props.isProvisioned, this.props.isInactive ),
					) }
				>
					{ this.getPrimaryColumnContent( subscription, isInExpanded, nextPaymentInformation ) }
				</ColumnPrimary>
				{ this.props.isProvisioned
					? this.getProvisionedColums( subscriptionPayload, isInExpanded )
					: this.getRegularColumns( subscription, billingType )
				}
				<StyledColumnMinWidth
					key={ subscription.id + "-details" }
					ellipsis={ false }
					hideOnMobile={ true }
					headerLabel={ this.getHeaderLabel() }
					minWidth="198px"
				>
					{ this.getColumnInformation( details, nextPaymentInformation ) }
				</StyledColumnMinWidth>
			</Fragment>
		);
	}
	/**
	 * Depending on the props it returns the right header label.
	 *
	 * @returns {string} A header label.
	 */
	getHeaderLabel() {
		if ( this.props.isProvisioned ) {
			return "";
		}
		if ( this.props.needsAttention || this.props.isInactive ) {
			return this.props.intl.formatMessage( messages.details );
		}

		return this.props.intl.formatMessage( messages.nextPaymentOn );
	}

	/**
	 * Returns the information to show in the column..
	 *
	 * @param {Object} details                The details.
	 * @param {Object} nextPaymentInformation Information about the next payment.
	 *
	 * @returns {string} The column information.
	 */
	 getColumnInformation( details, nextPaymentInformation ) {
		if ( this.props.isProvisioned ) {
			return "";
		}
		if ( this.props.needsAttention || this.props.isInactive ) {
			return details;
		}

		return nextPaymentInformation;
	}

	/**
	 * Creates the regular subscription columns.
	 *
	 * @param {Subscription} subscription The subscription to use.
	 * @param {string} billingType The billing type.
	 *
	 * @returns {ReactElement} The columns.
	 */
	getRegularColumns( subscription, billingType ) {
		return (
			<Fragment>
				<StyledColumnMinWidth
					key={ subscription.id + "-sites" }
					ellipsis={ true }
					hideOnMobile={ true }
					headerLabel={ this.props.intl.formatMessage( messages.used ) }
					maxWidth="120px"
					minWidth="102px"
					paddingLeft="inherit"
				>
					{ subscription.hasSites && this.isActive( subscription.status )
						? subscription.used + "/" + subscription.limit + " sites"
						: ""
					}
				</StyledColumnMinWidth>
				<StyledColumnMinWidth
					key={ subscription.id + "-billingtype" }
					ellipsis={ true }
					hideOnMobile={ true }
					headerLabel={ this.props.intl.formatMessage( messages.billingType ) }
					maxWidth="140px"
					minWidth="120px"
				>
					{ billingType }
				</StyledColumnMinWidth>
			</Fragment>
		);
	}

	/**
	 * Creates the provisioned subscripition columns.
	 *
	 * @param {Subscription} subscription The subscription to use.
	 * @param {bool} isInExpanded         Whether the row is in the expanded part of the collapsible.
	 *
	 * @returns {ReactElement} The columns.
	 */
	getProvisionedColums( subscription, isInExpanded = false ) {
		let provisionerLink = "";
		const subscriptionExists = this.props.provisionerData[ subscription.provisionerId ];

		if ( ( subscriptionExists && isInExpanded ) || this.props.subscriptionsArray.length === 1 ) {
			provisionerLink = this.getProvisionerLink( subscription );
		}

		return (
			<Fragment>
				<StyledColumnMinWidth
					key={ subscription.id + "-provisioner" }
					ellipsis={ true }
					hideOnMobile={ true }
					headerLabel={ this.props.intl.formatMessage( messages.boughtVia ) }
					maxWidth="120px"
					minWidth="102px"
					paddingLeft="inherit"
				>
					{ provisionerLink }
				</StyledColumnMinWidth>
				<StyledColumnMinWidth
					key={ subscription.id + "-empty" }
					ellipsis={ true }
					hideOnMobile={ true }
					headerLabel=""
					maxWidth="140px"
					minWidth="120px"
				/>
			</Fragment>
		);
	}

	/**
	 * Creates a link to the provisioner account page.
	 *
	 * @param {Subscription} subscription The subscription to get the data from.
	 *
	 * @returns {ReactElement} The link to the provisioner account page.
	 */
	getProvisionerLink( subscription ) {
		if ( ! this.props.provisionerData[ subscription.provisionerId ] ) {
			return null;
		}

		const provisionerData = this.props.provisionerData[ subscription.provisionerId ];

		return <Link to={ provisionerData.customerPlatformUrl } linkTarget="_blank">
			{ this.props.intl.formatMessage( messages.gotoProvisioner, { name: provisionerData.displayName } ) }
		</Link>;
	}

	/**
	 * Creates the collapsible row element.
	 *
	 * @returns {ReactElement} The collapsible row element.
	 */
	makeCollapsibleRow() {
		const subscription = this.retrieveOverallSubscriptionData( this.props.subscriptionsArray );

		let previousBackgroundColor = this.props.background;

		/**
		 * Sets the expandable rows background color.
		 *
		 * In the expandable part of the row, background colors should be slightly
		 * darker versions of the original zebra colors.
		 *
		 * @returns {string} The background color to apply.
		 */
		const determineNextBackgroundColor = () => {
			const offwhite = "var(--bg-color-offwhite)";
			const white = "var(--bg-color-white)";

			return previousBackgroundColor === offwhite ? white : offwhite;
		};

		/*
		 * Expanded table is simply using the makeSingleRow function,
		 * while changing the backgroundColor and passing isInExpanded as true.
		 * If subscriptions have an "endDate", that is used for ordering in descending order.
		 * If not, "nextPayment" is user for ordering in descending order.
		 */
		const expandedRows =
			<Fragment>
				{
					orderBy( this.props.subscriptionsArray, [ "endDate", "nextPayment" ], [ "desc", "desc" ] )
						.map( ( subscriptionEntry ) => {
							const nextBackgroundColor = determineNextBackgroundColor();
							previousBackgroundColor = nextBackgroundColor;
							return this.makeSingleRow( subscriptionEntry, nextBackgroundColor, true );
						} )
				}
				<Separator />
			</Fragment>;

		return (
			<Fragment>
				<Row
					key={ subscription.id }
					onClick={ this.toggleOpen }
					background={ this.props.background }
				>
					{ this.commonRowTemplate( subscription ) }
					<ColumnFixedWidth
						paddingLeft="0px"
					>
						<CollapseButtonSpacer>
							<ToggleButton
								className={ this.state.isOpen && "is-open" }
								onClick={ this.toggleOpen }
								aria-expanded={ this.state.isOpen }
								aria-label={ this.props.intl.formatMessage( messages.detailsButton ) }
							>
								<UpDownArrow isOpen={ this.state.isOpen } />
							</ToggleButton>
						</CollapseButtonSpacer>
					</ColumnFixedWidth>
				</Row>
				{
					this.state.isOpen && expandedRows
				}
			</Fragment>
		);
	}

	/**
	 * Creates a single, non-expandable row with subscription information and a manage button.
	 *
	 * @param {Object}        subscription The subscription for this row.
	 * @param {string}        background   The background color for this row.
	 * @param {boolean}       isInExpanded Whether this row is in the expandable part of a collapsible row.
	 *
	 * @returns {ReactElement}              A single subscription row.
	 */
	makeSingleRow( subscription, background = this.props.background, isInExpanded = false ) {
		const status = subscription.status;
		const showManageButton = subscription.showManageButton;

		let nextPayment = null;
		let endDate = null;
		let amount = null;
		let billingType = null;
		let details = null;

		if ( status === "pending-cancel" ) {
			endDate = <FormattedDate
				value={ subscription.hasEndDate && subscription.endDate }
				year="numeric"
				month="long"
				day="numeric"
			/>;
		}

		if ( subscription.status === "active" && ( subscription.hasNextPayment || subscription.hasEndDate ) ) {
			nextPayment = <FormattedDate
				value={ subscription.hasNextPayment ? subscription.nextPayment : subscription.endDate }
				year="numeric"
				month="long"
				day="numeric"
			/>;
			amount = <FormattedNumber
				value={ formatAmount( subscription.billingAmount ) }
				currency={ subscription.billingCurrency }
				style="currency"
			/>;
			billingType = subscription.requiresManualRenewal ? "Manual renewal" : "Automatic renewal";
		}

		if ( this.props.needsAttention || this.props.isInactive ) {
			details = this.getDetails( subscription );
		}

		const nextPaymentInformation = this.getNextBilling( status, endDate, nextPayment, amount );

		return (
			<Row key={ subscription.id } background={ background }>
				{ this.commonRowTemplate( subscription, isInExpanded, nextPaymentInformation, billingType, details ) }
				<ColumnFixedWidth
					paddingLeft="0px"
				>
					{ ( this.props.needsAttention || this.props.isInactive ) && ! this.props.isProvisioned
						? this.getActionButtonMobile( subscription )
						: this.getManageButtons( showManageButton, () => this.props.onManage( subscription.id ) )
					}
				</ColumnFixedWidth>
			</Row>
		);
	}

	/**
	 * Renders either a collapsible row or a single row, depending on the subscriptionsArray length.
	 *
	 * @returns {ReactElement} The appropriate row.
	 */
	render() {
		return this.props.subscriptionsArray.length > 1
			? this.makeCollapsibleRow()
			: this.makeSingleRow( this.props.subscriptionsArray[ 0 ] );
	}
}

SubscriptionRow.propTypes = {
	onManage: PropTypes.func.isRequired,
	subscriptionsArray: PropTypes.array.isRequired,
	provisionerData: PropTypes.object,
	isGrouped: PropTypes.bool,
	background: PropTypes.string,
	intl: intlShape.isRequired,
	needsAttention: PropTypes.bool,
	isProvisioned: PropTypes.bool,
	isInactive: PropTypes.bool,
	showDetailsModal: PropTypes.func,
};

SubscriptionRow.defaultProps = {
	provisionerData: {},
	background: "#FFF",
	showDetailsModal: null,
	isGrouped: false,
	isProvisioned: false,
	needsAttention: false,
	isInactive: false,
};

export default injectIntl( SubscriptionRow );
