import React, { Fragment } from "react";

import SubNavigation, { SubNavigationRoutes } from "./SubNavigation";
import SubscriptionsPageContainer from "../containers/SubscriptionsPage";
import ProfilePageContainer from "../containers/ProfilePage";
import OrderPage from "../containers/OrdersPage";

/**
 * Returns the rendered Account Page component.
 *
 * @returns {ReactElement} The rendered account page.
 */
const AccountPage = () => {
	const itemRoutes = [
		{
			component: SubscriptionsPageContainer,
			path: "/account/subscriptions",
			title: "Subscriptions",
			isActive: ( match, location ) => {
				if ( match ) {
					return match;
				}

				return location.pathname === "/account" || location.pathname === "/account/";
			},
		},
		{
			component: OrderPage,
			path: "/account/orders",
			title: "Orders",
		},
		{
			component: ProfilePageContainer,
			path: "/account/profile",
			title: "Profile",
		},
	];

	// This doesn't fit in the itemRoutes because SubNavigation also uses this array and we don't want an empty menuItem.
	const defaultRoute = {
		component: SubscriptionsPageContainer,
		path: "/account",
	};

	return (
		<Fragment>
			<SubNavigation itemRoutes={ itemRoutes } />
			<SubNavigationRoutes itemRoutes={ [ defaultRoute, ...itemRoutes ] } />
		</Fragment>
	);
};

export default AccountPage;
