import PropTypes from "prop-types";
import React from "react";
import { injectIntl } from "react-intl";
import forEach from "lodash/forEach";
import styled from "styled-components";

import SubscriptionRow from "./SubscriptionRow";
import { ListTable } from "../../Tables";
import { Paper } from "../../PaperStyles";

const StyledPaper = styled( Paper )`
	margin-bottom: 80px;
`;

/**
 *
 * @param {Object} props The props to use
 *
 * @returns {ReactElement} The rendered component.
 */
class Subscriptions extends React.Component {
	/**
	 * Builds the subscription rows.
	 * @returns {*[]} Subscription rows.
	 */
	outputSubscriptionRows() {
		const subscriptionRows = [];
		forEach( this.props.subscriptions, ( subscriptionsGroupedByProduct ) => {
			subscriptionRows.push( subscriptionsGroupedByProduct );
		} );

		return subscriptionRows;
	}

	/**
	 * Renders the component.
	 *
	 * @returns {JSX.Element} Component.
	 * */
	render() {
		return (
			<StyledPaper>
				<ListTable>
					{
						this.outputSubscriptionRows()
							.map( ( subscriptionsArray ) => {
								return <SubscriptionRow
									key={ subscriptionsArray[ 0 ].id }
									subscriptionsArray={ subscriptionsArray }
									provisionerData={ this.props.provisionerData }
									onManage={ this.props.onManage }
									isGrouped={ this.props.isGrouped }
									isProvisioned={ this.props.isProvisioned }
									needsAttention={ this.props.needsAttention }
									isInactive={ this.props.isInactive }
									showDetailsModal={ this.props.showDetailsModal }
								/>;
							} )
					}
				</ListTable>
			</StyledPaper>
		);
	}
}

export const SubscriptionProps = PropTypes.oneOfType( [
	PropTypes.arrayOf(
		PropTypes.shape(
			{
				id: PropTypes.string.isRequired,
				icon: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				used: PropTypes.number.isRequired,
				limit: PropTypes.number.isRequired,
				hasNextPayment: PropTypes.bool.isRequired,
				nextPayment: PropTypes.instanceOf( Date ).isRequired,
				hasEndDate: PropTypes.bool.isRequired,
				endDate: PropTypes.instanceOf( Date ).isRequired,
				billingAmount: PropTypes.number.isRequired,
				billingCurrency: PropTypes.string.isRequired,
				status: PropTypes.string.isRequired,
				renewalSecret: PropTypes.string.isRequired,
			},
		),
	),
	PropTypes.object,
] );

Subscriptions.propTypes = {
	subscriptions: SubscriptionProps,
	provisionerData: PropTypes.object,
	onManage: PropTypes.func.isRequired,
	isGrouped: PropTypes.bool,
	needsAttention: PropTypes.bool,
	isProvisioned: PropTypes.bool,
	isInactive: PropTypes.bool,
	showDetailsModal: PropTypes.func,
};

Subscriptions.defaultProps = {
	subscriptions: [],
	provisionerData: {},
	isGrouped: false,
	needsAttention: false,
	isProvisioned: false,
	isInactive: false,
	showDetailsModal: null,
};

export default injectIntl( Subscriptions );
