/* External dependencies */
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import _isUndefined from "lodash/isUndefined";
import _debounce from "lodash/debounce";
import validate from "validate.js";
import { colors } from "@yoast/style-guide";
/* Internal dependencies */
import { InputField } from "./InputField";
import inputCheck from "../icons/input-check.svg";
import inputX from "../icons/input-x.svg";
import { speak } from "@wordpress/a11y";

const INPUT_FIELD_EMPTY = "INPUT_FIELD_EMPTY";
const INPUT_FIELD_VALID = "INPUT_FIELD_VALID";
const INPUT_FIELD_INVALID = "INPUT_FIELD_INVALID";

const messages = defineMessages( {
	validationInvalidCharactersURL: {
		id: "validation.invalid.characters.url",
		defaultMessage: "Please do not enter your credentials in the URL.",
	},
} );

/**
 * Returns the border color to apply to the input field based on the passed status.
 *
 * @param {string} status One of the three possible statuses for the input field.
 *
 * @returns {string} The style to apply.
 */
const getBorderColorForInputField = ( status ) => {
	switch ( status ) {
		case INPUT_FIELD_VALID:
			return "#6EA029";
		case INPUT_FIELD_INVALID:
			return colors.$color_error;
		case INPUT_FIELD_EMPTY:
		default:
			return colors.$color_grey_medium;
	}
};

/**
 * Returns the background color to apply to the input field based on the passed status.
 *
 * @param {string} status One of the three possible statuses for the input field.
 *
 * @returns {string} The style to apply.
 */
const getBackgroundColorForInputField = ( status ) => {
	switch ( status ) {
		case INPUT_FIELD_VALID:
			return colors.$color_white;
		case INPUT_FIELD_INVALID:
			return "#F9DCDC";
		case INPUT_FIELD_EMPTY:
		default:
			return colors.$color_white;
	}
};

/**
 * Returns the box shadow to apply to the input field based on the passed status.
 *
 * @param {string} status One of the three possible statuses for the input field.
 *
 * @returns {string} The style to apply.
 */
const getBoxShadowForInputField = ( status ) => {
	switch ( status ) {
		case INPUT_FIELD_VALID:
			return "none";
		case INPUT_FIELD_INVALID:
			return "none";
		case INPUT_FIELD_EMPTY:
		default:
			return "inset 0 2px 4px 0 rgba(0,0,0,0.10)";
	}
};

/**
 * Returns the box shadow to apply to the input field based on the passed status.
 *
 * @param {string} status One of the three possible statuses for the input field.
 *
 * @returns {string} The style to apply.
 */
const getBackgroundImageForInputField = ( status ) => {
	switch ( status ) {
		case INPUT_FIELD_VALID:
			return inputCheck;
		case INPUT_FIELD_INVALID:
			return inputX;
		case INPUT_FIELD_EMPTY:
		default:
			return "";
	}
};

// Styled components.
const TextInput = styled( InputField )`
	border: 1px solid ${ props => getBorderColorForInputField( props.inputFieldStatus ) };
	background-color: ${ props => getBackgroundColorForInputField( props.inputFieldStatus ) };
	box-shadow: ${ props => getBoxShadowForInputField( props.inputFieldStatus ) };
	position: relative;

	background: url(${ props => getBackgroundImageForInputField( props.inputFieldStatus ) }) no-repeat 95% 50% /16px !important;
`;

const ErrorDisplay = styled.ul`
	color: var(--text-color-error);
	font-size: 14px;
	font-weight: 400;
	margin: 0 0 6px 0;
	padding: 0;
	list-style-type: none;
`;

const Error = styled.li`
	padding: 2px 0 2px;
	color: var(--text-color-error);
`;

/**
 * Text input field with functionality to validate on input
 * and show errors if validation fails.
 */
class ValidationInputField extends React.Component {
	/**
	 * Constructor for the ValidationInputField component.
	 *
	 * @param {Object} props The props that are passed to this component.
	 *
	 * @returns {void}
	 */
	constructor( props ) {
		super( props );

		this.state = {
			errors: [],
		};

		this.onInputChange = this.onInputChange.bind( this );
		this.getErrors = this.getErrors.bind( this );
		this.announceErrors = this.announceErrors.bind( this );
		this.getValidationErrors = this.getValidationErrors.bind( this );
		this.validate = this.validate.bind( this );
		this.validateDebounced = _debounce( this.validate, this.props.delay );
	}

	/**
	 * Called whenever the text in the input field changes.
	 *
	 * @param {*} event the event.
	 * @returns {void}
	 */
	onInputChange( event ) {
		let value = event.target.value;

		if ( this.props.trimWhiteSpace ) {
			value = value.trim();
		}

		let validating = false;

		if ( this.props.constraint ) {
			this.validateDebounced( value );
			validating = true;
		}
		this.props.onChange( value, this.state.errors, validating );
	}

	/**
	 * Validates the given value according to the constraints as set in the properties.
	 *
	 * @param {*} value the value to check.
	 * @returns {string[]} an array of error messages, will be empty if there are none.
	 */
	validate( value ) {
		const errors = this.getValidationErrors( value );

		const validating = false;
		this.props.onChange( value, errors, validating );

		this.setState( {
			errors: errors,
		}, this.announceErrors );
	}

	/**
	 * Announces any validation errors. Uses @wordpress/a11y's speak() function.
	 *
	 * @returns {void}
	 */
	announceErrors() {
		if ( ! this.state.errors.length ) {
			return;
		}

		const combinedErrorString = this.state.errors.join( "." );
		speak( combinedErrorString, "assertive" );
	}

	/**
	 * Validates the given value according to the constraints as set in the properties.
	 *
	 * @param {*} value the value to check.
	 * @returns {string[]} an array of error messages, will be empty if there are none.
	 */
	getValidationErrors( value ) {
		let errors = validate.single( value, this.props.constraint, { format: "detailed" } );

		// Account for credentials in the URL object if the constraints are for url string.
		if ( this.props.constraint && this.props.constraint.hasOwnProperty( "url" ) ) {
			try {
				const url = new URL( value );

				if ( url.username || url.password ) {
					return [ this.props.intl.formatMessage( messages.validationInvalidCharactersURL ) ];
				}
			} catch ( error ) {
				return errors;
			}
		}

		if ( _isUndefined( errors ) ) {
			errors = [];
		}

		return errors;
	}

	/**
	 * Called whenever the component will disappear from the screen.
	 *
	 * @returns {void}
	 */
	componentWillUnmount() {
		this.validateDebounced.cancel();
	}

	/**
	 * Returns an array of Error components to be displayed
	 * below the input field.
	 *
	 * @param {string[]} errors the error messages to be displayed
	 * @returns {React.Component[]} an array of Error components
	 */
	getErrors( errors ) {
		return errors.map( ( error, index ) => {
			const key = `${ this.props.id }-${ index }`;
			return <Error key={ key }>{ error }</Error>;
		} );
	}

	/**
	 * Returns a component that displays the given list of errors,
	 * if there are any. Returns null if there are no errors to be displayed.
	 *
	 * @param {string[]} errors the error messages to be displayed.
	 * @returns {React.Component|null} the error display component, or null.
	 */
	displayErrors( errors ) {
		if ( errors && errors.length > 0 ) {
			return <ErrorDisplay>
				{ this.getErrors( errors ) }
			</ErrorDisplay>;
		}
		return null;
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		const errors = this.props.errors.concat( this.state.errors );
		const hasInput = this.props.value.length > 0;
		const hasErrors = errors.length > 0;

		let inputFieldStatus = "";
		if ( ! hasInput ) {
			inputFieldStatus = INPUT_FIELD_EMPTY;
		} else if ( hasErrors ) {
			inputFieldStatus = INPUT_FIELD_INVALID;
		} else {
			inputFieldStatus = INPUT_FIELD_VALID;
		}

		return (
			<Fragment>
				{ hasInput && this.displayErrors( errors ) }
				<TextInput
					{ ...this.props }
					id={ this.props.id }
					onChange={ this.onInputChange }
					type={ this.props.type }
					name={ this.props.name }
					inputFieldStatus={ inputFieldStatus }
					backgroundColor={ colors.$color_background_light }
				/>
			</Fragment>
		);
	}
}

ValidationInputField.propTypes = {
	intl: intlShape.isRequired,
	id: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	type: PropTypes.string,
	delay: PropTypes.number,
	constraint: PropTypes.object,
	errors: PropTypes.array,
	name: PropTypes.string,
	value: PropTypes.string,
	trimWhiteSpace: PropTypes.bool,
};

ValidationInputField.defaultProps = {
	errors: [],
	delay: 1000,
	type: "text",
	value: "",
	constraint: null,
	name: null,
	trimWhiteSpace: false,
};

export default injectIntl( ValidationInputField );
