import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";

import SubscriptionEditModal from "../components/SubscriptionEditModal";
import { cancelSubscription } from "shared-frontend/redux/actions/subscriptions";
import { getCoursesFromSubscription } from "shared-frontend/redux/selectors/entities/courseEnrollments";
import { getAllSubscriptionsById } from "shared-frontend/redux/selectors/entities/subscriptions";
import PropTypes from "prop-types";
import { getCancelSubscriptionState } from "../redux/selectors/ui/subscriptions";

/**
 * Returns sites for a given subscription.
 *
 * @param {Object} state Application state.
 * @param {string} subscriptionId ID for the subscription to find sites for.
 *
 * @returns {Array} Sites belonging to the subscription.
 */
function getSitesForSubscription( state, subscriptionId ) {
	let sites = [];
	const siteIds = state.entities.sites.allIds;
	if ( isEmpty( siteIds ) === false ) {
		sites = siteIds
			.map( siteId => state.entities.sites.byId[ siteId ] )
			.filter( site => site.subscriptions.includes( subscriptionId ) );
	}

	return sites;
}


/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state, ownProps ) => {
	const { subscriptionId } = ownProps;
	const subscription = getAllSubscriptionsById( state )[ subscriptionId ];

	const subscriptionsCancelState = getCancelSubscriptionState( state );

	return {
		loading: subscriptionsCancelState.cancelLoading,
		success: subscriptionsCancelState.cancelSuccess,
		error: subscriptionsCancelState.cancelError,
		subscription: subscription,
		amountOfActiveSites: getSitesForSubscription( state, subscriptionId ).length,
		numberOfCurrentSubscriptions: subscription.limit,
		numberOfActiveEnrollments: getCoursesFromSubscription( state, subscriptionId ).length,
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	return {
		cancelSubscription: ( subscriptionId, amount ) => {
			dispatch( cancelSubscription( subscriptionId, amount ) );
		},
	};
};

const SubscriptionEditModalContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( SubscriptionEditModal );

SubscriptionEditModalContainer.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default SubscriptionEditModalContainer;
