import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Transactions from "./Transactions";
import Search from "../../Search";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import { speak } from "@wordpress/a11y";
import util from "util";
import _debounce from "lodash/debounce";
import SuggestedAction from "../../SuggestedAction";
import { GoToButtonLink } from "../../Button";
import noOrdersImage from "../../../images/noOrders.svg";
import noResultsImage from "../../../images/SitesNoResults.svg";
import Alert from "../../Alert";
import styled from "styled-components";
import Transaction from "../../../types/Transaction";

const messages = defineMessages( {
	searchLabel: {
		id: "search.label.orders",
		defaultMessage: "Search orders",
	},
	ordersPageLoaded: {
		id: "menu.account.orders.loaded",
		defaultMessage: "Account orders page loaded",
	},
	searchResults: {
		id: "ordersSearch.results",
		defaultMessage: "Number of orders found: %d",
	},
	infoOrdersOnlyProvisionedSubscriptions: {
		id: "orders.infoProvisionedNotShopify",
		defaultMessage: "You have bought one of our products through one of our provisioning partners and those are not managed within MyYoast.",
	},
	infoOrdersMixedProvisionedSubscriptions: {
		id: "orders.infoMixedProvisionedSubscriptions",
		defaultMessage: "You currently have Yoast SEO products as well as products by Shopify, Bluehost or WordPress.com. " +
			"There are no orders in MyYoast for products purchased from them.",
	},
} );

const AlertBanner = styled.div`
	${ props => props.addMargin && "margin: 16px 0 0 0;" };
`;

const debouncedSpeak = _debounce( speak, 1000 );

/**
 * A function that returns the Order Page component, containing a search bar and the orders table.
 *
 * @param {Object} props The props.
 *
 * @returns {ReactElement} The component that contains the search bar and the order page.
 */
const OrdersPage = props => {
	/**
	 * Return the search bar.
	 *
	 * @returns {ReactElement} The rendered Search component.
	 */
	const getSearch = () => {
		return <Search
			id="search"
			searchLabel={ props.intl.formatMessage( messages.searchLabel ) }
			descriptionId="search-description"
			onChange={ props.onSearchChange }
			query={ props.query }
		/>;
	};


	/**
	 * Speaks the next results message.
	 *
	 * @returns {void}
	 */
	const speakSearchResultsMessage = () => {
		if ( props.query.length > 0 ) {
			const message = util.format( props.intl.formatMessage( messages.searchResults ), props.transactions.length );

			debouncedSpeak( message, "assertive" );
		}
	};

	useEffect( () => {
		props.loadData();

		// Announce navigation to assistive technologies.
		const message = props.intl.formatMessage( messages.ordersPageLoaded );
		speak( message );
	}, [] );

	const isFirstRun = useRef( true );
	useEffect( () => {
		if ( isFirstRun.current ) {
			isFirstRun.current = false;
			return;
		}
		/*
		 * While typing or pasting in the search field, `componentWillReceiveProps()`
		 * continously passes a new `query` props. We use this at our advantage
		 * to debounce the call to `speak()`.
		 * Note: remember for <input> and <textarea>, React `onChange` behaves
		 * like the DOM's built-in oninput event handler.
		 */
		speakSearchResultsMessage();
	}, [ props.query ] );

	const noOrdersParagraphs        = [
		<FormattedMessage
			id="orders.noOrders.welcome"
			key="orders.noOrders.welcome"
			defaultMessage="Welcome to the orders overview."
		/>,
		<FormattedMessage
			id="orders.noOrders.manage"
			key="orders.noOrders.manage"
			defaultMessage="Here you can find a list of your orders - but it looks like you didn't order anything yet!"
		/>,
		<FormattedMessage
			id="orders.noOrders.pressButton"
			key="orders.noOrders.pressButton"
			defaultMessage="Press the button below to visit our shop and get your first product."
		/>,
	];
	const noSearchResultsParagraphs = [
		<FormattedMessage
			id="orders.search.noResults"
			key="orders.search.noResults"
			defaultMessage={ "We could not find any orders matching { query }." }
			values={ { query: <strong>{ props.query }</strong> } }
		/> ];

	return (
		<>
			{ ( props.transactions.length > 0 || props.query ) && getSearch() }

			<AlertBanner addMargin={ ( props.transactions.length > 0 || props.query ) }>
				{ ( props.isOnlyProvisionerSubscriptions  ) && (
					<Alert type="info" dismissable={ false } cookieName="infoOnlyProvisionedSubscriptions">
						{ props.intl.formatMessage( messages.infoOrdersOnlyProvisionedSubscriptions ) }
					</Alert>
				) }

				{ ( props.hasMixedSubscriptions ) && (
					<Alert type="info" dismissable={ false } cookieName="infoMixedProvisionedSubscriptions">
						{ props.intl.formatMessage( messages.infoOrdersMixedProvisionedSubscriptions ) }
					</Alert>
				) }
			</AlertBanner>

			{ props.transactions.length > 0 &&
				<Transactions { ...props } onChange={ props.onSearchChange } query={ props.query } /> }

			{ ( props.query.length > 0 && props.transactions.length === 0 ) &&
				<SuggestedAction paragraphs={ noSearchResultsParagraphs } imageSource={ noResultsImage } /> }

			{ ( props.query.length <= 0 && props.transactions.length === 0 ) &&
				<SuggestedAction
					paragraphs={ noOrdersParagraphs }
					imageSource={ noOrdersImage }
				>
					<GoToButtonLink />
				</SuggestedAction> }
		</>
	);
};

export default injectIntl( OrdersPage );

OrdersPage.propTypes = {
	onSearchChange: PropTypes.func.isRequired,
	transactions: PropTypes.arrayOf( Transaction ),
	intl: intlShape.isRequired,
	query: PropTypes.string,
	loadData: PropTypes.func,
	isOnlyProvisionerSubscriptions: PropTypes.bool,
	hasMixedSubscriptions: PropTypes.bool.isRequired,
};

OrdersPage.defaultProps = {
	transactions: [],
	query: "",
	loadData: () => {},
	isOnlyProvisionerSubscriptions: false,
};
