import { defineMessages, FormattedMessage } from "react-intl";
import React, { Fragment } from "react";
import styled from "styled-components";
import Link from "../Link";

const messages = defineMessages( {
	isMobileMessage: {
		id: "install.isMobileMessage",
		defaultMessage: "To continue installing your products, please visit {link} from a desktop or laptop computer.",
	},
	myYoastInstallLink: {
		id: "install.myYoastInstallLink",
		defaultMessage: "my.yoast.com/install",
	},
} );

const MobileInformation = styled.p`
	font-size: 1em;
	color: #404040;
	margin: 16px 0;
`;

const myYoastInstallLink = <Link to={ "/install" } id="install-page">
	<FormattedMessage { ...messages.myYoastInstallLink } />
</Link>;

/**
 * Install a subscription on a site flow.
 *
 * @param {object} props The properties.
 *
 * @returns {ReactComponent} The rendered component.
 */
const InstallMobile = () => {
	return (
		<Fragment>
			<MobileInformation>
				<FormattedMessage
					{ ...messages.isMobileMessage }
					values={ { link: myYoastInstallLink } }
				/>
			</MobileInformation>
		</Fragment>
	);
};

export default InstallMobile;
