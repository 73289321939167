import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import { push } from "connected-react-router";
import { PlusSmallIcon, AdjustmentsVerticalIcon } from "@heroicons/react/24/solid";
import { orderBy } from "lodash/collection";

import { getAllSites } from "shared-frontend/redux/selectors/entities/sites";
import { linkSite, loadSites, updateSiteUrl } from "shared-frontend/redux/actions/sites";

import { LargeButton, LargeSecondaryButton } from "../Button";
import { linkSiteModalClose, linkSiteModalOpen } from "../../redux/actions/sites";
import addSiteImage from "../../images/addsite.svg";
import Card from "../card/index";
import AddSiteModal from "../modal/AddSiteModal";
import Link from "../Link";

const messages = defineMessages( {
	cardTitle: {
		id: "home.siteCard.title",
		defaultMessage: "Activate your product subscription(s)",
	},
	cardDescription: {
		id: "home.siteCard.description",
		defaultMessage: "Add the URL of your site and activate the product subscription to unlock all the features of your Premium product.",
	},
	cardNoSites: {
		id: "home.siteCard.noSites",
		defaultMessage: "Add your first site to your MyYoast account!",
	},
	cardButtonAddText: {
		id: "home.siteCard.buttonAddText",
		defaultMessage: "Add a site",
	},
	cardButtonManageText: {
		id: "home.siteCard.buttonManageText",
		defaultMessage: "Manage",
	},
	cardButtonManageSitesText: {
		id: "home.siteCard.buttonManageSitesText",
		defaultMessage: "Manage my sites",
	},
} );

const TextAndImage = styled.div`
	display: flex;
	align-items: start;

	p {
		padding-right: 3rem;

		@media only screen and (max-width: 600px) {
			padding-right: 0;
		}
	}

	img {
		flex: 0 0 120px;

		@media only screen and (max-width: 600px) {
			display: none;
		}
	}
`;

const AddSiteImage = styled.img`
	display: block;
	width: 120px;
	margin: 0 auto;
`;

const StyledLargeButton = styled( LargeButton )`
	display: flex;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width: 600px) {
		min-width: 100%;
	}

		svg {
			flex-shrink: 0;
			width: 20px;
			height: 20px;
		}

		span {
			flex-shrink: 0;
			margin-left: .3rem !important;
		}
`;

const StyledLargeButtonLink = styled( LargeSecondaryButton )`
	display: flex;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width: 600px) {
		min-width: 100%;
	}

	svg {
		flex-shrink: 0;
		width: 20px;
		height: 20px;
	}

	span {
		flex-shrink: 0;
		margin-left: .3rem !important;
	}
`;

const StyledUl = styled.ul`
	list-style-type: none;
	padding: 0;

	p {
		font-weight: var(--font-weight-500);
	  	color: var(--color-gray-800);
	}

	li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-block: calc(var(--element-padding) / 2);
		border-bottom: 1px solid var(--border-color);

		&:first-of-type {
			margin-top: calc(var(--element-padding) / 2);
			border-top: 1px solid var(--border-color-300);
		}

		&:last-of-type {
		border-bottom: none;
		}
	}
`;

const StyledDiv = styled.div`
	display: flex;
	gap: var(--element-padding);

	@media only screen and (max-width: 600px) {
		flex-direction: column;
		gap: calc(var(--element-padding) / 2);
	}
`;

/**
 * A components that lists user's sites.
 *
 * @param {Object} props Component props.
 * @returns {JSX.Element} Styled unordered list that contains markup and logic to print sites.
 */
const SitesList = ( { sites } ) => {
	return (
		<StyledUl>
			<p>My sites ({ sites.length })</p>
			{
				sites.slice( 0, 3 ).map( site => {
					return (
						<li key={ site.id }>
							<span>{ site.hostname }</span>
							<Link to={ `/sites/${site.id}` } className="no-decoration">
								<FormattedMessage
									id={ messages.cardButtonManageText.id }
									defaultMessage={ messages.cardButtonManageText.defaultMessage }
								/>
							</Link>
						</li>
					);
				} )
			}
			{ sites.length > 3 && <li>...</li> }
			{ ! sites.length &&
				<li><i>
					<FormattedMessage
						id={ messages.cardNoSites.id }
						defaultMessage={ messages.cardNoSites.defaultMessage }
					/>
				</i></li> }
		</StyledUl>
	);
};

SitesList.propTypes = {
	sites: PropTypes.array.isRequired,
};

/**
 * A function that returns the SitesCard component.
 *
 * @param {Object} props The props required for the SitesCard.
 *
 * @returns {JSX.Element} The component that contains the progress tab of the course page.
 */
const SitesCard = ( props ) => {
	return (
		<Card>
			<Card.Header>
				<h2>
					<FormattedMessage
						id={ messages.cardTitle.id }
						defaultMessage={ messages.cardTitle.defaultMessage }
					/>
				</h2>
			</Card.Header>
			<Card.Content className="no-margin">
				<TextAndImage>
					<p>
						<FormattedMessage
							id={ messages.cardDescription.id }
							defaultMessage={ messages.cardDescription.defaultMessage }
						/>
					</p>
					<AddSiteImage src={ addSiteImage } alt="" />
				</TextAndImage>
				<SitesList sites={ props.sites } />
				<StyledDiv>
					{
						props.sites.length > 3 &&
						<StyledLargeButtonLink
							id="manage-sites"
							onClick={ () => props.navigateToSites() }
						>
							<AdjustmentsVerticalIcon />
							<FormattedMessage
								id={ messages.cardButtonManageSitesText.id }
								defaultMessage={ messages.cardButtonManageSitesText.defaultMessage }
							/>
						</StyledLargeButtonLink>
					}
					<StyledLargeButton
						id="add-site"
						onClick={ props.onClick }
						aria-label={ props.intl.formatMessage( messages.cardButtonAddText ) }
					>
						<PlusSmallIcon />
						<FormattedMessage
							id={ messages.cardButtonAddText.id }
							defaultMessage={ messages.cardButtonAddText.defaultMessage }
						/>
					</StyledLargeButton>
				</StyledDiv>
				<AddSiteModal { ...props } />
			</Card.Content>
		</Card>
	);
};

SitesCard.propTypes = {
	intl: intlShape.isRequired,
	onClick: PropTypes.func.isRequired,
	navigateToSites: PropTypes.func.isRequired,
	sites: PropTypes.array.isRequired,
};

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state ) => {
	const modalOpen = state.ui.sites.addSiteModalOpen;

	const errorFound = state.ui.sites.linkSiteFailed;

	const linkError = state.ui.sites.linkSiteError;

	const linkingSiteUrl = state.ui.sites.linkingSiteUrl;

	const sites = orderBy( getAllSites( state ), "hostname", "DESC" );

	return {
		modalOpen,
		errorFound,
		linkError,
		linkingSiteUrl,
		sites,
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	dispatch( loadSites() );

	return {
		onClick: () => {
			dispatch( linkSiteModalOpen() );
		},
		addSite: ( url ) => {
			dispatch( linkSiteModalOpen() );
			dispatch( updateSiteUrl( url ) );
		},
		onClose: () => {
			dispatch( linkSiteModalClose() );
		},
		onConnect: ( url, type ) => {
			dispatch( linkSite( url, type ) );
		},
		onChange: ( url ) => {
			dispatch( updateSiteUrl( url ) );
		},
		navigateToSites: () => {
			dispatch( push( "/sites" ) );
		},
	};
};

export const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
	const url = stateProps.linkingSiteUrl;

	const onConnect = ( type, fromHome ) => {
		dispatchProps.onConnect( url, type, fromHome );
	};

	const addSite = () => {
		dispatchProps.addSite( url );
	};

	return Object.assign( {}, ownProps, stateProps, dispatchProps, { onConnect, addSite } );
};

const SitesCardContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	mergeProps,
)( SitesCard );

export default injectIntl( SitesCardContainer );
