import { colors } from "@yoast/style-guide";
import PropTypes from "prop-types";
import styled from "styled-components";
import defaults from "../../config/defaults.json";
import { addPlaceholderStyles } from "../../styles/inputs";

// Because we want to start applying the new style some places, I created this inputFieldRestyled to show this component uses new styling.
export const InputField = addPlaceholderStyles( styled.input`
	width: ${ props => props.inputWidth };
	height: ${ defaults.css.inputField.height };
	background: ${ props => props.backgroundColor };
	padding: 0 10px;
	margin-bottom: 8px;
	border: 1px solid ${ colors.$color_grey_medium };
	box-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.10);
	position: relative;
	background-size: 16px;
` );

InputField.propTypes = {
	inputWidth: PropTypes.string,
	backgroundColor: PropTypes.string,
};

InputField.defaultProps = {
	inputWidth: "100%",
	backgroundColor: colors.$color_white,
};
