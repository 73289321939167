/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
/* Internal dependencies */
import Alert from "./Alert";

const ErrorList = styled.ul`
	padding-left: 20px;
`;

/**
 * Creates a Form Validation Alert component.
 *
 * @param {object} props Properties for this element.
 *
 * @returns {string|ReactElement} the Form Validation Alert component.
 */
function FormValidationAlert( props ) {
	if ( props.errors.length <= 0 ) {
		return "";
	}

	return (
		<Alert dismissable={ false } cookieName="FormValidation" type={ props.type }>
			<ErrorList>
				{ props.errors.map( ( error, i ) => <li key={ i }> { error } </li> ) }
			</ErrorList>
		</Alert>
	);
}

FormValidationAlert.propTypes = {
	errors: PropTypes.arrayOf( PropTypes.string ),
	type: PropTypes.oneOf( [ "error", "success", "warning", "info" ] ),
};

FormValidationAlert.defaultProps = {
	errors: [],
	type: "error",
};

export default FormValidationAlert;
