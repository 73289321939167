import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Product from "./Product";

const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: var(--gap);

	@media screen and ( max-width: 1400px ) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media screen and ( max-width: 1100px ) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and ( max-width: 1024px ) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media screen and ( max-width: 800px ) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and ( max-width: 600px ) {
		grid-template-columns: repeat(1, 1fr);
	}
`;

/**
 * Returns the rendered Products component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {JSX.Element} The rendered products page.
 */
function Products( props ) {
	const glNumbersFilter = [ "82120" ];

	return (
		<CardsContainer>
			{ props.products
			// Filter out "Yoast SEO Premium + Academy" so we don't get two downloads.
				.filter( product => ! glNumbersFilter.includes( product.glNumber ) )
				.map( product => {
					return <Product
						key={ product.glNumber }
						name={ product.name }
						glNumber={ product.glNumber }
						currentVersion={ product.currentVersion }
						icon={ product.icon }
						buttons={ product.buttons }
						composerToken={ props.composerToken }
						onComposerHelpModalOpen={ props.onComposerHelpModalOpen }
						onComposerHelpModalClose={ props.onComposerHelpModalClose }
						type={ product.category }
					/>;
				} ) }
		</CardsContainer>
	);
}

export default Products;

Products.propTypes = {
	composerToken: PropTypes.object,
	onComposerHelpModalOpen: PropTypes.func,
	onComposerHelpModalClose: PropTypes.func,
	products: PropTypes.array,
};

Products.defaultProps = {
	onComposerHelpModalClose: () => {},
	onComposerHelpModalOpen: () => {},
};
