import PropTypes from "prop-types";
import React, { useEffect } from "react";
import styled from "styled-components";
import { injectIntl, intlShape, defineMessages, FormattedMessage } from "react-intl";
import Products from "./Products";
import { speak } from "@wordpress/a11y";
import noDownloadsImage from "./../images/noDownloads.svg";
import SuggestedAction from "./SuggestedAction";
import { GoToButtonLink } from "./Button";
import MyYoastModal from "./MyYoastModal";
import ComposerHelp from "./downloads/ComposerHelp";
import Link from "./Link";
import Alert from "./Alert";
import { ListHeading } from "./Headings";

const messages = defineMessages( {
	downloadsPageLoaded: {
		id: "downloadsPage.page.loaded",
		defaultMessage: "Downloads page loaded",
	},
	downloadPageTitle: {
		id: "downloads.page.title",
		defaultMessage: "Downloads",
	},
	downloadPageDescription: {
		id: "downloads.page.description",
		defaultMessage: "Need help installing Yoast plugins? Read our { link }.",
	},
	guide: {
		id: "downloadsPage.noProducts",
		defaultMessage: "our guide",
	},
	infoDownloadsOnlyProvisionedSubscriptions: {
		id: "downloadsPage.infoProvisionedNotShopify",
		defaultMessage: "You have sites with one or more of our provisioning partners. " +
			"The download and installation of these products are handled on purchase by them.",
	},
	infoDownloadsMixedProvisionedSubscriptions: {
		id: "downloadsPage.infoMixedProvisionedSubscriptions",
		defaultMessage: "On this page, you can download zip files related to your Yoast SEO products. " +
			"The download and installation of products purchased through third parties " +
			"are handled by their teams respectively.",
	},
} );

const ProductOverviewContainer = styled.div`
	display: block;
`;

const AlertBanner = styled.div`
	${props => props.addMargin && "margin: 16px 0 0 0;"};
`;

const DownloadsHeading = styled( ListHeading )`
	width: 100%;
	font-size: 22px;
	margin: 0 0 24px 0px !important;
	padding: 0 0 24px 0 !important;
`;

const DownloadsIntroTitle = styled.h1`
	font-weight: normal;
	font-size: 1.5rem;
	margin: 0 0 0.2rem 0;
`;

const DownloadsIntroDescription = styled.p`
	font-size: 1rem;
	margin: 0;
`;

/**
 * Returns the rendered Downloads Page component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {JSX.Element} The downloads page.
 */
const DownloadsPage = ( props ) => {
	useEffect( () => {
		const message = props.intl.formatMessage( messages.downloadsPageLoaded );
		speak( message );
	}, [] );

	/**
	 * Returns the modal for help on using Composer.
	 *
	 * @returns {ReactElement} The modal for help on using Composer.
	 */
	const getModal = () => {
		const modalAriaLabel = {
			id: "modal.arialabel.create",
			defaultMessage: "Help on using Composer",
		};

		if ( props.composerHelpModalIsOpen ) {
			return (
				<MyYoastModal
					isOpen={ props.composerHelpModalIsOpen }
					onClose={ props.onComposerHelpModalClose }
					modalAriaLabel={ modalAriaLabel }
				>
					<ComposerHelp
						onClose={ props.onComposerHelpModalClose }
						productName={ props.composerHelpProductName }
						productGlNumber={ props.composerHelpProductGlNumber }
						createComposerToken={ props.composerHelpCreateComposerToken }
						composerToken={ props.composerToken }
					/>
				</MyYoastModal>
			);
		}

		return null;
	};

	const noDownloadsParagraphs = [
		<FormattedMessage
			id="downloadsPage.noDownloads.welcome"
			key="downloadsPage.noDownloads.welcome"
			defaultMessage="Welcome to the downloads overview."
		/>,
		<FormattedMessage
			id="downloadsPage.byLine.plugins"
			key="downloadsPage.byLine.plugins"
			defaultMessage="Installing using composer? See { link }."
			values={ {
				link: <Link
					linkTarget="_blank"
					to="https://kb.yoast.com/kb/how-to-install-yoast-plugins-using-composer/"
				>
					{ props.intl.formatMessage( messages.guide ) }
				</Link>,
			} }
		/>,
		<FormattedMessage
			id="downloadsPage.noDownloads.explanation"
			key="downloadsPage.noDownloads.explanation"
			defaultMessage="It looks like you haven’t bought any products with downloadable files yet."
		/>,
		<FormattedMessage
			id="downloadsPage.noDownloads.pressButton"
			key="downloadsPage.noDownloads.pressButton"
			defaultMessage="To browse our products, please visit:"
		/>,
	];

	const pluginDownloads = <Products
		products={ props.plugins }
		composerToken={ props.composerToken }
		onComposerHelpModalOpen={ props.onComposerHelpModalOpen }
		onComposerHelpModalClose={ props.onComposerHelpModalClose }
	/>;

	const eBookDownloads = <Products
		products={ props.eBooks }
	/>;

	const hideForWooCommerceProvisioner =
				props.provisioners.length === 1 &&
				props.provisioners.includes( "WooCommerce" );

	return (
		<>
			{ ( props.plugins.length > 0 || props.eBooks.length > 0 ) && (
				<DownloadsHeading>
					<DownloadsIntroTitle>
						<FormattedMessage { ...messages.downloadPageTitle } />
					</DownloadsIntroTitle>
					<DownloadsIntroDescription>
						<FormattedMessage
							{ ...messages.downloadPageDescription }
							values={
								{ link: <Link linkTarget="_blank" to="https://yoa.st/myyoast-installation">installation guides</Link> }
							}
						/>
					</DownloadsIntroDescription>
				</DownloadsHeading>
			) }

			{ ! hideForWooCommerceProvisioner && (
				<AlertBanner
					addMargin={
						props.isOnlyProvisionerSubscriptions || props.hasMixedSubscriptions
					}
				>
					{ props.isOnlyProvisionerSubscriptions || props.hasMixedSubscriptions && (
						<Alert
							type="info"
							dismissable={ false }
							cookieName="infoOnlyProvisionedSubscriptions"
						>
							{ props.isOnlyProvisionerSubscriptions && props.intl.formatMessage( messages.infoDownloadsOnlyProvisionedSubscriptions ) }
							{ props.hasMixedSubscriptions && props.intl.formatMessage( messages.infoDownloadsMixedProvisionedSubscriptions ) }
						</Alert>
					) }
				</AlertBanner>
			) }

			{ ( props.eBooks.length === 0 && props.plugins.length ) === 0 && (
				<SuggestedAction
					paragraphs={ noDownloadsParagraphs }
					imageSource={ noDownloadsImage }
				>
					<GoToButtonLink />
				</SuggestedAction>
			) }

			{ ( props.plugins.length > 0 || props.eBooks.length > 0 ) && (
				<ProductOverviewContainer>
					{ pluginDownloads }
					{ props.eBooks.length > 0 ? eBookDownloads : null }
				</ProductOverviewContainer>
			) }

			{ getModal() }
		</>
	);
};

DownloadsPage.propTypes = {
	intl: intlShape.isRequired,
	eBooks: PropTypes.array,
	plugins: PropTypes.array,
	composerToken: PropTypes.object,
	composerHelpModalIsOpen: PropTypes.bool,
	onComposerHelpModalOpen: PropTypes.func.isRequired,
	onComposerHelpModalClose: PropTypes.func.isRequired,
	composerHelpCreateComposerToken: PropTypes.func.isRequired,
	composerHelpProductName: PropTypes.string,
	composerHelpProductGlNumber: PropTypes.string,
	composerHelpComposerToken: PropTypes.object,
	isOnlyProvisionerSubscriptions: PropTypes.bool.isRequired,
	hasMixedSubscriptions: PropTypes.bool.isRequired,
	provisioners: PropTypes.array,
};

DownloadsPage.defaultProps = {
	eBooks: [],
	plugins: [],
	composerToken: null,
	composerHelpModalIsOpen: false,
	composerHelpProductName: "",
	composerHelpProductGlNumber: "0",
	composerHelpComposerToken: null,
	provisioners: [],
};

export default injectIntl( DownloadsPage );
