// External dependencies.
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Internal dependencies.
import defaults from "../../config/defaults.json";
import getEnv from "shared-frontend/functions/getEnv";
import { defineMessages, FormattedMessage } from "react-intl";
import Link from "../Link";

const messages = defineMessages( {
	linkToYoast: {
		id: "link.to.yoast",
		defaultMessage: "Go to yoast.com",
	},
} );

/**
 * Basic column layout.
 */
const ColumnLayout = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 48px;
`;

/**
 * Main column.
 *
 * Either displayed in the middle when only one child
 * has been provided, or displayed on the left when
 * two have been provided.
 *
 * Is hidden when screen is sufficiently small and two children are provided.
 */
const MainColumn = styled.div`
	:not(:only-child) {
		@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
			display: none;
		}
	}
`;

/**
 * Additional column.
 *
 * Displayed on the right when at least two child elements
 * have been provided.
 */
const AdditionalColumn = styled.div`
	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		grid-column: 1/3;
	}
`;

const FooterColumn = styled.div`
	grid-column: 1/3;
	text-align: right;

	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		margin-top: var(--gap);
	}
`;

/**
 * A one or two column layout for a login / registration / reset password page.
 *
 * First child component is displayed in the left column, second is displayed on the right.
 *
 * Reverts to a one column layout when only one child is given.
 * When more then two child components are given, only the first two are shown.
 *
 * @returns {ReactElement} A two column layout containing the two children as columns.
 */
class LoginColumnLayout extends React.Component {
	/**
	 * Returns the column that should be shown on
	 * the right side of the column layout.
	 * @returns {AdditionalColumn} the column to display
	 */
	getAdditionalColumn() {
		return <AdditionalColumn>
			{ this.props.children[ 1 ] }
		</AdditionalColumn>;
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		const twoColumns = this.props.children.length > 1;

		return (
			<ColumnLayout>
				<MainColumn>
					{ twoColumns ? this.props.children[ 0 ] : this.props.children }
				</MainColumn>
				{ twoColumns ? this.getAdditionalColumn() : null }
				<FooterColumn>
					<Link to={ getEnv( "SHOP_URL", "https://yoast.com" ) } linkTarget="_blank"  hasExternalLinkIcon={ false }>
						<FormattedMessage { ...messages.linkToYoast } /> &rarr;
					</Link>
				</FooterColumn>
			</ColumnLayout>
		);
	}
}

LoginColumnLayout.propTypes = {
	children: PropTypes.any.isRequired,
};

export default LoginColumnLayout;
