import React from "react";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import ForumsImage from "../../images/supportForums.svg";
import KbImage from "../../images/supportKb.svg";
import Link from "../Link.js";
import Card from "../card/index";

const messages = defineMessages( {
	supportHeader: {
		id: "support.card.header",
		defaultMessage: "Support",
	},
	supportIntro: {
		id: "support.card.intro",
		defaultMessage: "If you have a question, if you need help, or if you just want to contact us, " +
			"there are several ways to get in touch.",
	},
	supportKbLink: {
		id: "support-card-kb-link",
		defaultMessage: "Knowledge base",
	},
	supportKb: {
		id: "support.kb",
		defaultMessage: "The Yoast Knowledge Base is always a good place to start if you have a problem or a question. " +
			"You can check the list of articles, or easily search the knowledge base to find the answers you're looking for.",
	},
	supportForumsLink: {
		id: "support-card-support-forums-link",
		defaultMessage: "Free support forums",
	},
	supportForums: {
		id: "support.forums",
		defaultMessage: "On the support forums at wordpress.org you can post about your issues, " +
			"and others will help you out.",
	},
	supportForumsDetail: {
		id: "support.forumsDetail",
		defaultMessage: "To let others help you, please include as much detail in your description as possible.",
	},
} );

const TextImageContainer = styled.div`
	display: flex;
	align-items: top;
	
	&:first-of-type {
		margin-bottom: var(--gap);
	}

	p:first-of-type {
		margin-top: 0;
	}
`;

const Image = styled.img`
	width: 100px;
	flex-shrink: 0;
	margin-left: var(--gap) !important;
`;

/**
 * A function that returns the SupportCard component.
 *
 * @param {Object} props The props required for the SupportCard component.
 *
 * @returns {ReactElement} The SupportCard component.
 */
const SupportCard = () => {
	return (
		<Card>
			<Card.Header>
				<h2>
					<FormattedMessage { ...messages.supportHeader } />
				</h2>
			</Card.Header>
			<Card.Content className="no-margin">
				<p>
					<FormattedMessage { ...messages.supportIntro } />
				</p>
				<TextImageContainer>
					<div>
						<Link to={ "https://kb.yoast.com/" } linkTarget="_blank"  hasExternalLinkIcon={ true }>
							<FormattedMessage { ...messages.supportKbLink } />
						</Link>
						<p>
							<FormattedMessage { ...messages.supportKb } />
						</p>
					</div>
					<Image src={ KbImage } alt="" />
				</TextImageContainer>
				<Link to={ "https://kb.yoast.com/kb/support/#free" } linkTarget="_blank"  hasExternalLinkIcon={ true }>
					<FormattedMessage { ...messages.supportForumsLink } />
				</Link>
				<TextImageContainer>
					<div>
						<p>
							<FormattedMessage { ...messages.supportForums } />
						</p>
						<p>
							<FormattedMessage { ...messages.supportForumsDetail } />
						</p>
					</div>
					<Image src={ ForumsImage } alt="" />
				</TextImageContainer>
			</Card.Content>
		</Card>
	);
};


export default injectIntl( SupportCard );
