// External dependencies.
import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import MediaQuery from "react-responsive";
import DocumentTitle from "react-document-title";

// Internal dependencies.
import UserStatus from "../containers/UserStatus";
import MainMenu from "../components/Menu";
import DebugInfo from "../components/DebugInfo";
import { Logo } from "../components/Logo";
import SkipLink from "../components/SkipLink";
import MobileHeaderContainer from "../containers/MobileHeaderContainer";
import { WhitePage } from "../components/PaperStyles";
import RenewalNotificationContainer from "../containers/RenewalNotification";
import defaults from "../config/defaults.json";

const Layout = styled.div`
	display: flex;
	min-height: 100%;

	/**
	* Must be here to support ../App.css:288
	 */
	@media only screen and (max-width: 1024px) {
		padding-bottom: 3rem;
	}

	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		display: block;
		min-height: 0;
		overflow: hidden;
	}
`;


/* eslint-disable max-len */
const LayoutLogin = styled.div`
	background: var(--bg-color-purple) url( https://my.yoast.com/cdn-cgi/image/width=1920%2Cquality=85fit=crop%2Cf=auto%2Conerror=redirect/login-background.jpg ) no-repeat 100% 0;
	background-size: cover;

	font-family: "Open Sans", sans-serif;
	color: #000;

	display: flex;
	min-height: 100%;

	align-items: center;
	justify-content: center;
	padding: var(--gap);
`;
/* eslint-enable max-len */

const LayoutWhite = styled.div`
	background-color: var(--bg-color-white);
	height: 100%;
	color: #404040;
`;

const Sidebar = styled.div`
	flex: 0 0 300px;
	background-color: var(--bg-color-purple);
	// Firefox needs this for user-email break word to work inside flex items.
	max-width: 300px;
	padding-left: 16px;

	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		position: fixed;
		z-index: 1;
		width: 100%;
		height: 53px;
		bottom: 0;
		max-width: none;
		padding-left: 0;
		overflow-x: auto;
		overflow-y: hidden;

		& header,
		& .user-info {
		 display: none;
		}
	}
`;

const Main = styled.main`
	flex: 1 1 auto;
	background: var(--bg-color-offwhite);
	margin: 0 2%;
	padding: 24px 0;
	// Firefox needs this for site-name break word to work.
	min-width: 0;

	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		margin: 48px 4% 0 4%;
		padding: 24px 0 100px 0;
		position: relative;
		z-index: 0;
	}
`;

const SingleMain = styled( Main )`
	margin: 48px 2% 0 2%;
`;

const WhiteMain = styled( Main )`
	background: var(--bg-color-white);
	margin: 0;
	padding: 0 0 24px;

	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		position: relative;
		margin: 0;
		padding: 0 0 24px;
		z-index: 0;
	}
`;

const Content = styled.div`
	max-width: 1200px;
	margin: 0 auto;
`;

Main.propTypes = {
	id: PropTypes.string,
};

Main.defaultProps = {
	id: "content",
};

const WhitePaper = styled( WhitePage )`
	margin: auto;
	padding: 48px;

	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		max-width: 520px;
	}

	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
		padding: 24px;
		width: 100%;
	}
`;

/**
 * Get the document title to use in the document.
 *
 * @param {string} title The base title.
 *
 * @returns {string} The enriched document title.
 */
const getDocumentTitle = title => title ? title + " – MyYoast" : "MyYoast";

/**
 * Wraps a component in the layout used for the login.
 *
 * @param {ReactElement} WrappedComponent The React component to wrap.
 * @param {string}       documentTitle    The document title.
 *
 * @returns {ReactElement} The login layout with the wrapped component.
 */
export const inLoginLayout = ( WrappedComponent, documentTitle ) => {
	return class LoginLayout extends Component {
		/**
		 * Renders the component.
		 *
		 * @returns {ReactElement} The rendered component.
		 */
		render() {
			return (
				<DocumentTitle title={ getDocumentTitle( documentTitle ) }>
					<LayoutLogin>
						<WhitePaper>
							<WrappedComponent { ...this.props } />
						</WhitePaper>
					</LayoutLogin>
				</DocumentTitle>
			);
		}
	};
};

/**
 * Wraps a component in the single column layout.
 *
 * @param {ReactElement} WrappedComponent The React component to wrap.
 * @param {string}       documentTitle    The document title.
 *
 * @returns {ReactElement} The single column layout with the wrapped component.
 */
export const inSingleLayout = ( WrappedComponent, documentTitle ) => {
	return class SingleLayout extends Component {
		/**
		 * Renders the component.
		 *
		 * @returns {ReactElement} The rendered component.
		 */
		render() {
			return (
				<DocumentTitle title={ getDocumentTitle( documentTitle ) }>
					<Layout>
						<SkipLink>
							<FormattedMessage id="skiplink" defaultMessage="Skip to main content" />
						</SkipLink>
						<MobileHeaderContainer { ...this.props } detailPage={ true } />
						<SingleMain>
							<Content>
								<RenewalNotificationContainer />
								<WrappedComponent { ...this.props } />
							</Content>
						</SingleMain>
					</Layout>
				</DocumentTitle>
			);
		}
	};
};

/**
 * Wraps a component in a layout with a white background
 *
 * @param {ReactElement} WrappedComponent The React component to wrap.
 * @param {string}       documentTitle    The document title.
 *
 * @returns {ReactElement} The layout with a white background with the wrapped component.
 */
export const inWhiteLayout = ( WrappedComponent, documentTitle ) => {
	return class WhiteLayout extends Component {
		/**
		 * Renders the component
		 *
		 * @returns {ReactElement} The rendered component.
		 */
		render() {
			return (
				<DocumentTitle title={ getDocumentTitle( documentTitle ) }>
					<LayoutWhite>
						<SkipLink>
							<FormattedMessage id="skiplink" defaultMessage="Skip to main content" />
						</SkipLink>
						<WhiteMain>
							<WrappedComponent { ...this.props } />
						</WhiteMain>
					</LayoutWhite>
				</DocumentTitle>
			);
		}
	};
};

/**
 * Wraps a component in the main layout.
 *
 * @param {ReactElement} WrappedComponent The React component to wrap.
 * @param {string}       documentTitle    The document title.
 * @param {Array}        menuItems        The document title.
 *
 * @returns {ReactElement} The main layout with the wrapped component.
 */
// eslint-disable-next-line react/display-name
export const inMainLayout = ( WrappedComponent, documentTitle, menuItems ) => props => {
	return (
		<DocumentTitle title={ getDocumentTitle( documentTitle ) }>
			<Layout>
				<SkipLink>
					<FormattedMessage id="skiplink" defaultMessage="Skip to main content" />
				</SkipLink>
				<MediaQuery maxWidth={ defaults.css.breakpoint.medium }>
					<MobileHeaderContainer detailPage={ false } />
				</MediaQuery>
				<Sidebar>
					<MediaQuery minWidth={ defaults.css.breakpoint.medium + 1 }>
						<header>
							<Logo context="sidebar" size="200px" />
						</header>
					</MediaQuery>
					<UserStatus />
					<MainMenu menuRoutes={ menuItems } />
					<DebugInfo />
				</Sidebar>
				<Main>
					<Content>
						<RenewalNotificationContainer />
						<WrappedComponent { ...props } />
					</Content>
				</Main>
			</Layout>
		</DocumentTitle>
	);
};

export default inMainLayout;
