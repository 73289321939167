import { connect } from "react-redux";
import { getAllSubscriptions } from "shared-frontend/redux/actions/subscriptions";
import RealSubscriptionUpgradeTool from "../components/account/subscriptions/SubscriptionUpgradeTool";

/**
 * Maps dispatch to props.
 *
 * @param {Function} dispatch The redux dispatch function.
 *
 * @returns {{reloadSubscriptionData: (function(): *)}} The component props.
 */
const mapDispatchToProps = ( dispatch ) => (
	{
		reloadSubscriptionData: () => dispatch( getAllSubscriptions() ),
	}
);

export const SubscriptionUpgradeTool = connect(
	() => ( {} ),
	mapDispatchToProps,
)( RealSubscriptionUpgradeTool );
