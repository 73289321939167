/* External dependencies */
import { connect } from "react-redux";
import _filter from "lodash/filter";
import _sortBy from "lodash/sortBy";

/* Internal dependencies */
import InvoiceButtonArea from "../components/account/orders/InvoiceButtonArea";
import { closeInvoicesModal, openInvoicesModal } from "../redux/actions/invoices";
import { getOrdersById } from "shared-frontend/redux/selectors/entities/orders";
import { getRefundsById } from "shared-frontend/redux/selectors/entities/refunds";
import {
	getInvoicesModalError,
	getInvoicesModalOrderId,
	isInvoicesModalOpen,
} from "../redux/selectors/ui/invoicesModal";
import { getAllSubscriptionsById, getProductSwitches } from "shared-frontend/redux/selectors/entities/subscriptions";

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state, ownProps ) => {
	const resourceId   = ownProps.resourceId;
	const type = ownProps.type;

	let invoices = [];
	if ( type === "order" ) {
		const order = getOrdersById( state )[ resourceId ];
		// Filter orders for the orderId passed to the container.
		const refundsForThisOrder = _filter( getRefundsById( state ), refund => refund.orderId === resourceId );

		invoices.push( {
			resourceId: order.id,
			refundId: "",
			date: order.date,
			type: "Invoice",
			totalAmount: order.totalAmount,
			currency: order.currency,
			invoiceNumber: order.invoiceNumber,
		} );

		invoices = invoices.concat( refundsForThisOrder.map( ( refund ) => ( {
			resourceId: refund.orderId,
			refundId: refund.id,
			date: refund.date,
			type: "Credit note",
			totalAmount: refund.amount,
			currency: order.currency,
			invoiceLink: "",
		} ) ) );
	}

	if ( type === "productSwitch" ) {
		const allProductSwitches = getProductSwitches( state );

		const currentProductSwitch = allProductSwitches.find( ( productSwitch ) => productSwitch.id.toString() === resourceId );
		if ( ! currentProductSwitch ) {
			return;
		}
		const subscription = getAllSubscriptionsById( state )[ currentProductSwitch.subscriptionId ];

		// Data other than the resourceId should never matter, but We provide placeholder values as defensive coding.
		invoices.push( {
			resourceId: resourceId,
			refundId: "",
			date: currentProductSwitch.performedAt,
			type: "Invoice",
			totalAmount: 0.00,
			currency: subscription.currency,
			invoiceNumber: currentProductSwitch.invoiceNumber,
		} );

		if ( currentProductSwitch.isRevertedBy ) {
			invoices.push( {
				resourceId: currentProductSwitch.isRevertedBy.id.toString(),
				refundId: currentProductSwitch.isRevertedBy.id.toString(),
				date: currentProductSwitch.isRevertedBy.createdAt,
				type: "Credit note",
				totalAmount: 0.00,
				currency: subscription.currency,
				invoiceNumber: currentProductSwitch.isRevertedBy.invoiceNumber,
			} );
		}
	}

	invoices = _sortBy( invoices, "date" );

	const hasMultipleInvoices = invoices.length > 1;

	// Only show invoices for completed orders and refunds??? May not be needed depending on final data structure.
	const invoiceModalProps = {
		invoicesModalIsOpen: isInvoicesModalOpen( state ),
		invoicesModalOrderId: getInvoicesModalOrderId( state ),
		error: getInvoicesModalError( state ),
	};

	// The ownProps parameter is also returned, implicitly. So the props contain orderId, for example.
	return {
		invoices,
		type,
		hasMultipleInvoices,
		invoiceModalProps,
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	return {
		onInvoicesClick: ( orderId ) => {
			dispatch( openInvoicesModal( orderId ) );
		},
		onInvoicesClose: () => {
			dispatch( closeInvoicesModal() );
		},
	};
};

const InvoicesDownloadContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( InvoiceButtonArea );

export default InvoicesDownloadContainer;
