// External dependencies.
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import styled from "styled-components";
import { colors } from "@yoast/style-guide";
import { NavLink, Route } from "react-router-dom";

// Internal dependencies.
import defaults from "../config/defaults.json";

const activeStyle = "activeSubMenu";

const SubNavigationContainer = styled.ul`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	text-align: center;
	margin: auto;
	list-style-type: none;
	margin-bottom: 2em;
	padding: 0;
	border-bottom: 4px solid ${ colors.$color_grey_medium };
`;

const SubNavigationEntry = styled.li`
	/* On large screens: the menu flex items have a min width. */
	min-width: 200px;
	font-size: 1.2em;
	flex: 1 1 0;

	@media screen and (max-width: ${ defaults.css.breakpoint.mobile }px) {
		/* On small screens: let the menu flex items grow but not shrink. */
		flex: 1 0 auto;
		min-width: 0;
	}
`;

const LinkItem = styled( NavLink )`
	display: block;
	text-decoration: none;
	padding: 0.5em 0;
	color: ${ colors.$color_grey_dark };

	margin-bottom: -4px;
	border-bottom: 4px solid transparent;

	transition: border 200ms ease-out;

	&:hover,
	&:focus,
	&.${ activeStyle } {
		border-bottom-color: ${ colors.$color_pink_dark };
		border-bottom-style: double;
	}

	&.${ activeStyle } {
		color: ${ colors.$color_pink_dark };
		border-bottom-style: solid;
	}
`;

/**
 * The SubNavigation component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered SubNavigation component.
 */
const SubNavigation = props => {
	return (
		<SubNavigationContainer role="list">
			{ props.itemRoutes.map( item => {
				const isActive = item.isActive || ( match => match );

				return <SubNavigationEntry key={ item.title }>
					<LinkItem
						activeClassName={ activeStyle }
						to={ item.path }
						isActive={ isActive }
					>
						{ item.title }
					</LinkItem>
				</SubNavigationEntry>;
			} ) }
		</SubNavigationContainer>
	);
};

SubNavigation.propTypes = {
	itemRoutes: PropTypes.array.isRequired,
};

/**
 * The SubNavigation routes.
 *
 * @param {Object} props The props to use.
 * @returns {ReactElement} The rendered route.
 */
const SubNavigationRoutes = props => {
	return (
		<Fragment>
			{ props.itemRoutes.map( ( route, routeKey ) => {
				return <Route exact={ true } key={ routeKey } path={ route.path } component={ route.component } />;
			} ) }
		</Fragment>
	);
};

SubNavigationRoutes.propTypes = {
	itemRoutes: PropTypes.array.isRequired,
};

export default SubNavigation;
export { SubNavigationRoutes };
