import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import { orderBy } from "lodash/collection";

import Header from "./SubscriptionHeader";
import AnimatedLoader from "../../Loader";
import SubscriptionDetails, { ColumnFixedWidthResponsive, RowMobileCollapseNoMinHeight } from "./SubscriptionDetails";
import { ListHeading } from "../../Headings";
import Transactions from "../orders/Transactions";
import { Paper } from "../../PaperStyles";
import defaults from "../../../config/defaults.json";
import { ColumnMinWidth, ListTable } from "../../Tables";
import Link from "../../Link";
import { hasDownload } from "shared-frontend/functions/productGroups";
import { subscriptionShape } from "./SubscriptionPropTypeShape";
import UpDownArrow from "../../../icons/UpDownArrow";
import { colors } from "@yoast/style-guide";
import CollapsibleHeader from "../../CollapsibleHeader";
import { LargeButton } from "../../Button";
import Transaction from "../../../types/Transaction";

const messages = defineMessages( {
	paymentDetailsTitle: {
		id: "subscriptionPage.paymentDetails.title",
		defaultMessage: "Payment details",
	},
	invoicesTitle: {
		id: "subscriptionPage.invoices.title",
		defaultMessage: "Invoices",
	},
	downloadTitle: {
		id: "subscriptionPage.download.title",
		defaultMessage: "Included products",
	},
	downloadLinkText: {
		id: "subscriptionPage.download.linkText",
		defaultMessage: "Download",
	},
	installationGuide: {
		id: "subscriptionPage.installationGuide",
		defaultMessage: "Read our installation guides",
	},
	yoastCoursesTitle: {
		id: "subscriptionPage.yoastCoursesTitle",
		defaultMessage: "Yoast SEO academy",
	},
	yoastPluginsTitle: {
		id: "subscriptionPage.yoastPluginsTitle",
		defaultMessage: "Yoast SEO plugins",
	},
	connectedSites: {
		id: "connectedSites.title",
		defaultMessage: "Connected sites",
	},
	noConnectedSites: {
		id: "noConnectedSites.message",
		defaultMessage: "There are no connected sites to this subscription.",
	},
} );

/**
 * Changes the default styling of the Orders ListTable.
 *
 * @param {ReactElement} orders The original Orders component.
 * @returns {ReactElement} The Orders component with changed styling.
 */
export function styledOrders( orders ) {
	return styled( orders )`
		li:first-child {
			margin-top: 40px;
		}

		li > span::before {
			top: -20px;
			font-size: 1em;
		}

		@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
			li:first-child {
				margin-top: 0;
			}
		}
	`;
}

const SubscriptionTransactions = styledOrders( Transactions );

const DownloadListHeading = styled( ListHeading )`
	display: flex;
	justify-content: space-between;

	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
		flex-direction: column;
	}
`;

const StyledSitesPaper = styled( Paper )`
	margin-top: 20px;
`;

const StyledProductsPaper = styled( Paper )`
	box-shadow: none;
`;

const Container = styled.div`
	padding: 0;
	font-size: 14px !important;

	@media screen and ( max-width: ${ defaults.css.breakpoint.tablet }px ) {
		padding: 0;
	}

	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
		padding: 0;
	}
`;

const NoConnectedSites = styled.p`
	padding: 26px;
	margin: 0;
	background: #f7f7f7;
`;

const CollapsibleHeaderDiv = styled.div`
	border-bottom: 1px solid #ccc;

	ul {
		margin: 0 !important;
		border-top: 1px solid #eee;
	}

	li {
		padding-left: 36px;
	}
`;

const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background-color: ${colors.$color_white};
	padding: 16px 24px;
	font-weight: 500;
	border: none;
	border-radius: 0;
	cursor: pointer;
`;

/**
 * Returns the rendered SubscriptionPage component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {JSX.Element} The rendered SubscriptionPage component.
 */
const SubscriptionPage = props => {
	const [ products, setProducts ] = useState( { plugins: [], courses: [] } );
	const [ isCoursesOpen, setCoursesIsOpen ] = useState( false );
	const [ isPluginsOpen, setPluginsIsOpen ] = useState( false );

	useEffect( () => {
		props.loadData();
	}, [] );

	useEffect( () => {
		setProducts( {
			plugins: props.products.filter( product => ! product.isCourse ),
			courses: props.products.filter( product => product.isCourse ),
		} );
	}, [ props ] );

	/**
	 * Creates a list based on the passed courses.
	 *
	 * @param {array} courses The courses to show in the list.
	 * @returns {JSX.Element} The list of courses.
	 */
	const getCoursesList = courses => {
		if ( courses.length === 0 ) {
			return <></>;
		}

		return (
			<StyledProductsPaper>
				<CollapsibleHeaderDiv>
					<Button onClick={ () => setCoursesIsOpen( ! isCoursesOpen ) }>
						<span>{ props.intl.formatMessage( messages.yoastCoursesTitle ) }</span>
						<UpDownArrow isOpen={ isCoursesOpen } />
					</Button>
					{ isCoursesOpen && <Container>
						<ListTable>
							{ courses.map( course => {
								const updatedCourseName = course.name.replace( /(&amp;)/, "&" );
								return (
									<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key={ course.name }>
										<ColumnMinWidth ellipsis={ true }>
											{ updatedCourseName }
										</ColumnMinWidth>
									</RowMobileCollapseNoMinHeight>
								);
							} ) }
						</ListTable>
					</Container> }
				</CollapsibleHeaderDiv>
			</StyledProductsPaper>
		);
	};

	/**
	 * Creates a list based on the passed plugins.
	 *
	 * @param {array} plugins The plugins to show in the list.
	 * @returns {JSX.Element} The list of plugins.
	 */
	const getPluginsList = plugins => {
		if ( plugins.length === 0 ) {
			return <></>;
		}

		return (
			<StyledProductsPaper>
				<CollapsibleHeaderDiv>
					<Button onClick={ () => setPluginsIsOpen( ! isPluginsOpen ) }>
						<span>{ props.intl.formatMessage( messages.yoastPluginsTitle ) }</span>
						<UpDownArrow isOpen={ isPluginsOpen } />
					</Button>
					{ isPluginsOpen && <Container>
						<ListTable>
							{ plugins.map( plugin => {
								const updatedPluginName = plugin.name.replace( /(&amp;)/, "&" );
								return (
									<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key={ plugin.name }>
										<ColumnMinWidth ellipsis={ true }>
											{ updatedPluginName }
										</ColumnMinWidth>
										<ColumnFixedWidthResponsive ellipsis={ true }>
											{
												hasDownload( plugin ) && <Link
													to={ plugin.downloads[ 0 ].file }
												>
													<FormattedMessage { ...messages.downloadLinkText } />
												</Link>
											}
										</ColumnFixedWidthResponsive>
									</RowMobileCollapseNoMinHeight>
								);
							} ) }
						</ListTable>
					</Container> }
				</CollapsibleHeaderDiv>
			</StyledProductsPaper>
		);
	};

	/**
	 * Handle click of the connected site's button
	 *
	 * @param {string} siteId The ID of the site to be managed.
	 * @returns {void} Nothing.
	 */
	const onManageHandler = ( siteId ) => {
		props.onManage( siteId );
	};

	/**
	 * Creates a list of connected sites based on the passed sites.
	 *
	 * @param {array} sites The sites to show in the list.
	 * @returns {JSX.Element} The list of sites.
	 */
	const getConnectedSites = sites => {
		// Order sites by URL.
		const sitesByName = orderBy(  sites, "url", "asc" );
		return (
			<StyledSitesPaper>
				<CollapsibleHeader title={ messages.connectedSites.defaultMessage } isOpen={ false }>
					<Container>
						{ typeof sites !== "undefined" && sites.length
							? <ListTable>
								{ sitesByName.map( site => {
									return <RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key={ site.id }>
										<ColumnMinWidth ellipsis={ true }>
											{ site.url }
										</ColumnMinWidth>
										<ColumnFixedWidthResponsive ellipsis={ true }>
											<LargeButton onClick={ () => onManageHandler( site.id ) }>
												Manage
											</LargeButton>
										</ColumnFixedWidthResponsive>
									</RowMobileCollapseNoMinHeight>;
								} ) }
							</ListTable>
							: <NoConnectedSites>
								{ props.intl.formatMessage( messages.noConnectedSites ) }
							</NoConnectedSites>
						}
					</Container>
				</CollapsibleHeader>
			</StyledSitesPaper>
		);
	};

	/**
	 * Renders the component.
	 *
	 * @returns {JSX.Element} The rendered component.
	 */
	if ( ! props.subscription && props.isLoading ) {
		return <AnimatedLoader />;
	}

	const { subscription } = props;

	return (
		<section>
			<Header
				name={ subscription.name }
				byline={ subscription.limit + " site subscription" }
				image={ subscription.product.icon }
			/>
			<Paper>
				<ListHeading>
					{ props.intl.formatMessage( messages.paymentDetailsTitle ) }
				</ListHeading>
				<SubscriptionDetails
					subscription={ subscription }
					startDate={ new Date( subscription.startDate ) }
					hasNextBilling={ subscription.nextPayment !== null }
					nextBilling={ new Date( subscription.nextPayment ) }
					hasEndDate={ subscription.endDate !== null }
					endDate={ new Date( subscription.endDate ) }
					max={ subscription.limit }
					current={ 1 }
					orders={ props.orders }
					connectedSubscriptions={ props.connectedSubscriptions }
				/>
				<ListHeading>
					{ props.intl.formatMessage( messages.invoicesTitle ) }
				</ListHeading>
				<SubscriptionTransactions hasPaper={ false } { ...props } />
				<DownloadListHeading>
					<FormattedMessage { ...messages.downloadTitle } />
					<Link
						to={ "https://yoa.st/myyoast-installation" }
						linkTarget="_blank"
					>
						<FormattedMessage { ...messages.installationGuide } />
					</Link>
				</DownloadListHeading>
				{ getCoursesList( products.courses ) }
				{ getPluginsList( products.plugins ) }
			</Paper>
			{ getConnectedSites( props.sites ) }
		</section>
	);
};

SubscriptionPage.propTypes = {
	intl: intlShape.isRequired,
	loadData: PropTypes.func.isRequired,
	onManage: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
	subscription: subscriptionShape,
	sites: PropTypes.arrayOf( PropTypes.object ),
	orders: PropTypes.arrayOf( PropTypes.object ),
	transactions: PropTypes.arrayOf( Transaction ),
	products: PropTypes.arrayOf( PropTypes.object ),
	connectedSubscriptions: PropTypes.arrayOf( subscriptionShape ),
	connectedSubscriptionsSites: PropTypes.array,
};

SubscriptionPage.defaultProps = {
	isLoading: false,
	subscription: null,
	orders: [],
	transactions: [],
	sites: [],
	connectedSubscriptions: [],
	connectedSubscriptionsSites: [],
	products: [],
};

export default injectIntl( SubscriptionPage );
