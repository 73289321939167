import { connect } from "react-redux";

import AddSiteToSubscriptionModal from "../components/AddSiteToSubscriptionModal";
import { getAllSubscriptionsById } from "shared-frontend/redux/selectors/entities/subscriptions";
import { uniqBy } from "lodash/array";
import PropTypes from "prop-types";

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state, ownProps ) => {
	const { subscriptionId } = ownProps;
	const allSubscriptions = getAllSubscriptionsById( state );
	const subscription = getAllSubscriptionsById( state )[ subscriptionId ];

	const subscriptions = Object.keys( allSubscriptions ).map( ( key ) => allSubscriptions[ key ] )
		.filter( thisSubscription => {
			if ( thisSubscription.status === "pending-cancel" ) {
				return false;
			}
			return thisSubscription.subscriptionNumber === subscription.subscriptionNumber;
		} );
	const uniqueSubscriptions = uniqBy( subscriptions, ( thisSubscription ) => {
		return thisSubscription.productId;
	} );


	return {
		subscriptions: uniqueSubscriptions,
	};
};

const AddSiteToSubscriptionModalContainer = connect(
	mapStateToProps,
)( AddSiteToSubscriptionModal );

AddSiteToSubscriptionModalContainer.propTypes = {
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
};

export default AddSiteToSubscriptionModalContainer;
