import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import icon from "../../images/seo_academy_bubble.svg";
import logo from "../../images/yoast-seo-academy-logo.png";
import StyledHeading from "./StyledHeading";
import RenderParagraph from "./RenderParagraph";
import styled from "styled-components";

const messages = defineMessages( {
	header: {
		id: "login.academyAside.header",
		defaultMessage: "Start learning SEO!",
	},
	message: {
		id: "login.academyAside.message",
		defaultMessage: "Discover the ins and outs of SEO, site structure, copywriting and" +
		" more with our free video courses presented by industry professionals!" +
		" Sign up for Yoast SEO academy and learn from the best.",
	},
} );

const Icon = styled.img`
	margin-top: 20px;
	width: 200px;
`;

const Logos = styled.img`
	margin-top: 40px;
	width: 360px;
`;

/**
 * Display's the left side with the Academy branding.
 *
 * @returns {ReactElement} The component that contains the message.
 */
function AcademyLoginAside() {
	return (
		<div>
			<Logos src={ logo } alt="MyYoast - Yoast Academy" />
			<Icon src={ icon } alt="" />
			<StyledHeading header={ messages.header } />
			<RenderParagraph message={ messages.message } />
		</div>
	);
}

export default injectIntl( AcademyLoginAside );
