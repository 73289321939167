import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import defaults from "../../../config/defaults.json";
import { Heading } from "../../Headings";
import { injectIntl } from "react-intl";

const SubscriptionHeaderContainer = styled.div`
	width: 100%;
	background-color: var(--bg-color-purple);
	display: flex;
	margin-top: var(--gap);
  	padding: var(--gap);
  	gap: var(--gap);

	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
      padding: calc(var(--gap) / 2);
	}
`;

const HeaderTitle = styled( Heading )`
	color: var(--text-color-white);
	line-height: 1.25;
	font-weight: 400;
	margin: 0;

	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
		font-size: 1em;
	}
`;

const HeaderByline = styled.p`
	margin: 0;
	color: var(--text-color-white);
	font-style: italic;
	font-weight: 400;
	font-size: 1.1em;

	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
		margin-top: 0.5em;
		font-size: 0.875em;
	}
`;

// Provides air around the image and provides background color.
const HeaderImageContainer = styled.div`
	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
		display: none;
	}
`;

const HeaderImage = styled.div`
	background: url( ${ props => props.src } ) no-repeat center center;
	background-size: contain;
	width: 96px;
	height: 96px;
`;

const HeaderDescription = styled.p`
	margin-top: 1em;
	margin-bottom: 0;
	font-size: 1em;
	font-weight: 200;
	letter-spacing: 0.05em;
	color: var(--text-color-white);
 
	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
		display: none;
	}
`;

/**
 * Creates the Subscription Header component
 *
 * @param {Object} props Passed properties.
 * @returns {ReactElement} The rendered component.
 * @constructor
 */
function SubscriptionHeader( props ) {
	const imageContainer = (
		<HeaderImageContainer>
			<HeaderImage src={ props.image } />
		</HeaderImageContainer>
	);

	// Only add the image and container if provided.
	const byline = props.byline ? <HeaderByline>{ props.byline }</HeaderByline> : "";

	return (
		<SubscriptionHeaderContainer>
			{ imageContainer }
			<div>
				<HeaderTitle>{ props.name }</HeaderTitle>
				{ byline }
				<HeaderDescription>{ props.description }</HeaderDescription>
			</div>
		</SubscriptionHeaderContainer>
	);
}

export default injectIntl( SubscriptionHeader );

SubscriptionHeader.propTypes = {
	name: PropTypes.string.isRequired,
	image: PropTypes.string,
	byline: PropTypes.string,
	description: PropTypes.string,
};

SubscriptionHeader.defaultProps = {
	image: "",
	byline: "",
	description: "",
};
