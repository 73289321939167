import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import icon from "../../images/greeting.png";
import logo from "../../images/my-yoast-academy-logo.svg";
import StyledHeading from "./StyledHeading";
import RenderParagraph from "./RenderParagraph";
import styled from "styled-components";

const messages = defineMessages( {
	header: {
		id: "signup.defaultAside.header",
		defaultMessage: "Welcome!",
	},
	message: {
		id: "signup.defaultAside.message",
		defaultMessage: "MyYoast is the portal to all things Yoast. Whether you want to comment on a post, " +
			"take our free SEO for beginners training or find a product you have purchased: it's all there!",
	},
} );

const Icon = styled.img`
	margin-top: 20px;
	width: 200px;
`;

const Logos = styled.img`
	margin-top: 40px;
	max-width: 360px;
`;

/**
 * Display's the left side with the Signup branding.
 *
 * @returns {ReactElement} The component that contains the message.
 */
function DefaultSignupAside() {
	return (
		<div>
			<Logos src={ logo } alt="MyYoast - Yoast Academy" />
			<Icon src={ icon } decoding="async" fetchPriority="high" alt="" width="200" height="200" />
			<StyledHeading header={ messages.header } />
			<RenderParagraph message={ messages.message } />
		</div>
	);
}

export default injectIntl( DefaultSignupAside );
