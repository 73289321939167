import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { speak } from "@wordpress/a11y";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";

import SiteHeader from "./SiteHeader";
import SiteSubscriptionDetailList from "./SiteSubscriptionDetailList";
import SiteDangerZone from "./SiteDangerZone";
import AnimatedLoader from "./Loader";
import AddLicenses from "./AddLicenses";
import MyYoastModal from "./MyYoastModal";
import PlatformSelect from "./sites/details/PlatformSelect";
import ErrorDisplay from "../errors/ErrorDisplay";
import Alert from "./Alert";

const messages = defineMessages( {
	sitePageLoaded: {
		id: "menu.site.loaded",
		defaultMessage: "Manage site page loaded",
	},
	changeSitePlatform: {
		id: "site.details.platform-select-header",
		defaultMessage: "Select the platform your site is running on",
	},
	modalAriaLabel: {
		id: "modal.arialabel.add",
		defaultMessage: "Add licenses",
	},
	provisionedSubscriptionsAreDisabled: {
		id: "site.subscriptionDetail.provisionedSubscriptionsAreDisabled",
		defaultMessage: "Subscriptions bought via a third party are managed by that provider." +
			" Therefore the controls for these subscriptions are disabled here.",
	},
} );

const AlertArea = styled.div`
	margin-top: 20px;
`;

/**
 * Returns the rendered Site Page component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered Site component.
 */
class SitePage extends React.Component {
	/**
	 * Speak when the page loaded.
	 *
	 * @returns {void}
	 */
	componentDidMount() {
		// Announce navigation to assistive technologies.
		const message = this.props.intl.formatMessage( messages.sitePageLoaded );
		speak( message );
	}

	/**
	 * Returns the MyYoastModal.
	 *
	 * @returns {ReactElement} The MyYoastModal modal
	 */
	getModal() {
		const storeUrl = this.props.addSubscriptionModal.storeUrl || "";
		const open     = this.props.addSubscriptionModal.modalOpen;

		return (
			<MyYoastModal
				isOpen={ open }
				onClose={ this.props.onClose }
				modalAriaLabel={ messages.modalAriaLabel }
			>
				<AddLicenses
					onClose={ this.props.onClose }
					onShop={ storeUrl }
				/>
			</MyYoastModal>
		);
	}

	/**
	 * Returns the notice
	 *
	 * @returns {ReactElement} The provisioned subscription notice.
	 */
	getProvisionedSubscriptionNotice() {
		return (
			<AlertArea>
				<Alert type="info" dismissable={ false }>
					<FormattedMessage { ...messages.provisionedSubscriptionsAreDisabled } />
				</Alert>
			</AlertArea>
		);
	}

	/**
	 * Renders a subscription detail list.
	 *
	 * @returns {ReactElement} The subscription details list.
	 */
	getSubscriptionsList() {
		if ( this.props.loadingSubscriptions ) {
			return <AnimatedLoader />;
		}

		let errorDisplay = null;
		if ( this.props.uiSite.subscriptions.error ) {
			errorDisplay = <ErrorDisplay error={ this.props.uiSite.subscriptions.error } />;
		}

		return <Fragment>
			{ errorDisplay }
			<SiteSubscriptionDetailList
				plugins={ this.props.plugins }
				onMoreInfoClick={ this.props.onMoreInfoClick }
				onToggleSubscription={ this.props.onToggleSubscription }
				onClose={ this.props.onClose }
				onToggleDisabled={ this.props.onToggleDisabled }
				togglingSubscription={ this.props.uiSite.subscriptions.toggling }
			/>
		</Fragment>;
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		const props = this.props;

		if ( props.loadingSite ) {
			return <AnimatedLoader />;
		}

		const hostnameDisplay = props.site.hostname.replace( /^(?:www\.)?/, "" );
		const siteNameDisplay = props.site.path === "/" ? hostnameDisplay : hostnameDisplay + props.site.path;

		const hasProvisionedSubscriptions = this.props.plugins.some(
			plugin => ( !! plugin.accessGivenByProvisionedSubscription && ! plugin.provisionerAllowsSiteChange ),
		);

		return (
			<div>
				<SiteHeader
					name={ siteNameDisplay }
					url={ props.site.url }
					imageUrl={ props.site.header }
					adminButton={ props.site.type === "wordpress" }
				/>
				{ hasProvisionedSubscriptions && this.getProvisionedSubscriptionNotice() }
				{ this.getSubscriptionsList() }
				<PlatformSelect
					title={ props.intl.formatMessage( messages.changeSitePlatform ) }
					siteId={ props.site.id }
					siteType={ props.site.type }
					onConfirm={ props.onConfirmPlatformChange }
					disablePlatformSelect={ props.disablePlatformSelect }
				/>
				{ ( ! hasProvisionedSubscriptions ) && <SiteDangerZone onRemove={ props.onRemove } removing={ props.uiSite.removing } /> }
				{ this.getModal() }
			</div>
		);
	}
}

SitePage.propTypes = {
	site: PropTypes.object,
	uiSite: PropTypes.object,
	subscriptions: PropTypes.arrayOf( PropTypes.object ),
	plugins: PropTypes.arrayOf( PropTypes.object ),
	onMoreInfoClick: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	onToggleSubscription: PropTypes.func.isRequired,
	onToggleDisabled: PropTypes.func.isRequired,
	intl: intlShape.isRequired,
	loadingSite: PropTypes.bool,
	loadingSubscriptions: PropTypes.bool,
	addSubscriptionModal: PropTypes.object,
	onConfirmPlatformChange: PropTypes.func.isRequired,
	disablePlatformSelect: PropTypes.bool,
	onRemove: PropTypes.func.isRequired,
};

SitePage.defaultProps = {
	plugins: [],
	site: {},
	uiSite: {},
	addSubscriptionModal: {},
	disablePlatformSelect: false,
	subscriptions: [],
	loadingSite: false,
	loadingSubscriptions: true,
};

export default injectIntl( SitePage );
