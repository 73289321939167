import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { IconButtonTransparentLink, LargeButtonLink, makeButtonFullWidth } from "./Button";
import Toggle from "./Toggle";
import plusIcon from "../icons/blue-plus-circle.svg";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import { ColumnFixedWidth, ColumnPrimary, makeFullWidth, RowMobileCollapse } from "./Tables";
import defaults from "../config/defaults.json";
import util from "util";
import Link from "./Link";
import noop from "lodash/noop";
import { debounce } from "lodash/function";

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

const messages = defineMessages( {
	toggleAriaLabel: {
		id: "site.subscriptionDetail.toggle",
		defaultMessage: "Enable subscription for: %s",
	},
	toggleAriaLabelProvisioned: {
		id: "site.subscriptionDetail.toggleProvisioned",
		defaultMessage: "Disabled, because the subscription is bought via a third party",
	},
	provisionedByMessage: {
		id: "site.subscriptionDetail.provisionedBy",
		defaultMessage: "Bought via {name}",
	},
} );

const SubscriptionLogo = styled.img`
	display: inline-block;
	width: 66px;
	height: 66px;
	vertical-align: middle;
	opacity: ${ props => props.hasSubscriptions ? "1" : "0.2" };

	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
		display: none;
	}
`;

const SubscriptionToggle = styled.span`
	display: inline-block;
	visibility: ${ props => props.hasSubscriptions ? "initial" : "hidden" };
	vertical-align: middle;
	margin: 6px 40px 0 2px;

	@media screen and ( max-width: ${ defaults.css.breakpoint.tablet }px ) {
		margin-right: 24px;
	}

	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
		margin-right: 0;
	}
`;

const ProductName = styled.span`
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;

	@media screen and ( max-width: ${ defaults.css.breakpoint.mobile }px ) {
		white-space: normal;
	}
`;

const SubscriptionUsage = styled.span`
	display: ${ props => props.hasSubscriptions ? "inline-block" : "none" };
	font-weight: 300;
	margin-right: 8px;
	margin-top: 4px;
`;

const ProvisionerSection = styled.span`
	min-width: 152px;
	@media screen and (max-width: ${ defaults.css.breakpoint.mobile }px) {
		width: 100%;
		background-image: none;
		padding: 12px 16px;
	}
`;

const ColumnFixedWidthResponsive = makeFullWidth( ColumnFixedWidth );
const ResponsiveLargeButtonLink  = makeButtonFullWidth( LargeButtonLink );

const ColumnFixedWidthEnabledMinimal = styled( ColumnFixedWidthResponsive )`
	display: ${ props => props.isEnabled ? "inline-block" : "none" };
`;

const ColumnFixedWidthEnabledFixed = styled( ColumnFixedWidthResponsive )`
	display: ${ props => props.isEnabled ? "inline-block" : "none" };
	width: 220px;
	display: flex;
	flex-direction: row-reverse;
`;

// eslint-disable-next-line require-jsdoc
function getManageSectionForThirdParty( props ) {
	const provisionerName = props.provisionerName || "third party";

	return <ColumnFixedWidthEnabledFixed isEnabled={ props.isEnabled }>
		<ProvisionerSection>
			<FormattedMessage { ...messages.provisionedByMessage } values={ { name: provisionerName } } />
		</ProvisionerSection>
	</ColumnFixedWidthEnabledFixed>;
}

// eslint-disable-next-line require-jsdoc
function getManageSectionForYoast( props, subscriptionId ) {
	const buttonProps = {
		to: `/account/subscriptions/${ subscriptionId }`,
		background: "green",
		linkTarget: "_self",
		text: <FormattedMessage id="subscriptions.buttons.manage" defaultMessage="Manage" />,
	};

	return <ColumnFixedWidthEnabledFixed isEnabled={ props.isEnabled }>
		<ResponsiveLargeButtonLink
			to={ buttonProps.to }
			background={ buttonProps.background }
			linkTarget={ buttonProps.linkTarget }
		>
			{ buttonProps.text }
		</ResponsiveLargeButtonLink>
	</ColumnFixedWidthEnabledFixed>;
}

// eslint-disable-next-line require-jsdoc
function getManageSectionForBuyButton( props ) {
	const buttonProps = {
		to: props.storeUrl,
		background: "yellow",
		linkTarget: "_blank",
		text: <FormattedMessage id="subscriptions.buttons.buy" defaultMessage="Buy" />,
	};

	return <ColumnFixedWidthEnabledFixed isEnabled={ props.isEnabled }>
		<ResponsiveLargeButtonLink
			to={ buttonProps.to }
			background={ buttonProps.background }
			linkTarget={ buttonProps.linkTarget }
		>
			{ buttonProps.text }
		</ResponsiveLargeButtonLink>
	</ColumnFixedWidthEnabledFixed>;
}


// eslint-disable-next-line require-jsdoc
function getManageSection( props ) {
	const isEnabled = props.isEnabled;
	const isPurchasedViaThirdParty = !! props.accessGivenByProvisionedSubscription;

	if ( isEnabled && isPurchasedViaThirdParty ) {
		return getManageSectionForThirdParty( props );
	}

	const hasManageableSubscription = !! props.manageButtonSubscriptionId;
	if ( hasManageableSubscription ) {
		return getManageSectionForYoast( props, props.manageButtonSubscriptionId );
	}

	return getManageSectionForBuyButton( props );
}

/**
 * Default activatable product row.
 *
 * @param {object} props The props.
 *
 * @returns {ReactElement} The siteSubscriptionDetail element.
 */
function siteSubscriptionDetailChangeable( props ) {
	const rowProps = [];
	if ( props.background ) {
		rowProps.background = props.background;
	}

	const anotherLicense = (
		<IconButtonTransparentLink to={ props.storeUrl } linkTarget="_blank" iconSource={ plusIcon } iconSize={ "1em" }>
			<FormattedMessage
				id="site.subscriptions.licenses.add"
				defaultMessage="Get another subscription"
			/>
		</IconButtonTransparentLink>
	);

	let locked = true;
	if ( props.subscriptionId !== "" ) {
		locked = false;
	}

	/**
	 * Send a request to toggle the subscription if the status is different from the current one.
	 *
	 * @param {bool} toggleStatus The status of the toggle.
	 *
	 * @returns {void}
	 */
	function onTogglePress( toggleStatus ) {
		if ( toggleStatus !== props.isEnabled ) {
			props.onToggleSubscription( props.subscriptionId, props.sourceId, props.name, toggleStatus );
		}
	}

	return (
		<RowMobileCollapse { ...rowProps } hasHeaderLabels={ false }>
			<ColumnFixedWidth>
				<SubscriptionToggle hasSubscriptions={ props.hasSubscriptions }>
					<Toggle
						onSetEnablement={ debounce( onTogglePress, 300 ) }
						onClickingTheLockedToggle={ props.onToggleDisabled }
						isEnabled={ props.isEnabled }
						locked={ locked }
						toggling={ props.togglingSubscription }
						ariaLabel={ util.format( props.intl.formatMessage( messages.toggleAriaLabel ), props.name ) }
					/>
				</SubscriptionToggle>
				<SubscriptionLogo hasSubscriptions={ props.hasSubscriptions } src={ props.icon } alt="" />
			</ColumnFixedWidth>

			<ColumnPrimary>
				<ProductName>{ props.name }</ProductName>
				<SubscriptionUsage hasSubscriptions={ props.hasSubscriptions }>
					<FormattedMessage
						id="subscriptions.remaining" defaultMessage={ "{ howMany } used" }
						values={ { howMany: props.used + "/" + props.limit } }
					/>
				</SubscriptionUsage>
				{ props.limit > 0 && anotherLicense }
			</ColumnPrimary>
			<ColumnFixedWidthEnabledMinimal isEnabled={ props.isEnabled }>
				<Link to={ "/downloads" }>
					<FormattedMessage id="subscriptions.buttons.download" defaultMessage="Download" />
				</Link>
			</ColumnFixedWidthEnabledMinimal>
			{ getManageSection( props ) }
		</RowMobileCollapse>
	);
}

/**
 * Default activatable product row.
 *
 * @param {object} props The props.
 *
 * @returns {ReactElement} The siteSubscriptionDetail element.
 */
function siteSubscriptionDetailNotChangeable( props ) {
	const rowProps = [];
	if ( props.background ) {
		rowProps.background = props.background;
	}


	return (
		<RowMobileCollapse { ...rowProps } hasHeaderLabels={ false }>
			<ColumnFixedWidth>
				<SubscriptionToggle hasSubscriptions={ props.hasSubscriptions }>
					<Toggle
						isEnabled={ props.isEnabled }
						ariaLabel={ util.format( props.intl.formatMessage( messages.toggleAriaLabelProvisioned ) ) }
						onSetEnablement={ noop }
						onClickingTheLockedToggle={ noop }
						locked={ true }
						toggling={ false }
					/>
				</SubscriptionToggle>
				<SubscriptionLogo hasSubscriptions={ props.hasSubscriptions } src={ props.icon } alt="" />
			</ColumnFixedWidth>

			<ColumnPrimary>
				<ProductName>{ props.name }</ProductName>
			</ColumnPrimary>
			<ColumnFixedWidthEnabledMinimal isEnabled={ props.isEnabled }>
				<Link to={ "/downloads" }>
					<FormattedMessage id="subscriptions.buttons.download" defaultMessage="Download" />
				</Link>
			</ColumnFixedWidthEnabledMinimal>
			{ getManageSection( props ) }
		</RowMobileCollapse>
	);
}

/**
 * Creates Site Subscriptions component
 *
 * @param {Object} props The props to use
 *
 * @returns {ReactElement} The rendered component.
 */
function SiteSubscriptionDetail( props ) {
	if ( props.accessGivenByProvisionedSubscription && ! props.provisionerAllowsSiteChange ) {
		return siteSubscriptionDetailNotChangeable( props );
	}

	return siteSubscriptionDetailChangeable( props );
}

SiteSubscriptionDetail.propTypes = {
	name: PropTypes.string.isRequired,
	onMoreInfoClick: PropTypes.func.isRequired,
	onShop: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	limit: PropTypes.number.isRequired,
	used: PropTypes.number.isRequired,
	intl: intlShape.isRequired,
	onClose: PropTypes.func.isRequired,
	storeUrl: PropTypes.string.isRequired,
	onToggleSubscription: PropTypes.func.isRequired,
	onToggleDisabled: PropTypes.func.isRequired,
	hasSubscriptions: PropTypes.bool.isRequired,
	subscriptionId: PropTypes.string,
	manageButtonSubscriptionId: PropTypes.string,
	isEnabled: PropTypes.bool,
	accessGivenByProvisionedSubscription: PropTypes.bool,
	togglingSubscription: PropTypes.bool,
	background: PropTypes.string,
	sourceId: PropTypes.number,
};

SiteSubscriptionDetail.defaultProps = {
	isEnabled: false,
	togglingSubscription: false,
	accessGivenByProvisionedSubscription: false,
	subscriptionId: "",
	manageButtonSubscriptionId: "",
	background: "var(--bg-color-white)",
};

export default injectIntl( SiteSubscriptionDetail );
