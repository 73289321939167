import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

const Message = styled.p`
	padding: 0 2em;
`;

/**
 * Returns a styled pargraph
 *
 * @param  {object} props The props.
 *
 * @returns {ReactElement} The component that contains the paragraph message.
 */
function RenderParagraph( props ) {
	return (
		<Message>
			<FormattedMessage { ...props.message } />
		</Message>
	);
}

RenderParagraph.propTypes = {
	message: PropTypes.object.isRequired,
};

export default injectIntl( RenderParagraph );
