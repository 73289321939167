import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

const Heading = styled.h1`
	font-size: 1.5em; // 24 pixels
	margin: 0.8333em; // 24 x 0.8333 = ~20 pixels
	font-weight: 400;
	color: var(--text-color-purple);
	letter-spacing: 1px;
`;

/**
 * Returns a styled h1.
 *
 * @param  {object} props The props.
 *
 * @returns {ReactElement} The component that contains the heading message.
 */
function StyledHeading( props ) {
	return (
		<Heading>
			<FormattedMessage { ...props.header } />
		</Heading>
	);
}

StyledHeading.propTypes = {
	header: PropTypes.object.isRequired,
};

export default injectIntl( StyledHeading );
