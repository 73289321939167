import { connect } from "react-redux";
import { push } from "connected-react-router";

import SitesPage from "../components/SitesPage";

import { linkSite, loadSites, updateSiteUrl } from "shared-frontend/redux/actions/sites";
import { linkSiteModalClose, linkSiteModalOpen } from "../redux/actions/sites";
import { onSearchQueryChange } from "../redux/actions/search";
import { sortPluginsByPopularity } from "shared-frontend/functions/products";
import { getSearchQuery } from "shared-frontend/redux/selectors/entities/search";
import { getPlugins } from "shared-frontend/redux/selectors/entities/products";
import { hasMixedSubscriptions, isOnlyProvisionerSubscriptions, provisioners } from "../redux/selectors/ui/subscriptions";
import { getViewableSites } from "shared-frontend/redux/selectors/entities/sites";

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state ) => {
	let sites = getViewableSites( state );

	const query = getSearchQuery( state );
	if ( query.length > 0 ) {
		sites = sites.filter( ( site ) => {
			return site.siteName.includes( query ) || site.url.includes( query );
		} );
	}

	const modalOpen      = state.ui.sites.addSiteModalOpen;
	const errorFound     = state.ui.sites.linkSiteFailed;
	const linkError      = state.ui.sites.linkSiteError;
	const linkSuccess    = state.ui.sites.linkSiteSuccess;
	const plugins        = sortPluginsByPopularity( getPlugins( state ) );
	const linkingSiteUrl = state.ui.sites.linkingSiteUrl;
	const loadingSites   = ! state.ui.sites.sitesRetrieved;

	return {
		sites,
		modalOpen,
		errorFound,
		linkError: linkError,
		linkSuccess: linkSuccess,
		plugins,
		linkingSiteUrl,
		query,
		loadingSites,
		isOnlyProvisionerSubscriptions: isOnlyProvisionerSubscriptions( state ),
		hasMixedSubscriptions: hasMixedSubscriptions( state ),
		provisioners: provisioners( state ),
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	dispatch( loadSites() );

	return {
		onClick: () => {
			dispatch( linkSiteModalOpen() );
		},
		addSite: ( url ) => {
			dispatch( linkSiteModalOpen() );
			dispatch( updateSiteUrl( url ) );
		},
		onSearchChange: ( query ) => {
			dispatch( onSearchQueryChange( query ) );
		},
		onClose: () => {
			dispatch( linkSiteModalClose() );
		},
		onConnect: ( url, type ) => {
			dispatch( linkSite( url, type ) );
		},
		submitAddSite: ( url, type ) => {
			dispatch( linkSite( url, type ) );
		},
		onChange: ( url ) => {
			dispatch( updateSiteUrl( url ) );
		},
		onManage: ( siteId ) => {
			dispatch( push( "/sites/" + siteId ) );
		},
	};
};

export const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
	let url = stateProps.linkingSiteUrl;

	if ( stateProps.linkingSiteUrl.length === 0 ) {
		url = stateProps.query;
	}

	const onConnect = ( type ) => {
		dispatchProps.onConnect( url, type );
	};

	const addSite = () => {
		dispatchProps.addSite( url );
	};

	return Object.assign( {}, ownProps, stateProps, dispatchProps, { onConnect, addSite } );
};

const SitesPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	mergeProps,
)( SitesPage );

export { SitesPageContainer };
