/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import { injectIntl, intlShape, FormattedMessage, defineMessages } from "react-intl";

/* Internal dependencies */
import { LargeButton, makeButtonFullWidth, LargeSecondaryButton } from "../../Button.js";
import { ModalHeading } from "../../Headings";
import ErrorDisplay, { ErrorPropTypeShape } from "../../../errors/ErrorDisplay";
import { StyledLabel } from "../../Labels";
import ButtonsContainer from "../../general/ButtonsContainer";
import { InputField } from "../../InputField";

const messages = defineMessages(
	{
		placeholderMessage: {
			id: "token-description.placeholder",
			defaultMessage: "What's this token for?",
		},
	},
);

const WideLargeButton = makeButtonFullWidth( LargeButton );
const WideSecondaryButton = makeButtonFullWidth( LargeSecondaryButton );

/**
 * Returns the CreateToken component.
 *
 * @returns {ReactElement} The CreateToken component.
 */
class CreateToken extends React.Component {
	/**
	 * Initializes the class with the specified props.
	 *
	 * @param {Object} props The props to be passed to the class that was extended from.
	 *
	 * @returns {void}
	 */
	constructor( props ) {
		super( props );

		this.state = {
			tokenDescriptionInput: "",
		};
	}

	/**
	 * Calls onChange function when token description changes.
	 *
	 * @param {Object} event The event returned by the change in the tokenDescription.
	 *
	 * @returns {void}
	 */
	onTokenDescriptionChange = event => {
		this.setState( {
			tokenDescriptionInput: event.target.value,
		} );
	}

	/**
	 * Handles the submit event.
	 *
	 * @param {object} event The submit event.
	 *
	 * @returns {void}
	 */
	handleSubmit = event => {
		event.preventDefault();
		if ( this.state.tokenDescriptionInput !== "" ) {
			this.props.onCreateClick( {
				name: this.state.tokenDescriptionInput,
			} );
		}
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		const isEnabled = this.state.tokenDescriptionInput !== "";
		return (
			<div>
				<ModalHeading>
					<FormattedMessage id="profile.createToken.header" defaultMessage="Create token" />
				</ModalHeading>

				<form onSubmit={ this.handleSubmit } noValidate={ true }>
					<StyledLabel htmlFor="create-token-description-input">
						<FormattedMessage
							id="profile.create-token.token-description"
							defaultMessage="Please enter a description for the token you want to create:"
						/>
					</StyledLabel>

					<InputField
						type="text"
						id="create-token-description-input"
						placeholder={ this.props.intl.formatMessage( messages.placeholderMessage ) }
						value={ this.state.tokenDescriptionInput }
						onChange={ this.onTokenDescriptionChange }
						backgroundColor="var(--bg-color-offwhite)"
					/>

					<ErrorDisplay error={ this.props.error } />

					<ButtonsContainer>
						<WideSecondaryButton onClick={ this.props.onClose }>
							<FormattedMessage id="profile.createToken.cancel" defaultMessage="Cancel" />
						</WideSecondaryButton>
						<WideLargeButton
							type="submit"
							enabledStyle={ isEnabled }
							aria-disabled={ ! isEnabled }
						>
							<FormattedMessage id="profile.create-sites.create" defaultMessage="Create token" />
						</WideLargeButton>
					</ButtonsContainer>
				</form>
			</div>
		);
	}
}

CreateToken.propTypes = {
	intl: intlShape.isRequired,
	onCreateClick: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	error: ErrorPropTypeShape.isRequired,
};

export default injectIntl( CreateToken );
