import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Modal from "../MyYoastModal";
import { defineMessages, FormattedMessage } from "react-intl";
import { ModalHeading } from "../Headings";
import styled from "styled-components";
import { SubscriptionUpgradeTool } from "../../containers/SubscriptionUpgradeTool";


const ModalContent = styled.div`
	max-width: 600px;

	@media only screen and (min-width: 600px) {
		min-width: 600px;
	}
`;

const messages = defineMessages( {
	ariaLabel: {
		id: "upgradeSubscriptionModal.aria.label",
		defaultMessage: "Upgrade subscription to bundle",
	},
	header: {
		id: "upgradeSubscriptionModal.header",
		defaultMessage: "Upgrade subscription to bundle",
	},
	successHeader: {
		id: "upgradeSubscriptionModal.successHeader",
		defaultMessage: "Successfully upgraded to a bundle!",
	},
} );
/* eslint-disable react/jsx-no-bind */
/**
 * A modal for upgrading a subscription, containing the SubscriptionUpgradeTool.
 *
 * @param {Object} props The props as described by proptypes.
 *
 * @returns {JSX.Element} The SubscriptionUpgrade modal.
 */
export const UpgradeSubscriptionModal = ( props ) => {
	const [ success, setSuccess ] = useState( false );

	useEffect( () => {
		if ( ! props.isOpen ) {
			setSuccess( false );
		}
	}, [ setSuccess, props.isOpen ] );

	return (
		<Modal
			isOpen={ props.isOpen }
			onClose={ props.onClose }
			modalAriaLabel={ messages.ariaLabel }
		>
			<ModalContent>
				<ModalHeading>
					{
						success
							? <FormattedMessage { ...messages.successHeader } />
							: <FormattedMessage { ...messages.header } />
					}
				</ModalHeading>
				<SubscriptionUpgradeTool
					subscriptionId={ props.subscriptionId }
					onCancel={ props.onClose }
					onUpgradeSuccess={ () => setSuccess( true ) }
				/>
			</ModalContent>
		</Modal>
	);
};
/* eslint-enable */
UpgradeSubscriptionModal.propTypes = {
	subscriptionId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
