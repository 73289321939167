import React, { Fragment } from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors } from "@yoast/style-guide";
import { isMobile } from "react-device-detect";

import CheckoutLadyImage from "../../icons/checkout-lady.svg";
import CheckoutSuccessImage from "../../icons/checkout-success.svg";
import LogoImage from "../../icons/Yoast-logo.svg";
import defaults from "../../config/defaults.json";
import InstallMobile from "./InstallMobile";
import InstallDesktop from "./InstallDesktop";

const messages = defineMessages( {
	checkout: {
		id: "install.checkout",
		defaultMessage: "Checkout",
	},
	stepOne: {
		id: "install.stepOne",
		defaultMessage: "Enter your details",
	},
	stepTwo: {
		id: "install.stepTwo",
		defaultMessage: "Pay",
	},
	stepThree: {
		id: "install.stepThree",
		defaultMessage: "Receive your products",
	},
	stepFour: {
		id: "downloading.stepFour",
		defaultMessage: "Install",
	},
	title: {
		id: "install.title",
		defaultMessage: "Thank you for your purchase!",
	},
	orderSuccess: {
		id: "install.orderSuccess",
		defaultMessage: "Your order has been processed successfully!",
	},
} );

const Header = styled.div`
	width: 100%;
	background-color: ${ colors.$color_purple_dark };
	padding: 32px 16px;
`;

const WideContent = styled.div`
	max-width: ${ defaults.css.breakpoint.medium }px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;

const HeaderTitle = styled.h3`
	color: white;
	font-size: 2.5em;
	line-height: 1.2;
	font-weight: 200;
	margin: 0;
`;

const HeaderImage = styled.div`
	margin: -24px 0 -38px;
	width: 100%;
	max-width: 30%;
	vertical-align: bottom;

	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		display: none;
	}
`;

const Steps = styled.ol`
	width: 100%;
	display: flex;
	align-items: baseline;
	flex-direction: row;
	list-style: none;
	margin: 32px 0;
	padding: 0;
	justify-content: space-between;
	position: relative;
	&:before {
		content:' ';
		z-index: 0;
		border-top: 1px solid rgba(0,0,0,0.2);
		width: 100%;
		position: absolute;
		top: 13px;
	}
	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		display: none;
	}
`;

const Step = styled.li`
	font-size: 1em;
	z-index: 1;
	padding: 0 8px;
	background-color: white;
	color: #707070;
	margin: 0;
	counter-increment: indicator-step;
	&:before {
		content: counter(indicator-step);
		display: inline-block;
		overflow: hidden;
		width: 20px;
		height: 20px;
		line-height: 20px;
		margin-right: 8px;
		margin-bottom: -5px;
		border-radius: 50%;
		background-color: #707070;
		text-align: center;
		font-size: 11px;
		color: #fff;
	}
`;

const StepActive = styled( Step )`
	color: #303030;
	font-weight: 600;
	&:before {
		background-color: ${ colors.$color_pink_dark };
	}
`;

const SuccessImage = styled.img`
	margin: 48px auto 0;
	width: 200px;
`;

const Title = styled.h2`
	padding: 0;
	margin: 16px 0;
	color: ${ colors.$color_pink_dark };
	font-weight: 400;
	font-size: 2.5em;
`;

const CenterContent = styled.div`
	text-align: center;
	max-width: 600px;
	margin: 0 auto;
	padding: 0 16px;
`;


/**
 * Install a subscription on a site flow.
 *
 * @param {object} props The properties.
 *
 * @returns {ReactComponent} The rendered component.
 */
const Install = ( props ) => {
	return (
		<Fragment>
			<Header>
				<WideContent>
					<div>
						<img src={ LogoImage } alt="Yoast SEO for everyone" />
						<HeaderTitle><FormattedMessage { ...messages.checkout } /></HeaderTitle>
					</div>
					<HeaderImage><img src={ CheckoutLadyImage } alt="" /></HeaderImage>
				</WideContent>
			</Header>

			<WideContent>
				<Steps>
					<Step><FormattedMessage { ...messages.stepOne } /></Step>
					<Step><FormattedMessage { ...messages.stepTwo } /></Step>
					<StepActive><FormattedMessage { ...messages.stepThree } /></StepActive>
					<Step><FormattedMessage { ...messages.stepFour } /></Step>
				</Steps>
			</WideContent>

			<CenterContent>
				<SuccessImage src={ CheckoutSuccessImage } alt={ props.intl.formatMessage( messages.orderSuccess ) } />

				<Title><FormattedMessage { ...messages.title } /></Title>

				{
					isMobile && <InstallMobile />
				}

				{
					! isMobile && <InstallDesktop
						plugins={ props.plugins }
						intl={ props.intl }
						sites={ props.sites }
						loadingSites={ props.loadingSites }
						submitAddSite={ props.submitAddSite }
					/>
				}
			</CenterContent>
		</Fragment>
	);
};

Install.propTypes = {
	intl: intlShape.isRequired,
	sites: PropTypes.array,
	loadingSites: PropTypes.bool,
	submitAddSite: PropTypes.func.isRequired,
	plugins: PropTypes.array,
};

Install.defaultProps = {
	sites: [],
	plugins: [],
	loadingSites: false,
};

export default injectIntl( Install );
