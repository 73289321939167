import React, { Fragment } from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import { noop } from "shared-frontend/functions/noop";
import YoastNativeSelect from "../general/YoastNativeSelect";
import { StyledLabel } from "../Labels";

const StyledNativeSelect = styled( YoastNativeSelect )`
	select {
		box-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.10);
		line-height: inherit;
		background-color: ${ props => props.selectDisabled ? "var(--bg-input-disabled)" : "var(--bg-color-white)" }
	}
`;

const messages = defineMessages( {
	selectExistingSite: {
		id: "install.select.orChooseExisting",
		defaultMessage: "Or choose an existing site in MyYoast",
	},
	selectExistingSitePlaceholder: {
		id: "install.select.selectExistingPlaceholder",
		defaultMessage: "Select your site...",
	},
} );

/**
 * Renders the SelectExistingSite component.
 *
 * @param {object} props The properties.
 *
 * @returns {ReactElement} The SelectExistingSite component.
 */
const SelectExistingSite = ( props ) => {
	/**
	 * Converts the event to a plain value to pass to the parent.
	 *
	 * @param {object} event The event that triggered the change.
	 *
	 * @returns {void}
	 */
	function onChange( event ) {
		props.selectionChanged( event.target.value );
	}

	const wordPressSites = props.existingSites.filter( site => site.siteType === "wordpress" );

	const options = wordPressSites.map( site => {
		return <option value={ site.url } key={ site.url }>
			{ site.url }
		</option>;
	} );

	return (
		<Fragment>
			<StyledLabel htmlFor="select-existing-site">
				<FormattedMessage { ...messages.selectExistingSite } />
			</StyledLabel>
			<StyledNativeSelect
				selectId="select-previously-added-site"
				selectName="selectSite"
				selectDefaultValue={ props.selectedSiteUrl }
				selectOnBlur={ onChange }
				selectOnChange={ onChange }
				selectDisabled={ props.disabled }
			>
				<option value="">
					{ props.intl.formatMessage( messages.selectExistingSitePlaceholder ) }
				</option>
				{ options }
			</StyledNativeSelect>
		</Fragment>
	);
};

SelectExistingSite.propTypes = {
	selectedSiteUrl: PropTypes.string,
	selectionChanged: PropTypes.func,
	existingSites: PropTypes.array,
	disabled: PropTypes.bool,
	intl: intlShape.isRequired,
};

SelectExistingSite.defaultProps = {
	selectedSiteUrl: "",
	selectionChanged: noop,
	existingSites: [],
	disabled: false,
};

export default injectIntl( SelectExistingSite );
