// External dependencies.
import React from "react";
import { defineMessages } from "react-intl";

// Internal dependencies.
import {
	HomeIcon,
	GlobeAltIcon,
	AcademicCapIcon,
	ArrowDownOnSquareIcon,
	UserIcon,
	BuildingStorefrontIcon,
} from "@heroicons/react/24/solid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { SitesPageContainer } from "../containers/SitesPage";
import AccountPageContainer from "../containers/AccountPage";
import DownloadsPageContainer from "../containers/DownloadsPage";
import HomePageContainer from "../containers/HomePage";
import getEnv from "shared-frontend/functions/getEnv";

const titles = defineMessages( {
	home: {
		id: "menu.title.home",
		defaultMessage: "Home",
	},
	sites: {
		id: "menu.title.sites",
		defaultMessage: "Sites",
	},
	courses: {
		id: "menu.title.courses",
		defaultMessage: "Academy",
	},
	downloads: {
		id: "menu.title.downloads",
		defaultMessage: "Downloads",
	},
	account: {
		id: "menu.title.account",
		defaultMessage: "Account",
	},
	shop: {
		id: "menu.title.shop",
		defaultMessage: "Shop",
	},
} );

const menuItems = [
	{
		showInMenu: true,
		path: "/",
		titleKey: "home",
		iconSource: <HomeIcon />,
		component: HomePageContainer,
		exact: true,
		isActive: ( match, location ) => {
			return location.pathname === "/";
		},
		title: titles.home,

		// External link properties
		isExternal: false,
	},
	{
		showInMenu: true,
		path: "/sites",
		titleKey: "sites",
		iconSource: <GlobeAltIcon />,
		component: SitesPageContainer,
		exact: true,
		title: titles.sites,

		// External link properties
		isExternal: false,
	},
	{
		showInMenu: true,
		path: "/courses",
		titleKey: "courses",
		iconSource: <AcademicCapIcon />,
		component: () => {
			return window.location.replace(
				getEnv( "LEARNDASH_URL", "https://academy.yoast.com" ) + "/courses",
			);
		},
		exact: true,
		title: titles.courses,

		// External link properties
		isExternal: true,
		pathname: { pathname: getEnv( "LEARNDASH_URL", "https://academy.yoast.com" ) + "/courses" },
		externalLinkIconSource: <ArrowTopRightOnSquareIcon className="external-link" />,
	},
	{
		showInMenu: true,
		path: "/downloads",
		titleKey: "downloads",
		iconSource: <ArrowDownOnSquareIcon />,
		component: DownloadsPageContainer,
		exact: true,
		title: titles.downloads,

		// External link properties
		isExternal: false,
	},
	{
		showInMenu: true,
		path: "/account",
		titleKey: "account",
		iconSource: <UserIcon />,
		component: AccountPageContainer,
		exact: false,
		title: titles.account,

		// External link properties
		isExternal: false,
	},
	{
		showInMenu: true,
		path: "/shop",
		titleKey: "shop",
		iconSource: <BuildingStorefrontIcon />,
		component: () => {
			return window.location.replace(
				getEnv( "SHOP_URL", "https://yoast.com" ) + "/shop/",
			);
		},
		exact: false,
		title: titles.shop,

		// External link properties
		isExternal: true,
		pathname: { pathname: getEnv( "SHOP_URL", "https://yoast.com" ) + "/shop/" },
		externalLinkIconSource: <ArrowTopRightOnSquareIcon className="external-link" />,
	},
];

export default menuItems;
