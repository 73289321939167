import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./styles.css";

/**
 * @param {Object} children The children.
 * @param {string} className The className.
 * @param {Object} props Additional props.
 * @returns {JSX.Element} The card header.
 */
const Header = ( { children, className = "", ...props } ) => (
	<div { ...props } className={ classNames( "card-header", className ) }>
		{ children }
	</div>
);

Header.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

Header.defaultProps = {
	className: "",
};

/**
 * @param {Object} children The children.
 * @param {string} className The className.
 * @param {Object} props Additional props.
 * @returns {JSX.Element} The card content.
 */
const Content = ( { children, className = "", ...props } ) => (
	<div { ...props } className={ classNames( "card-content", className ) }>
		{ children }
	</div>
);

Content.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

Content.defaultProps = {
	className: "",
};

/**
 * @param {Object} children The children.
 * @param {string} className The className.
 * @param {Object} props Additional props.
 * @returns {JSX.Element} The card footer.
 */
const Footer = ( { children, className = "", ...props } ) => (
	<div { ...props } className={ classNames( "card-footer", className ) }>
		{ children }
	</div>
);

Footer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

Footer.defaultProps = {
	className: "",
};

/**
 * @param {Object} children The children.
 * @param {string} className The className.
 * @param {Object} props Additional props.
 * @returns {JSX.Element} The card component.
 */
const Card = ( { children, className, ...props } ) => (
	<div { ...props } className={ classNames( "card", className ) }>
		{ children }
	</div>
);

Card.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

Card.defaultProps = {
	className: "",
};

Card.Header = Header;
Card.Content = Content;
Card.Footer = Footer;

export default Card;
