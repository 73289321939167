import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import { defineMessages, FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import ButtonsContainer from "../../general/ButtonsContainer";
import { ButtonLink, LargeSecondaryButton, LargeYellowButton, makeButtonFullWidth } from "../../Button";
import Link from "../../Link";
import { REFUND_POLICY_URL, SWITCH_BILLING_FAQ_URL, TERMS_OF_SERVICE_URL } from "../../../config/constants";
import useRequest from "../../../reactHooks/useRequest";
import PulsingLoader from "../../Loader";
import { SpanStyledAsLabel } from "../../Labels";
import YoastNativeSelect from "../../general/YoastNativeSelect";
import formatAmount from "../../../functions/currency";
import { ArrowSmallRightIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import Check from "../../../icons/checkCircle.svg";
import Success from "../../../icons/celebration-assistant.svg";
import { noop } from "shared-frontend/functions/noop";
import { doRequest, prepareInternalRequest } from "shared-frontend/functions/api";
import ErrorDisplay from "../../../errors/ErrorDisplay";
import Checkbox from "../../Checkbox";


const ActionButtonsContainer = styled( ButtonsContainer )`
	margin-top: var(--gap);
`;

const SectionTitle = styled.p`
	display: block;
	margin: var(--gap) 0 0 0;
  	font-weight: var(--font-weight-600);
`;

const SectionTitleLabel = styled( SpanStyledAsLabel )`
	display:block;
	margin-top: 24px;
  	font-weight: var(--font-weight-600);
`;

const SectionSubtitle = styled( SectionTitle )`
	font-size: var(--text-font-size);
`;

const Loader = styled( PulsingLoader )`
	margin-top: 1em;
`;

const CheckmarkList = styled.ul`
	margin: calc(var(--gap) / 3) 0 var(--gap) 0;
  	font-size: var(--text-font-size);
	list-style: none;
	padding:0;
`;

const CheckIcon = styled( CheckCircleIcon )`
	margin-right: calc(var(--gap) / 3);
	height: calc(var(--gap) / 1.5);
	transform: translateY(3px);
  	fill: var(--checkmark-circle-green);
`;

const Arrow = styled( ArrowSmallRightIcon )`
	width: var(--gap);
	height: calc(var(--gap) / 2);
	fill: var(--text-color-default);
`;

const SuccessIcon = styled.img`
	height: 160px;
	transform: scaleX(-1);
`;

const SuccessLayout = styled.div`
	display: flex;

	flex-direction:column;
	@media only screen and (min-width: 800px) {
		flex-direction:row;
	}
`;

const CurrentNextBilling = styled.span`
	text-decoration: line-through;
	color: var(--text-color-default);
`;

const CurrentPrice = styled.span`
	text-decoration: line-through;
	color: var(--text-color-default);
`;

const NewNextBilling = styled.span`
	font-weight: var(--font-weight-500);
`;
const NewPrice       = styled.span`
	font-weight: var(--font-weight-500);
`;

const NewPriceSubtext = styled.span`
	font-size:10px;
`;

const Selection = styled.div`
	border-bottom: 1px solid var(--border-color);
`;
const Summary   = styled.div`
	border-bottom: 1px solid var(--border-color);
  	padding-bottom: var(--gap);
  	margin-bottom: var(--gap);
`;

const StyledParagraph = styled.p`
	font-size: var(--text-font-size);
  	margin-top: 0;
`;

const StyledList = styled.ul`
	padding: 0;
  	margin: 0;
  	list-style-type: none;
  	font-size: var(--text-font-size);
  
  	li {
	  	margin-bottom: calc(var(--gap) / 2);
    }
`;

const StyledCheckboxLabel = styled.span`
	font-weight: var(--font-weight-500);
`;

const CheckboxesNotCheckedError = styled.p`
	margin: 0;
  	color: var(--text-color-error);
  	font-size: var(--text-font-size-small);
`;

const messages = defineMessages( {
	currentSubscription: {
		id: "subscriptionUpgradeTool.currentSubscription",
		defaultMessage: "Current subscription",
	},
	upgradeTo: {
		id: "subscriptionUpgradeTool.upgradeTo",
		defaultMessage: "Upgrade to",
	},
	whatIsIncluded: {
		id: "subscriptionUpgradeTool.whatIsIncluded",
		defaultMessage: "What's included",
	},
	updatedPaymentDetailsHeader: {
		id: "subscriptionUpgradeTool.updatedPaymentDetailsHeader",
		defaultMessage: "Updated payment details",
	},
	updatedPaymentDetails: {
		id: "subscriptionUpgradeTool.updatedPaymentDetails",
		defaultMessage: "Upgrade and get a {percentage}% bundle discount. " +
			"You won't be charged now, but your next billing date will be sooner. {link}",
	},
	updatedPaymentDetailsLink: {
		id: "subscriptionUpgradeTool.updatedPaymentDetailsLink",
		defaultMessage: "Learn more about how we recalculated your next billing date and renewal price.",
	},
	refundPolicyLabel: {
		id: "subscriptionUpgradeTool.refundPolicyLabel",
		defaultMessage: "I agree to the {link}",
	},
	refundPolicyLabelLink: {
		id: "subscriptionUpgradeTool.refundPolicyLabelLink",
		defaultMessage: "refund policy for upgrades.",
	},
	termsPolicyLabel: {
		id: "subscriptionUpgradeTool.termsPolicyLabel",
		defaultMessage: "I’ve read and accept the {link}",
	},
	termsPolicyLabelLink: {
		id: "subscriptionUpgradeTool.termsPolicyLabelLink",
		defaultMessage: "terms of service.",
	},
	nextBilling: {
		id: "subscriptionUpgradeTool.nextBilling",
		defaultMessage: "Next billing",
	},
	nextRenewalPrice: {
		id: "subscriptionUpgradeTool.nextRenewalPrice",
		defaultMessage: "Next renewal price",
	},
	newNextBilling: {
		id: "subscriptionUpgradeTool.newNextBilling",
		defaultMessage: "Your new next billing date",
	},
	currentPrice: {
		id: "subscriptionUpgradeTool.currentPrice",
		defaultMessage: "your current renewal price",
	},
	newPrice: {
		id: "subscriptionUpgradeTool.newPrice",
		defaultMessage: "Your new renewal price",
	},
	nextBillingSubtext: {
		id: "subscriptionDetails.paymentDetails.nextBillingSubtext",
		defaultMessage: "/ {term} (ex. VAT and possible discounts)",
	},
	cancel: {
		id: "subscriptionUpgradeTool.cancel",
		defaultMessage: "Cancel",
	},
	close: {
		id: "subscriptionUpgradeTool.close",
		defaultMessage: "Close",
	},
	confirm: {
		id: "subscriptionUpgradeTool.confirm",
		defaultMessage: "Upgrade to bundle",
	},
	upgrading: {
		id: "subscriptionUpgradeTool.upgrading",
		defaultMessage: "Upgrading your subscription...",
	},
	errorOccurredWhileFetching: {
		id: "subscriptionUpgradeTool.errorOccurredWhileFetching",
		defaultMessage: "Oops! Something went wrong while preparing your potential upgrade paths.",
	},
	errorOccurredWhileUpdating: {
		id: "subscriptionUpgradeTool.errorOccurredWhileUpdating",
		defaultMessage: "Oops! Something went wrong while upgrading your subscription. Please try again.",
	},
	noValidUpgrades: {
		id: "subscriptionUpgradeTool.noValidUpgrades",
		defaultMessage: "There is no further upgrade path available for your subscription at this time. Please check again later!",
	},
	tryAgain: {
		id: "subscriptionUpgradeTool.tryAgain",
		defaultMessage: "Try again",
	},
	currentNextBilling: {
		id: "subscriptionUpgradeTool.currentNextBilling",
		defaultMessage: "your current next billing date",
	},
	upgradeSuccess: {
		id: "subscriptionUpgradeTool.upgradeSuccess",
		defaultMessage: "Congratulations! You've just upgraded to the winning combination of the {newProduct}.",
	},
	getStarted: {
		id: "subscriptionUpgradeTool.getStarted",
		defaultMessage: "Let's get started! Install your new plugin(s) on your site, start optimizing, and rank higher in the search results!",
	},
	install: {
		id: "subscriptionUpgradeTool.install",
		defaultMessage: "Install plugin(s) on your site",
	},
	checkboxesNotChecked: {
		id: "subscriptionUpgradeTool.checkboxesNotChecked",
		defaultMessage: "To proceed with your upgrade, please agree to the refund policy for upgrades and accept the terms of service.",
	},
} );

/**
 * A tool that lets users choose from a list of eligible products to upgrade their subscription to.
 * The upgrade trades in time for a more expensive product.
 *
 * @param {Object} props The props as described by proptypes.
 *
 * @returns {JSX.Element} The SubscriptionUpgrade tool.
 */
export const SubscriptionUpgradeTool = ( props ) => {
	const [ selectedSwitchOptionIndex, setSelectedSwitchOptionIndex ] = useState( 0 );
	const [ upgrading, setUpgrading ]                                 = useState( false );
	const [ upgradeSuccess, setUpgradeSuccess ]                       = useState( false );
	const [ lastUpgradeError, setLastUpgradeError ]                   = useState( null );
	const [ refundPolicyChecked, setRefundPolicyChecked ]             = useState( false );
	const [ termsPolicyChecked, setTermsPolicyChecked ]               = useState( false );
	const [ showCheckboxError, setShowCheckboxError ]                 = useState( false );

	const {
		      data: switchOptions,
		      isLoading,
		      error: getSwitchOptionsError,
		      reload,
	      } = useRequest( `Subscriptions/${ props.subscriptionId }/product-switch-options` );

	const selectedSwitchOption = switchOptions[ selectedSwitchOptionIndex ] || null;

	useEffect( () => {
		if ( upgrading ) {
			setLastUpgradeError( null );

			doRequest(
				prepareInternalRequest(
					`Subscriptions/${ props.subscriptionId }/switch-product`,
					"POST",
					{ productId: selectedSwitchOption.newProduct.id },
				),
			)
				.then( () => {
					setUpgradeSuccess( true );
				} )
				.catch( ( error ) => {
					console.error( error );
					setLastUpgradeError( { message: props.intl.formatMessage( messages.errorOccurredWhileUpdating ) } );
				} )
				.finally( () => {
					props.reloadSubscriptionData();
					setUpgrading( false );
				} );
		}
	}, [ upgrading ] );

	useEffect( () => {
		if ( termsPolicyChecked && refundPolicyChecked ) {
			setShowCheckboxError( false );
		}
	}, [ termsPolicyChecked, refundPolicyChecked ] );

	const handleSubmitButtonClick = useCallback(
		() => {
			if ( ! termsPolicyChecked || ! refundPolicyChecked ) {
				setShowCheckboxError( true );
			}

			if ( termsPolicyChecked && refundPolicyChecked ) {
				setUpgrading( true );
				setShowCheckboxError( false );
			}
		},
		[ upgrading, termsPolicyChecked, refundPolicyChecked ],
	);

	const PrimaryGreenButton      = makeButtonFullWidth( ButtonLink );
	const PrimaryYellowButton     = makeButtonFullWidth( LargeYellowButton );
	const SecondaryButton         = makeButtonFullWidth( LargeSecondaryButton );
	const upgradeBillingFaqLink = ( <Link to={ SWITCH_BILLING_FAQ_URL } linkTarget="_blank" hasExternalLinkIcon={ true }>
		<FormattedMessage { ...messages.updatedPaymentDetailsLink } />
	</Link> );
	const upgradeRefundPolicyLink = <Link to={ REFUND_POLICY_URL } linkTarget="_blank" hasExternalLinkIcon={ true }>
		<FormattedMessage { ...messages.refundPolicyLabelLink } />
	</Link>;
	const upgradeBillingTermsLink = <Link to={ TERMS_OF_SERVICE_URL } linkTarget="_blank" hasExternalLinkIcon={ true }>
		<FormattedMessage { ...messages.termsPolicyLabelLink } />
	</Link>;

	if ( isLoading ) {
		return <Loader />;
	}

	if ( getSwitchOptionsError ) {
		return <>
			<p><FormattedMessage { ...messages.errorOccurredWhileFetching } /></p>
			<ActionButtonsContainer>
				{ props.onCancel !== noop &&
					<SecondaryButton onClick={ props.onCancel }>
						<FormattedMessage { ...messages.cancel } />
					</SecondaryButton>
				}
				<SecondaryButton onClick={ reload }>
					<FormattedMessage { ...messages.tryAgain } />
				</SecondaryButton>
			</ActionButtonsContainer>
		</>;
	}

	if ( ! switchOptions || switchOptions.length < 1 ) {
		return <>
			<p><FormattedMessage { ...messages.noValidUpgrades } /></p>
			<ActionButtonsContainer>
				{ props.onCancel !== noop &&
					<SecondaryButton onClick={ props.onCancel }>
						<FormattedMessage { ...messages.close } />
					</SecondaryButton>
				}
			</ActionButtonsContainer>
		</>;
	}

	if ( upgradeSuccess ) {
		return <>
			<SuccessLayout>
				<div>
					<p>
						<FormattedMessage
							{ ...messages.upgradeSuccess }
							values={ { newProduct: <strong>{ selectedSwitchOption.newProduct.name }</strong> } }
						/>
					</p>
					<p><FormattedMessage { ...messages.getStarted } /></p>
				</div>
				<SuccessIcon src={ Success } />
			</SuccessLayout>
			<ActionButtonsContainer>
				{ props.onCancel !== noop &&
					<SecondaryButton onClick={ props.onCancel }>
						<FormattedMessage { ...messages.close } />
					</SecondaryButton>
				}
				<PrimaryGreenButton to="/downloads">
					<FormattedMessage { ...messages.install } />
				</PrimaryGreenButton>
			</ActionButtonsContainer>
		</>;
	}

	/* eslint-disable no-undefined */
	const currentNextBilling = new Date( selectedSwitchOption.currentSubscription.currentNextBilling ).toLocaleDateString(
		"en-US",
		{ dateStyle: "long" },
	);
	const newNextBilling     = new Date( selectedSwitchOption.newNextBilling ).toLocaleDateString(
		"en-US",
		{ dateStyle: "long" },
	);
	/* eslint-enable */

	const currentProductCurrency = selectedSwitchOption.currentSubscription.product.currency;
	const newProductCurrency = selectedSwitchOption.newProduct.currency;

	/* eslint-disable no-inline-comments,react/jsx-no-bind */
	return (
		<>
			<ErrorDisplay error={ lastUpgradeError } />
			<Selection>
				{ /* Current subscription */ }
				<SectionTitle><FormattedMessage { ...messages.currentSubscription } /></SectionTitle>
				<span>{ selectedSwitchOption.currentSubscription.product.name }</span>

				{ /* Upgrade to */ }
				<SectionTitleLabel htmlFor="upgrade-to"><FormattedMessage { ...messages.upgradeTo } /></SectionTitleLabel>
				<YoastNativeSelect
					selectId="upgrade-to"
					selectName="upgradeTo"
					selectDefaultValue={ selectedSwitchOptionIndex.toString() }
					selectDisabled={ upgrading }
					selectOnChange={ ( e ) => setSelectedSwitchOptionIndex( e.target.value ) }
				>
					{
						switchOptions.map( ( switchOption, key ) => (
							<option value={ key } key={ `switch-${ key }` }>{ switchOption.newProduct.name }</option>
						) )
					}
				</YoastNativeSelect>

				{ /* What's included */ }
				<SectionSubtitle><FormattedMessage { ...messages.whatIsIncluded } /></SectionSubtitle>
				<CheckmarkList>
					{
						selectedSwitchOption.newProduct.productGroups.map( ( productGroupName, key ) => (
							<li key={ `pg-${ key }` }>
								<CheckIcon role="img" aria-hidden="true" focusable="false" src={ Check } />
								{ productGroupName }
							</li>
						) )
					}
				</CheckmarkList>
			</Selection>

			<Summary>
				{ /* Updated payment details */ }
				<SectionTitle><FormattedMessage { ...messages.updatedPaymentDetailsHeader } /></SectionTitle>
				<StyledParagraph>
					<FormattedMessage
						{ ...messages.updatedPaymentDetails }
						values={ {
							percentage: selectedSwitchOption.estimatedBundleDiscountPercentage,
							link: upgradeBillingFaqLink,
						} }
					/>
				</StyledParagraph>


				{ /* Next billing */ }
				<SectionTitle><FormattedMessage { ...messages.nextBilling } /></SectionTitle>
				<CurrentNextBilling title={ props.intl.formatMessage( messages.currentNextBilling ) }>
					{ currentNextBilling }
				</CurrentNextBilling>
				<Arrow />
				<NewNextBilling title={ props.intl.formatMessage( messages.newNextBilling ) }>
					{ newNextBilling }
				</NewNextBilling>

				{ /* Next renewal price */ }
				<SectionTitle><FormattedMessage { ...messages.nextRenewalPrice } /></SectionTitle>
				<CurrentPrice title={ props.intl.formatMessage( messages.currentPrice ) }>
					<FormattedNumber
						value={ formatAmount( selectedSwitchOption.currentSubscription.estimatedRenewalPriceInCents ) }
						currency={ currentProductCurrency }
						style="currency"
					/> { currentProductCurrency }
				</CurrentPrice>
				<Arrow />
				<NewPrice title={ props.intl.formatMessage( messages.newPrice ) }>
					<FormattedNumber
						value={ formatAmount( selectedSwitchOption.newEstimatedRenewalPriceInCents ) }
						currency={ newProductCurrency }
						style="currency"
					/> { newProductCurrency }
					&nbsp;
					<NewPriceSubtext>
						<FormattedMessage { ...messages.nextBillingSubtext } values={ { term: selectedSwitchOption.newProduct.billingTerm } } />
					</NewPriceSubtext>
				</NewPrice>
			</Summary>
			<StyledList>
				<li>
					<Checkbox
						id="switch-refund-policy"
						className={ showCheckboxError ? "not-checked" : "" }
						labelText={
							<StyledCheckboxLabel>
								<FormattedMessage { ...messages.refundPolicyLabel } values={ { link: upgradeRefundPolicyLink } } />
							</StyledCheckboxLabel>
						}
						onCheck={ event => setRefundPolicyChecked( event.target.checked ) }
						checked={ refundPolicyChecked }
					/>
				</li>
				<li>
					<Checkbox
						id="switch-terms-policy"
						className={ showCheckboxError ? "not-checked" : "" }
						labelText={
							<StyledCheckboxLabel>
								<FormattedMessage { ...messages.termsPolicyLabel } values={ { link: upgradeBillingTermsLink } } />
							</StyledCheckboxLabel>
						}
						onCheck={ event => setTermsPolicyChecked( event.target.checked ) }
						checked={ termsPolicyChecked }
					/>
				</li>
				{
					showCheckboxError &&
					<li>
						<CheckboxesNotCheckedError>
							<FormattedMessage { ...messages.checkboxesNotChecked } />
						</CheckboxesNotCheckedError>
					</li>
				}
			</StyledList>

			<ActionButtonsContainer>
				{ props.onCancel !== noop &&
					<SecondaryButton onClick={ props.onCancel }>
						<FormattedMessage { ...messages.cancel } />
					</SecondaryButton>
				}
				<PrimaryYellowButton
					className="upgrade_to_bundle_modal_btn"
					type="submit" onClick={ handleSubmitButtonClick }
					disabled={ upgrading }
					aria-disabled={ upgrading }
				>
					{ upgrading
						? <FormattedMessage { ...messages.upgrading } />
						: <FormattedMessage { ...messages.confirm } />
					}
				</PrimaryYellowButton>
			</ActionButtonsContainer>
		</>
	);
	/* eslint-enable */
};

SubscriptionUpgradeTool.propTypes = {
	intl: intlShape.isRequired,
	subscriptionId: PropTypes.string.isRequired,
	onCancel: PropTypes.func,
	onUpgradeSuccess: PropTypes.func,
	reloadSubscriptionData: PropTypes.func,
};

SubscriptionUpgradeTool.defaultProps = {
	onCancel: noop,
	onUpgradeSuccess: noop,
	reloadSubscriptionData: noop,
};


export default injectIntl( SubscriptionUpgradeTool );
