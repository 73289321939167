import React from "react";
import { injectIntl, intlShape, defineMessages } from "react-intl";

// Components.
import logo from "../../images/my-yoast-academy-logo.svg";
import icon from "../../images/greeting.png";
import StyledHeading from "./StyledHeading";
import RenderParagraph from "./RenderParagraph";
import styled from "styled-components";

const messages = defineMessages( {
	message: {
		id: "AlmostThere.message",
		defaultMessage: "We just sent you an email, so check your inbox! " +
		"Click the link inside the email to activate your account. After that you can access MyYoast.",
	},
	header: {
		id: "AlmostThere.header",
		defaultMessage: "Almost there!",
	},
} );

const Icon = styled.img`
	margin-top: 20px;
	max-width: 200px;
`;

const Logos = styled.img`
	margin-top: 40px;
	max-width: 360px;
`;

const MainPaper = styled.div`
	padding: 0px;
	max-width: 480px;
	text-align: center;
`;

/**
 * Shows an "Almost there" login message.
 */
class AlmostThere extends React.Component {
	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return (
			<MainPaper>
				<Logos src={ logo } alt="MyYoast - Yoast Academy" />
				<Icon src={ icon } alt="" />
				<StyledHeading header={ messages.header } />
				<RenderParagraph message={ messages.message } />
			</MainPaper>
		);
	}
}

export default injectIntl( AlmostThere );

AlmostThere.propTypes = {
	intl: intlShape.isRequired,
};

AlmostThere.defaultProps = {};
