import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl, intlShape, defineMessages } from "react-intl";
import { speak } from "@wordpress/a11y";
import MediaQuery from "react-responsive";

import PluginUpsell from "./PluginUpsell";
import AcademyUpsell from "./AcademyUpsell";
import SitesCard from "./SitesCard";
import SupportCard from "./SupportCard";
import BlogFeed from "./BlogCard";

const messages = defineMessages( {
	homePageLoaded: {
		id: "home.page.loaded",
		defaultMessage: "Home page loaded",
	},
} );

const LEFT_COLUMN = "left";
const RIGHT_COLUMN = "right";
const SINGLE_COLUMN_BREAKPOINTS = "( min-width: 1025px ) and ( max-width: 1200px ), ( max-width: 900px )";
const DOUBLE_COLUMN_BREAKPOINTS = "( min-width: 901px ) and ( max-width: 1024px ), ( min-width: 1201px )";

const cards = [
	{
		priority: 1,
		column: LEFT_COLUMN,
		id: "academy-upsell-card",
		className: "UpsellCard",
		component: <AcademyUpsell />,
	},
	{
		priority: 2,
		column: LEFT_COLUMN,
		id: "plugin-upsell-card",
		className: "UpsellCard",
		component: <PluginUpsell />,
	},
	{
		priority: 3,
		column: LEFT_COLUMN,
		id: "support-card",
		className: "SupportCard",
		component: <SupportCard />,
	},
	{
		priority: 1,
		column: RIGHT_COLUMN,
		id: "sites-card",
		className: "SitesCard",
		component: <SitesCard />,
	},
	{
		priority: 2,
		column: RIGHT_COLUMN,
		id: "blog-card",
		className: "BlogCard",
		component: <BlogFeed />,
	},
];

const CardWrapper = styled.div`
	display: inline-block;
	margin-bottom: var(--gap);
	width: 100%;
`;

const DoubleColumn = styled.div`
	display: flex;

	> :first-child {
		margin-right: var(--gap);
	}
`;

const SingleColumn = styled.div`
	width: 50%;
`;

/**
 * Returns the rendered HomePage component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered home page.
 */
const HomePage = props => {
	/**
	 * Speaks a message when the component mounted.
	 *
	 * @returns {void}
	 */
	useEffect( () => {
		// Announce navigation to assistive technologies.
		const message = props.intl.formatMessage( messages.homePageLoaded );
		speak( message );
	}, [] );

	/**
	 * Wraps the inner card content components in a ShadowDiv and a FullHeightCard.
	 *
	 * @param {array}  cardsArray An array of cards with id, className, and the inner card component.
	 * @param {string} column     A string that defines whether this card should be in the left or right column.
	 *
	 * @returns {array} Returns an array of ReactElements.
	 */
	const createCards = ( cardsArray, column = "single" ) => {
		if ( props.isOnlyProvisionerSubscriptions ) {
			cardsArray = cardsArray.filter( card => ! [ "sites-card", "plugin-upsell-card" ].includes( card.id ) );
		}

		// Sorting by priority.
		cardsArray.sort( ( a, b ) => {
			return a.priority - b.priority;
		} );

		return cardsArray.map( card => {
			if ( column !== "single" && card.column !== column ) {
				return;
			}
			return <CardWrapper id={ card.id } key={ card.id }> { card.component } </CardWrapper>;
		} );
	};

	return (
		<Fragment>
			{
				window.navigator.userAgent.indexOf( "MSIE " ) > 0 || ! ! window.navigator.userAgent.match( /Trident.*rv:11\./ )
					? (
						<div>
							{ createCards( cards ) }
						</div>
					)
					: ( [
						<MediaQuery key={ "single_column_layout" } query={ SINGLE_COLUMN_BREAKPOINTS }>
							<div>
								{ createCards( cards ) }
							</div>
						</MediaQuery>,
						<MediaQuery key={ "double_column_layout" } query={ DOUBLE_COLUMN_BREAKPOINTS }>
							<DoubleColumn>
								<SingleColumn>
									{ createCards( cards, LEFT_COLUMN ) }
								</SingleColumn>
								<SingleColumn>
									{ createCards( cards, RIGHT_COLUMN ) }
								</SingleColumn>
							</DoubleColumn>
						</MediaQuery>,
					] )
			}

		</Fragment>
	);
};

HomePage.propTypes = {
	intl: intlShape.isRequired,
	isOnlyProvisionerSubscriptions: PropTypes.bool,
};

HomePage.defaultProps = {
	isOnlyProvisionerSubscriptions: false,
};

export default injectIntl( HomePage );


