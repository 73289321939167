/* External dependencies */
import { connect } from "react-redux";
import { push } from "connected-react-router";

/* Internal dependencies */
import SubscriptionPage from "../components/account/subscriptions/SubscriptionPage";
import { getAllProducts } from "shared-frontend/redux/actions/products";
import { getProductGroups } from "shared-frontend/redux/actions/productGroups";
import { getOrders } from "shared-frontend/redux/actions/orders";
import { retrieveSites } from "shared-frontend/redux/actions/sites";
import { retrieveCourseEnrollments } from "shared-frontend/redux/actions/courses";
import { getAllSubscriptions } from "shared-frontend/redux/actions/subscriptions";
import {
	getAllSubscriptionsById,
	getConnectedSubscriptions,
	getSubscriptionsOrders,
	getSubscriptionsSites,
	getConnectedSubscriptionsSites,
	getSubscriptionsProducts,
	getTransactionsPerSubscription,
} from "shared-frontend/redux/selectors/entities/subscriptions";
import { isSubscriptionPageLoading } from "../redux/selectors/ui/subscriptions";

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state, ownProps ) => {
	const selectedSubscriptionId = ownProps.match.params.id;
	const subscription           = getAllSubscriptionsById( state )[ selectedSubscriptionId ];

	const orders   = getSubscriptionsOrders( state )[ selectedSubscriptionId ];
	const products = getSubscriptionsProducts( state )[ selectedSubscriptionId ];
	const transactions = getTransactionsPerSubscription( state )[ selectedSubscriptionId ];

	return {
		isLoading: isSubscriptionPageLoading( state ),
		subscription,
		orders,
		transactions,
		products,
		sites: getSubscriptionsSites( state )[ selectedSubscriptionId ],
		connectedSubscriptions: getConnectedSubscriptions( state )[ selectedSubscriptionId ],
		connectedSubscriptionsSites: getConnectedSubscriptionsSites( state )[ selectedSubscriptionId ],
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	return {
		loadData: () => {
			// Fetch required model data.
			dispatch( getOrders() );
			dispatch( getAllSubscriptions() );
			dispatch( retrieveSites() );
			dispatch( getAllProducts() );
			dispatch( getProductGroups() );
			dispatch( retrieveCourseEnrollments() );
		},
		onManage: ( siteId ) => {
			dispatch( push( "/sites/" + siteId ) );
		},
	};
};

const SubscriptionsPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( SubscriptionPage );

export default SubscriptionsPageContainer;
