/* eslint-disable complexity */
import util from "util";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { speak } from "@wordpress/a11y";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import _debounce from "lodash/debounce";

import Sites from "./Sites";
import Search from "./Search";
import SuggestedAction from "./SuggestedAction";
import { LargeButton } from "./Button";
import AnimatedLoader from "./Loader";
import noSitesImage from "./../images/noSites.svg";
import sitesNoResultsImage from "./../images/SitesNoResults.svg";
import AddSiteModal from "./modal/AddSiteModal";
import { ErrorPropTypeShape } from "../errors/ErrorDisplay";
import Alert from "./Alert";
import defaults from "../config/defaults.json";
import { PlusSmallIcon } from "@heroicons/react/24/solid";

const messages = defineMessages( {
	sitesPageTitle: {
		id: "page.sites.title",
		defaultMessage: "Sites",
	},
	sitesPageDescription: {
		id: "page.sites.description",
		defaultMessage:
			"You can easily add and manage your websites here. " +
			"Activate your product subscription(s) per site by using the toggles in \"Manage\". " +
			"This ensures that all the features of your product are unlocked."
		,
	},
	sitesPageLoaded: {
		id: "menu.sites.loaded",
		defaultMessage: "Sites page loaded",
	},
	searchResults: {
		id: "sitesSearch.results",
		defaultMessage: "Number of sites found: %d",
	},
	searchLabel: {
		id: "search.label.sites",
		defaultMessage: "Search sites",
	},
	addSite: {
		id: "sites.addSiteButton",
		defaultMessage: "Add site",
	},
	infoProvisionedAndMixedSubscriptions: {
		id: "sites.infoProvisionedAndMixedSubscriptions",
		defaultMessage: "You have sites with one or more of our provisioning partners. " +
			"Our partners handle installation and activation of our plugin and apps for you, so you don’t need to add and manage those sites here.",
	},
} );

const SitesIntro = styled.div`
	max-width: 50%;
	margin-bottom: 2rem;

	@media screen and (max-width: ${ defaults.css.breakpoint.medium }px) {
		max-width: none;
	}
`;

const SitesIntroTitle = styled.h1`
	font-weight: normal;
	font-size: 1.5rem;
	margin: 0 0 0.2rem 0;
`;

const SitesIntroDescription = styled.p`
	font-size: 1rem;
	margin: 0;
`;

const SiteAddContainer = styled.div`
	text-align: center;
`;

const AlertBanner = styled.div`
	${ props => props.addMargin && "margin: 16px 0 0 0;" };
`;

const StyledLargeButton = styled( LargeButton )`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;

	svg {
		flex-shrink: 0;
		width: 20px;
		height: 20px;
	}

	span {
		flex-shrink: 0;
		margin-left: .3rem;
	}
`;

const debouncedSpeak = _debounce( speak, 1000 );

/**
 * Returns the rendered Sites Page component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered Sites component.
 */
const SitesPage = props => {
	/**
	 * Returns the search bar.
	 *
	 * @returns {ReactElement} The rendered Search component.
	 */
	const getSearch = () => {
		return <Search
			id="search"
			searchLabel={ props.intl.formatMessage( messages.searchLabel ) }
			descriptionId="search-description"
			onChange={ props.onSearchChange }
			query={ props.query }
		/>;
	};

	/**
	 * Announces the search results to assistive technologies.
	 *
	 * @param {Object} nextProps The new props the component has received.
	 *
	 * @returns {void}
	 */
	const speakSearchResultsMessage = () => {
		if ( props.query.length > 0 ) {
			const message = util.format( props.intl.formatMessage( messages.searchResults ), props.sites.length );

			debouncedSpeak( message, "assertive" );
		}
	};

	useEffect( () => {
		// Announce navigation to assistive technologies.
		const message = props.intl.formatMessage( messages.sitesPageLoaded );

		speak( message );
	}, [] );

	const isFirstRun = useRef( true );
	useEffect( () => {
		if ( isFirstRun.current ) {
			isFirstRun.current = false;
			return;
		}
		/*
		 * While typing or pasting in the search field, `componentWillReceiveProps()`
		 * continously passes a new `query` props. We use this at our advantage
		 * to debounce the call to `speak()`.
		 * Note: remember for <input> and <textarea>, React `onChange` behaves
		 * like the DOM's built-in oninput event handler.
		 */
		speakSearchResultsMessage();
	}, [ props.query ] );

	/**
	 * Gets the AddSite modal to open.
	 *
	 * @returns {func} The getAddSiteModal() function to open the AddSite modal
	 */
	const getModal = () => {
		if ( ! props.modalOpen ) {
			return null;
		}
		return <AddSiteModal { ...props } />;
	};

	/**
	 * Get the messages to display when the customer doesn't have any sites.
	 *
	 * @returns {ReactElement[]} The messages.
	 */
	const getNoSitesMessages = () => {
		return [
			<FormattedMessage
				key="sites.noSite.welcome"
				id="sites.noSite.welcome"
				defaultMessage="Welcome to the sites overview."
			/>,
			<FormattedMessage
				key="sites.noSite.manage"
				id="sites.noSite.manage"
				defaultMessage="Here you will be able to manage all your sites that are running Yoast subscriptions."
			/>,
			<FormattedMessage
				key="sites.noSite.pressButton"
				id="sites.noSite.pressButton"
				defaultMessage="Press the button below to add your first site."
			/>,
		];
	};

	/**
	 * Get the messages to display when the customer's site search query doesn't yield any results.
	 *
	 * @returns {ReactElement[]} The messages.
	 */
	const getNoSearchResultMessages = () => {
		return [
			<FormattedMessage
				key="sites.sitesNoResult.notfound"
				id="sites.sitesNoResult.notfound"
				defaultMessage={ "We could not find { site } in your account." }
				values={ { site: <strong>{ props.query }</strong> } }
			/>,
			<FormattedMessage
				key="sites.sitesNoResult.add"
				id="sites.sitesNoResult.add"
				defaultMessage="Do you want to add it?"
			/>,
		];
	};

	if ( props.loadingSites ) {
		return <AnimatedLoader />;
	}

	const hasSites   = props.sites.length > 0;
	const isSearch   = props.query.length > 0;
	const showSearch = hasSites || isSearch;
	let paragraphs   = null;
	let imageSource  = null;

	if ( ! hasSites && isSearch ) {
		paragraphs  = getNoSearchResultMessages();
		imageSource = sitesNoResultsImage;
	}

	if ( ! hasSites && ! isSearch ) {
		paragraphs  = getNoSitesMessages();
		imageSource = noSitesImage;
	}

	const hideForWooCommerceProvisioner =
				props.provisioners.length === 1 &&
				props.provisioners.includes( "WooCommerce" );

	return (
		<>
			{ hasSites && (
				<SitesIntro>
					<SitesIntroTitle>
						<FormattedMessage
							id={ messages.sitesPageTitle.id }
							defaultMessage={ messages.sitesPageTitle.defaultMessage }
						/>
					</SitesIntroTitle>
					<SitesIntroDescription>
						<FormattedMessage
							id={ messages.sitesPageDescription.id }
							defaultMessage={ messages.sitesPageDescription.defaultMessage }
						/>
					</SitesIntroDescription>
				</SitesIntro>
			) }
			<SiteAddContainer>
				{ showSearch && getSearch() }
			</SiteAddContainer>

			{ ! hideForWooCommerceProvisioner && <AlertBanner addMargin={ showSearch }>
				{ ( props.isOnlyProvisionerSubscriptions || props.hasMixedSubscriptions ) && (
					<Alert type="info" dismissable={ false } cookieName="infoProvisionedAndMixedSubscriptions">
						{ props.intl.formatMessage( messages.infoProvisionedAndMixedSubscriptions ) }
					</Alert>
				) }
			</AlertBanner> }

			<SuggestedAction
				paragraphs={ paragraphs }
				imageSource={ imageSource }
			>
				<StyledLargeButton
					id="add-site__button"
					onClick={ props.addSite }
					aria-label={ props.intl.formatMessage( messages.addSite ) }
				>
					<PlusSmallIcon />
					<FormattedMessage
						id={ messages.addSite.id }
						defaultMessage={ messages.addSite.defaultMessage }
					/>
				</StyledLargeButton>
			</SuggestedAction>

			{ hasSites && <Sites
				sites={ props.sites }
				plugins={ props.plugins }
				onManage={ props.onManage }
			/> }

			{ getModal() }
		</>
	);
};

SitesPage.propTypes = {
	addSite: PropTypes.func.isRequired,
	onSearchChange: PropTypes.func.isRequired,
	onConnect: PropTypes.func.isRequired,
	onManage: PropTypes.func.isRequired,
	linkError: ErrorPropTypeShape,
	sites: PropTypes.arrayOf( PropTypes.object ),
	plugins: PropTypes.arrayOf( PropTypes.object ).isRequired,
	availableSites: PropTypes.arrayOf( PropTypes.object ),
	linkingSiteUrl: PropTypes.string,
	query: PropTypes.string,
	loadingSites: PropTypes.bool,
	modalOpen: PropTypes.bool,
	intl: intlShape.isRequired,
	isOnlyProvisionerSubscriptions: PropTypes.bool,
	hasMixedSubscriptions: PropTypes.bool,
	provisioners: PropTypes.array,
};

SitesPage.defaultProps = {
	sites: [],
	availableSites: [],
	modalOpen: false,
	linkError: null,
	loadingSites: false,
	query: "",
	linkingSiteUrl: "",
	isOnlyProvisionerSubscriptions: false,
	hasMixedSubscriptions: false,
	provisioners: [],
};

export default injectIntl( SitesPage );
