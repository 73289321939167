/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import validate from "validate.js";
import styled from "styled-components";
import { colors } from "@yoast/style-guide";
import _isUndefined from "lodash/isUndefined";
import _every from "lodash/every";
import isShallowEqual from "@wordpress/is-shallow-equal";
/* Internal dependencies */
import ErrorDisplay, { ErrorPropTypeShape } from "../../../errors/ErrorDisplay";
import { StyledLabel } from "../../Labels";
import { FormGroup, getChangeButtons } from "./FormElements";
import { emailConstraints } from "../../login/CommonConstraints";
import { displayValidateJSWarnings } from "../../../functions/displayValidateJSWarnings";
import { InputField } from "../../InputField";

/**
 * Format the validation errors in a way that our ErrorDisplay can handle.
 *
 * @param {object[]} errors The errors thrown by the validator.
 *
 * @returns {{attribute: string, message: string}[]} A formatted error.
 */
validate.formatters.custom = ( errors ) => {
	return errors.map( error => {
		return {
			attribute: error.attribute,
			message: error.options.message,
		};
	} );
};

const messages = defineMessages( {
	duplicateEmail: {
		id: "profile.error.duplicateEmail",
		defaultMessage: "The email address could not be changed, it is probably already in use.",
	},
	labelEmail: {
		id: "profile.label.email",
		defaultMessage: "Primary email address",
	},
	labelFirstName: {
		id: "profile.label.firstName",
		defaultMessage: "First name",
	},
	labelLastName: {
		id: "profile.label.lastName",
		defaultMessage: "Last name",
	},
} );

const LabelBlock = styled.div`
	width: 100%;
`;

const NameBlock = styled( LabelBlock )`
	margin-bottom: 8px;

	div:last-of-type{
		margin-bottom: 0;
	}
`;

/**
 * Returns the rendered ProfileForm component, which is a form that .
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered ProfileForm component.
 */
class ProfileForm extends React.Component {
	/**
	 * Constructs the ProfileForm class.
	 *
	 * Sets (input) validation constraints, including email.
	 *
	 * @param {Object} props The props passed to the component.
	 * @returns {void}
	 */
	constructor( props ) {
		super( props );

		this.state = {
			userFirstName: this.props.userFirstName,
			userLastName: this.props.userLastName,
			email: this.props.email,
			onDiscard: false,
			warnings: [],
		};

		this.canSave = this.canSave.bind( this );
		this.isSaved = this.isSaved.bind( this );
		this.onUpdateEmail = this.onUpdateEmail.bind( this );
		this.handleSubmit = this.handleSubmit.bind( this );
		this.discardChanges = this.discardChanges.bind( this );
		this.onUpdateFirstName = this.onUpdateName.bind( this, "first" );
		this.onUpdateLastName = this.onUpdateName.bind( this, "last" );
	}

	/**
	 * Runs the fields through the validator and returns the warnings.
	 *
	 * @param {string} email the email field that needs to be validated.
	 *
	 * @returns {Array} All validation warnings.
	 */
	validateFields( email ) {
		const constraints = {
			email: emailConstraints( this.props.intl ),
		};

		let warnings = validate( {
			email: email,
		}, constraints, { format: "custom" } );

		if ( _isUndefined( warnings ) ) {
			warnings = [];
		}

		return warnings;
	}

	/**
	 * Function to determine whether the current email can be saved.
	 *
	 * Simply checks if there is a non-empty string if we remove all whitespaces.
	 *
	 * @returns {boolean} True if it seems to be a valid string, false otherwise.
	 */
	canSave() {
		return this.state.email.replace( /\s/g, "" ) !== "";
	}


	/**
	 * Discards the changes of personal info and resets it to initial state.
	 *
	 * @returns {void}
	 */
	discardChanges() {
		this.setState( {
			userFirstName: this.props.userFirstName,
			userLastName: this.props.userLastName,
			email: this.props.email,
			onDiscard: true,
		} );
	}

	/**
	 * Whether we have saved.
	 *
	 * @returns {boolean} Whether we are currently saving.
	 */
	isSaved() {
		return this.props.isSaved && ! this.state.onDiscard &&
			_every( [ "userFirstName", "userLastName", "email" ], key => this.props[ key ] === this.state[ key ] );
	}

	/**
	 * Handles the change event on the email input field and sets the email state.
	 *
	 * @param {object} event The input field change event.
	 *
	 * @returns {void}
	 */
	onUpdateEmail( event ) {
		const newEmail = event.target.value;
		const warnings = this.validateFields( newEmail );
		this.setState( { email: newEmail, warnings: warnings } );
	}

	/**
	 * Handles the change event on the first and last name input fields and sets the related state.
	 *
	 * @param {string} type  Whether the input field is the first or last name.
	 * @param {object} event The input fields change event.
	 *
	 * @returns {void}
	 */
	onUpdateName( type, event ) {
		if ( type === "first" ) {
			this.setState( { userFirstName: event.target.value } );
			return;
		}

		this.setState( { userLastName: event.target.value } );
	}

	/**
	 * Handles the submit event on the form.
	 *
	 * @param {object} event The form submit event.
	 *
	 * @returns {void}
	 */
	handleSubmit( event ) {
		event.preventDefault();
		/*
		 * While saving: prevent multiple submissions but don't disable the
		 * button for better accessibility (avoid keyboard focus loss).
		 */
		if ( this.props.isSaving || this.state.warnings.length !== 0 ) {
			return;
		}
		const profile = {
			/* eslint-disable camelcase */
			first_name: this.state.userFirstName,
			last_name: this.state.userLastName,
			/* eslint-enable camelcase */
			email: this.state.email,
		};

		this.setState( { onDiscard: false } );
		this.props.onSaveProfile( profile );
	}

	/**
	 * Tries to reduce rerenderings when props and state don't change.
	 *
	 * Useful when other child components trigger parent component updates.
	 *
	 * @param {object} nextProps The next props.
	 * @param {object} nextState The next state.
	 *
	 * @returns {boolean} Should component update.
	 */
	shouldComponentUpdate( nextProps, nextState ) {
		return ! isShallowEqual( nextProps, this.props ) || ! isShallowEqual( nextState, this.state );
	}

	/**
	 * Resets the profile saved message when the component unmounts.
	 *
	 * @returns {void}
	 */
	componentWillUnmount() {
		this.props.resetSaveMessage();
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return (
			<FormGroup onSubmit={ this.handleSubmit }>
				<NameBlock>
					<StyledLabel htmlFor="first-name">
						<FormattedMessage
							id={ messages.labelFirstName.id }
							defaultMessage={ messages.labelFirstName.defaultMessage }
						/>
					</StyledLabel>
					<InputField
						id="first-name"
						name="first name"
						type="text"
						value={ this.state.userFirstName }
						onChange={ this.onUpdateFirstName }
						backgroundColor={ colors.$color_background_light }
					/>
					<StyledLabel htmlFor="last-name">
						<FormattedMessage
							id={ messages.labelLastName.id }
							defaultMessage={ messages.labelLastName.defaultMessage }
						/>
					</StyledLabel>
					<InputField
						id="last-name"
						name="last name"
						type="text"
						value={ this.state.userLastName }
						onChange={ this.onUpdateLastName }
						backgroundColor={ colors.$color_background_light }
					/>
				</NameBlock>
				<LabelBlock>
					<StyledLabel htmlFor="email-address">
						<FormattedMessage
							id={ messages.labelEmail.id }
							defaultMessage={ messages.labelEmail.defaultMessage }
						/>
					</StyledLabel>
					<InputField
						id="email-address"
						autocomplete="on"
						name="email"
						type="text"
						value={ this.state.email }
						onChange={ this.onUpdateEmail }
						backgroundColor={ colors.$color_background_light }
					/>
					{ displayValidateJSWarnings( this.state.warnings, "email" ) }
					<ErrorDisplay error={ this.props.saveEmailError } />
					{ getChangeButtons(
						"profile",
						this.props.intl,
						this.state.warnings.length === 0,
						this.props.isSaving,
						this.isSaved(),
						this.discardChanges,
					) }
				</LabelBlock>
			</FormGroup>
		);
	}
}

ProfileForm.propTypes = {
	intl: intlShape.isRequired,
	onSaveProfile: PropTypes.func.isRequired,
	email: PropTypes.string,
	userFirstName: PropTypes.string,
	userLastName: PropTypes.string,
	isSaving: PropTypes.bool,
	isSaved: PropTypes.bool,
	saveEmailError: ErrorPropTypeShape,
	resetSaveMessage: PropTypes.func.isRequired,
};

ProfileForm.defaultProps = {
	email: "",
	userFirstName: "",
	userLastName: "",
	isSaving: false,
	isSaved: false,
	saveEmailError: null,
};

export default injectIntl( ProfileForm );
