/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

/* Internal dependencies */
import { LargeButtonLink, LargeSecondaryButton } from "./Button.js";
import { ModalHeading } from "./Headings";
import ButtonsContainer from "./general/ButtonsContainer";

const AddLicensesModal = styled.div`
	max-width: 640px;
	margin: auto;
`;

const AddLicensesText = styled.p`
	font-size: 16px;
`;

/**
 * Renders the AddLicenses component.
 *
 * @param {Object} props Component props.
 * @param {Function} props.onClose   The function to execute when the cancel button is clicked.
 * @param {Function} props.onUpgrade The function to execute when the link button is clicked.
 *
 * @returns {ReactElement} A react component describing the AddLicenses modal.
 */
export default function AddLicenses( props ) {
	return (
		<AddLicensesModal role="document">
			<ModalHeading>
				<FormattedMessage id="add-licenses.header" defaultMessage="You are out of licenses" />
			</ModalHeading>
			<AddLicensesText>
				<FormattedMessage
					id="add-licenses.text"
					defaultMessage="You've used up all the site licenses in your current subscription.
						If you want to add more sites, please buy another subscription."
				/>
			</AddLicensesText>
			<ButtonsContainer>
				<LargeSecondaryButton type="button" onClick={ props.onClose }>
					<FormattedMessage id="add-licenses.buy-more.cancel" defaultMessage="Cancel" />
				</LargeSecondaryButton>

				<LargeButtonLink to={ props.onShop } linkTarget="_blank">
					<FormattedMessage id="add-licenses.buy-more.shop" defaultMessage="Shop" />
				</LargeButtonLink>
			</ButtonsContainer>
		</AddLicensesModal>
	);
}

AddLicenses.propTypes = {
	onClose: PropTypes.func.isRequired,
	onShop: PropTypes.string.isRequired,
};
