import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { IconButtonLink, LinkButton } from "./Button";
import { injectIntl, intlShape, defineMessages, FormattedMessage } from "react-intl";
import _isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import Card from "./card/index.js";
import Link from "./Link";
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/solid";
import { generatePluginChangelogUrl } from "shared-frontend/functions/products";

const messages = defineMessages( {
	downloadButton: {
		id: "product.buttons.download",
		defaultMessage: "Download",
	},
	version: {
		id: "product.product.version",
		defaultMessage: "Version",
	},
} );

const ProductIcon = styled.img`
	width: 112px;
	height: 112px;
	padding: 0;
	margin-top: var(--gap) !important;
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: calc(var(--gap) / 5);
	width: 100%;
`;

const Item = styled.p`
	text-align: center;
	margin: 0;
`;

const DownloadLink = styled( IconButtonLink )`
	display: flex;
	justify-content: center;
	padding-left: 16px;

	span {
		margin-left: 8px;
	}
`;

const StyledParagraph = styled.p`
	margin-top: 0;
	margin-bottom: 8px;
`;

/**
 * The Product component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {JSX.Element} The rendered Product component.
 */
function Product( props ) {
	let productVersion = "";
	if ( ! _isEmpty( props.currentVersion ) ) {
		productVersion = props.intl.formatMessage( messages.version ) + " " + props.currentVersion;
	}

	let composerDownload = null;

	if ( includes( [ "plugin", "typo3-extension" ], props.type ) ) {
		composerDownload =
			<Item>
				<LinkButton
					type="button"
					onClick={ () => {
						props.onComposerHelpModalOpen( props.name, props.glNumber, props.composerToken );
					} }
				>
					<FormattedMessage
						id="downloadsPage.product.install-with-composer"
						defaultMessage="Install with Composer"
					/>
				</LinkButton>
			</Item>;
	}

	return (
		<Card>
			<Card.Header className="centered">
				<h2>{ props.name.replace( "plugin", "" ).trim() }</h2>
			</Card.Header>
			<Card.Content className="centered">
				<StyledParagraph>
					{ productVersion }
				</StyledParagraph>
				<StyledParagraph>
					<Link to={ generatePluginChangelogUrl( props.name ) } linkTarget="_blank" hasExternalLinkIcon={ true }>
						Changelog
					</Link>
				</StyledParagraph>
				<ProductIcon src={ props.icon } alt="" />
			</Card.Content>
			<Card.Footer>
				<Items>
					{ props.buttons.map( button => {
						return (
							<Item key={ button.label }>
								<DownloadLink
									iconSource=""
									to={ button.file }
									ariaLabel={ props.intl.formatMessage( messages.downloadButton ) + " " + props.name + " " + button.label }
								>
									<ArrowDownOnSquareIcon />
									<span>{ props.intl.formatMessage( messages.downloadButton ) }</span>
								</DownloadLink>
							</Item>
						);
					} ) }
					<Item>or</Item>
					{ composerDownload }
				</Items>
			</Card.Footer>
		</Card>
	);
}

Product.propTypes = {
	name: PropTypes.string.isRequired,
	glNumber: PropTypes.string,
	currentVersion: PropTypes.string,
	icon: PropTypes.string.isRequired,
	buttons: PropTypes.array.isRequired,
	intl: intlShape.isRequired,
	composerToken: PropTypes.object,
	onComposerHelpModalOpen: PropTypes.func,
	type: PropTypes.string,
};

export default injectIntl( Product );
