import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { LogoutButton } from "../components/Button";
import { colors } from "@yoast/style-guide";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import ErrorDisplay, { ErrorPropTypeShape } from "../errors/ErrorDisplay";
import { UserContext } from "../context/UserContext";
import { cancelImpersonation } from "shared-frontend/functions/auth";

const UserInfoContainer = styled.aside`
	display: flex;
	margin-left: 24px;
	margin-bottom: 32px;
	padding-right: 16px;
`;

const UserInfo = styled.div`
	// Firefox needs this for user-email break word to work.
	min-width: 0;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	color: ${ colors.$color_white };
`;

const UserEmail = styled.p`
	margin: 0;
	font-size: 14px;
	font-weight: 300;
	word-wrap: break-word;
	overflow-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
`;

const messages = defineMessages( {
	signout: {
		id: "signout",
		defaultMessage: "Sign out",
	},
	signingout: {
		id: "signout.pending",
		defaultMessage: "Signing out...",
	},
	impersonating: {
		id: "userProfile.impersonating",
		defaultMessage: "Impersonating:",
	},
	stopImpersonation: {
		id: "userProfile.stopImpersonation",
		defaultMessage: "Stop impersonating",
	},
} );


/**
 * Shows info about the currently logged in user and also shows user impersonation status.
 *
 * @param {Object } props props
 *
 * @returns {JSX.Element} info about the currently logged in user.
 */
const UserProfile = ( props ) => {
	/**
	 * Gets called when the user clicks on logout.
	 *
	 * @returns {void}
	 */
	const onLogoutClick = () => {
		if ( props.loggingOut ) {
			return;
		}

		props.onLogoutClick();
	};

	const stopImpersonation = useCallback( () => {
		cancelImpersonation();
		/*
		 * Reload the page so the full state is properly filled with the new user data.
		 * We might get away with dispatching the login action for the new user, but I don't trust that
		 * we properly clean up all data from the impersonated user stored all over the Redux state.
		 */
		window.location.reload();
	}, [ cancelImpersonation, window.location.reload ] );

	const { userSession } = useContext( UserContext );

	const message = props.loggingOut ? messages.signingout : messages.signout;
	return <UserInfoContainer className="user-info">
		<UserInfo>
			{
				userSession.impersonatedBy &&
				<FormattedMessage tagName="strong" { ...messages.impersonating } />
			}

			<UserEmail>{ props.displayEmail }</UserEmail>

			{
				userSession.impersonatedBy &&
				<LogoutButton onClick={ stopImpersonation }>
					<FormattedMessage { ...messages.stopImpersonation } />
				</LogoutButton>
			}

			{ /* eslint-disable react/jsx-no-bind */ }
			<LogoutButton
				id="sign-out-button"
				type="button"
				onClick={ onLogoutClick }
				enabledStyle={ ! props.loggingOut }
				aria-disabled={ props.loggingOut }
			>
				<FormattedMessage { ...message } />
			</LogoutButton>
			{ /* eslint-enable */ }
			<ErrorDisplay error={ props.logoutError } showIcon={ false } />
		</UserInfo>
	</UserInfoContainer>;
};

export default injectIntl( UserProfile );

UserProfile.propTypes = {
	displayEmail: PropTypes.string.isRequired,
	onLogoutClick: PropTypes.func.isRequired,
	loggedIn: PropTypes.bool,
	className: PropTypes.string,
	loggingOut: PropTypes.bool,
	logoutError: ErrorPropTypeShape,
};

UserProfile.defaultProps = {
	loggingOut: false,
	loggedIn: false,
	className: "",
	logoutError: null,
};
